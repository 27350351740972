import { BooleanInput, CreateButton, Datagrid, Link, List, SelectInput, TextField, TextInput, TopToolbar, useTranslate } from 'react-admin'

import ConfirmationField from '../../components/ConfirmationField'

const ListFilters = [
  <SelectInput source='language'
    choices={[
      {id: 'en', name: 'English'},
      {id: 'ht', name: 'Haitian Creole'},
      {id: 'es', name: 'Spanish'},
      {id: 'fr', name: 'French'}
    ]} />,
    <SelectInput source='country'
    choices={[
      {id: 'US', name: 'United States'},
      {id: 'HT', name: 'Haiti'},
      {id: 'DO', name: 'Dominican Republic'}
    ]} />,
    <TextInput source='key' />,
    <TextInput source='text' />,
    <BooleanInput source='confirmed' />
]

const ListActions = () => {
  const translate = useTranslate()
  return <TopToolbar sx={{ alignItems: 'center' }}>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation'}>{translate('nclusion.translations')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-keys'}>{translate('nclusion.untranslatedKeys')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-keys-dynamic'}>{translate('nclusion.dynamicTranslationKeys')}</Link>
    <CreateButton resource='translation' />
  </TopToolbar>
}

export default () => {
  const translate = useTranslate()

  return <List
    filters={ListFilters}
    resource='translation'
    title={translate('nclusion.unconfirmedTranslations')}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={false} rowClick='show'>
      <TextField source='language' />
      <TextField source='country' />
      <TextField source='key' />
      <TextField source='text' />
      <ConfirmationField source='confirmed' />
    </Datagrid>
  </List>
}
