import { createContext } from 'react'
import type Search from './Search'

type AggregationContextType = {
  search: Search
}

export default createContext<AggregationContextType>({
  search: {} as Search
})
