import { CreateButton, Datagrid, Identifier, Link, List, RowClickFunction, SelectInput, TextField, TextInput, TopToolbar, useTranslate } from 'react-admin'
import { languageOptions } from '../../i18nProvider'
import { NclusionList } from '../../components/NclusionList'

const languageChoices = languageOptions.map(({ locale, name }) => ({ id: locale, name }))
const countryChoices = ['DO', 'HT', 'US'].map(country => ({ id: country, name: country }))

const Filters = [
  <TextInput source="key" />,
  <SelectInput source="language" choices={languageChoices} />,
  <SelectInput source="country" choices={countryChoices} />
]

const ListActions = () => {
  const translate = useTranslate()
  return <TopToolbar sx={{ alignItems: 'center' }}>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation'}>{translate('nclusion.translations')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-unconfirmed'}>{translate('nclusion.unconfirmedTranslations')}</Link>
    <Link sx={{ textTransform: 'uppercase' }} to={'/translation-keys-dynamic'}>{translate('nclusion.dynamicTranslationKeys')}</Link>
    <CreateButton resource="translation" />
  </TopToolbar>
}

const toCreate: RowClickFunction = (id: Identifier, resource: any, record: any) => `/translation/create?key=${record.key}&language=${record.language}&country=${record.country}`

const TranslationKeysList = () => {
  const translate = useTranslate()

  return <NclusionList
    filters={Filters}
    title={translate('nclusion.untranslatedKeys')}
  >
    <ListActions />
    <Datagrid
      rowClick={toCreate}
      bulkActionButtons={false}
    >
      <TextField source="language" />
      <TextField source="country" />
      <TextField source="key" />
    </Datagrid>
  </NclusionList>
}

export default TranslationKeysList
