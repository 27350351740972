import List from './List'
import { UserShow } from './Show'
import { UserCreate }  from './Create'
import { UserEdit }  from './Edit'
import ChangePassword from './ChangePassword'
import ChangePasswordSuccess from './ChangePasswordSuccess'

export default {
  list: List,
  show: UserShow,
  create: UserCreate,
  edit: UserEdit,
  changePassword: ChangePassword,
  changePasswordSuccess: ChangePasswordSuccess
}
