import React from 'react'
import ListComponent from './ListComponent'
import { Box, Tab, Tabs } from '@mui/material'
import { CustomTabPanel } from '../../components/CustomTabPanel'

export default () => {
  const [tabValue, setTabValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setTabValue(newValue)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label='Confirmation' />
          <Tab label='Past Results' />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <ListComponent
          confirmationFilter={{
            confirmed: false,
            date: {},
            drawing_id: undefined,
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <ListComponent />
      </CustomTabPanel>
    </Box>
  )
}
