import React from 'react'
import List from './List'
import { Box, Tab, Tabs } from '@mui/material'
import { CustomTabPanel } from '../../components/CustomTabPanel'
import { useTranslate } from 'react-admin'

export default () => {
  const [tabValue, setTabValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setTabValue(newValue)
  const translate = useTranslate()

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label={translate('nclusion.fullReport')} />
          <Tab label={translate('nclusion.netReport')} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <List fullReport={true} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <List />
      </CustomTabPanel>
    </Box>
  )
}
