import { Fragment, useEffect, useState } from 'react'
import { Admin, Resource, CustomRoutes, useNotify } from 'react-admin'
import { kebabCase, maxBy, pick } from 'lodash'
import dataProvider from './dataProvider'
import authProvider from './authProvider'
import i18nProvider, { initTranslations } from './i18nProvider'
import * as recordRepresentations from './recordRepresentations'
import { Route } from 'react-router-dom'
import Layout from './Layout'
import usersComponents from './resources/users'
import { feathersResourceComponents } from './components/FeathersCRUD'
import { QueryClient } from 'react-query'
import { light, dark } from './theme'
import LoggedOutRoutes from './LoggedOutRoutes'
import app, { restClient } from './feathersClient'
import invitationComponents from './resources/invitation'
import translationKeysComponents from './resources/translation-keys'
import translationKeysDynamicComponents from './resources/translation-keys-dynamic'
import TranslationUnconfirmedList from './resources/translation/UnconfirmedList'
import bankComponents from './resources/bank'
import drawingNumbersComponents from './resources/drawingNumbers'
import translationComponents from './resources/translation'
import ticketComponents from './resources/ticket'
import lotteryComponents from './resources/lottery'
import reconciliationComponents from './resources/reconciliation'
import TranslationsImport from './resources/translation/Import'
import BanksImport from './resources/bank/Import'
import associateCommissionReport from './resources/associate-commission-report'
import associateComponent from './resources/associates'
import DeveloperDashboard from './routes/dashboards/DeveloperDashboard'
import OperationsDashboard from './routes/dashboards/OperationsDashboard'
import FinanceDashboard from './routes/dashboards/FinanceDashboard'
import TenantAdminDashboard from './routes/dashboards/TenantAdminDashboard'
import LotteryAdminDashboard from './routes/dashboards/LotteryDashboard'
import MerchantAdminDashboard from './routes/dashboards/MerchantAdminDashboard'
import MfiAdminDashboard from './routes/dashboards/MfiAdminDashboard'
import BankDashboard from './components/BankDashboard'
import NewUserInvitations from './routes/dashboards/NewUserInvitations'
import appState, { AppState } from './appState'
import { User } from '@nclusion/feathers-client'
import allocationComponent from './resources/allocation'
import ticketDenormalizedComponents from './resources/ticket-denormalized'
import './App.css'

const dashboards = [
  NewUserInvitations,
  () => <></>,
  () => <></>,
  OperationsDashboard,
  FinanceDashboard,
  TenantAdminDashboard,
  MerchantAdminDashboard,
  LotteryAdminDashboard,
  MfiAdminDashboard
]

const Dashboard = () => {
  const user = authProvider.getIdentitySync()

  if (!user?.roles.length && user?.email === 'superuser@nclusion.com') {
    return <DeveloperDashboard />
  }

  const highestRole = maxBy<UserRole>(user?.roles, 'role_id')!

  const Component = dashboards[highestRole?.role_id || 0]

  return <Component />
}

const customComponents = {
  translation: translationComponents,
  bank: bankComponents,
  drawingNumbers: drawingNumbersComponents,
  ticketDenormalized: ticketDenormalizedComponents,
  ticket: ticketComponents,
  invitation: invitationComponents,
  lottery: lotteryComponents,
  associate: associateComponent,
  allocation: allocationComponent,
  reconciliation: reconciliationComponents
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0
    }
  }
})

const recordRepresentationFromSchema = (schema: any) => {
  let representationKeys = schema.representationKeys
  if (!representationKeys) {
    const keys = Object.keys(schema.properties)
    representationKeys = ['id']
  }

  return (record: any) =>
    Object.values(pick(record, representationKeys)).join(' ')
}

type UserRole = {
  role_id: number
  role_name: string
  tenant_id?: number
  subtenant_id?: number
  lottery_id?: number
  merchant_id?: number
  mfi_id?: number
}

export const App = () => {
  const [ready, setReady] = useState(false)
  const [user, setUser] = useState<null | User>(null)
  const notify = useNotify()
  const token = (window.location.hash.match(/#access_token=(.*?)$/) || [
    '',
    ''
  ])[1]
  if (token) {
    localStorage.setItem('feathers-jwt', token)
    window.location.href = `${window.location.href.split('#')[0]}#/`
  }

  appState.observe(({ user }: AppState) => setUser(user))

  useEffect(() => {
    appState.notify = notify

    const fn = async () => {
      await initTranslations()
      i18nProvider.changeLocale(localStorage.getItem('lang') || 'en-US')
      try {
        localStorage.getItem('feathers-jwt') &&
          authProvider.checkAuth &&
          (await authProvider.checkAuth(null))
        const _schemas = appState.user
          ? await app.service('schema').find({})
          : null
        app.set('schemas', _schemas)
      } catch (e) {
        console.error(`${e}`)
      }
      setReady(true)
    }

    fn()
  }, [user?.id])

  const schemas = appState.user ? app.get('schemas') : null

  return (
    ready && (
      <Admin
        authProvider={authProvider as any}
        dataProvider={dataProvider as any}
        queryClient={queryClient}
        i18nProvider={i18nProvider}
        layout={(props: any) => <Layout {...props} schemas={schemas} />}
        theme={light}
        darkTheme={dark}
        loginPage={<LoggedOutRoutes />}
        dashboard={Dashboard as any}
      >
        <CustomRoutes>
          <Route
            path="/change-password"
            element={<usersComponents.changePassword />}
          />
          <Route
            path="/change-password-success"
            element={<usersComponents.changePasswordSuccess />}
          />
          <Route
            path="/lottery-dashboard"
            element={<LotteryAdminDashboard />}
          />
          <Route path="/translations-import" element={<TranslationsImport />} />
          <Route
            path="/translation-unconfirmed"
            element={<TranslationUnconfirmedList />}
          />
          <Route path="/bank-dashboard" element={<BankDashboard />} />
          <Route
            path="/invitation-dashboard"
            element={<NewUserInvitations />}
          />
          <Route path="/banks-import" element={<BanksImport />} />
        </CustomRoutes>
        <Resource
          recordRepresentation={({ firstName, lastName }) =>
            lastName ? `${firstName} ${lastName}` : ''
          }
          name="users"
          {...usersComponents}
        />
        <Fragment key={user ? user.id : 0}>
          {schemas &&
            Object.keys(schemas).map((name: string) => (
              <Resource
                key={name}
                name={`${
                  schemas[name]?.category ? `${schemas[name].category}-` : ''
                }${kebabCase(name)}`}
                {...feathersResourceComponents(name)}
                {...customComponents[name as keyof typeof customComponents]}
                recordRepresentation={
                  recordRepresentations[
                    name as keyof typeof recordRepresentations
                  ] || recordRepresentationFromSchema(schemas[name])
                }
              />
            ))}
        </Fragment>
        <Resource
          name={'associate-commission-report'}
          list={<associateCommissionReport.list />}
        />
        <Resource name="translation-keys" {...translationKeysComponents} />
        <Resource
          name="translation-keys-dynamic"
          {...translationKeysDynamicComponents}
        />
      </Admin>
    )
  )
}
