import { useEffect, useState } from 'react'
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useChoicesContext,
} from 'react-admin'
import app from '../../feathersClient'
import { startCase, uniqBy } from 'lodash'
import { Role } from '@nclusion/feathers-client'

const RoleInput = ({roles, businessUnit} : {roles: Role[], businessUnit: string}) => {
  const { availableChoices } = useChoicesContext()
  const choices = availableChoices.filter(choice => choice.visibility === businessUnit)
  
  // FIXME: This is a hack to deal with the finance role.  It is listed as a tenant-level
  // visibility because only the tenant admin can assign it, but it needs to be assigned
  // at the subtenant level.  We need to find a better solution to this.
  const finance = roles.find(role => role.name === 'Finance')
  if (businessUnit === 'sub-tenant' && finance && !choices.find(choice => choice.id === finance.id)) {
    choices.push({id: finance.id, name: finance.name})
  } else if (businessUnit === 'tenant' && finance) {
    const findex = choices.findIndex(choice => choice.id === finance.id)
    if (findex >= 0) {
      choices.splice(findex, 1)
    }    
  }

  return <AutocompleteInput source='role_id' choices={choices} />
}

const InvitationFields = () => {
  const [businessUnitOptions, setBusinessUnitOptions] = useState<
    { id: string; name: string }[]
  >([])
  const [roles, setRoles] = useState<Role[]>([])
  const [businessUnit, setBusinessUnit] = useState('')
  const schema = app.get('schemas')?.invitation

  useEffect(() => {
    const fn = async () => {
      const { data } = await app.service('role').find({})
      const options = uniqBy(
        data.map(role => ({
          id: role.visibility as string,
          name: startCase(role.visibility),
        })),
        'id'
      )
      setRoles(data)
      setBusinessUnitOptions(options)
    }
    fn()
  }, [])

  return schema ? (
    <>
      <TextInput source='emailOrPhoneNumber' parse={val => val.length ? val.trim() : val} />
      <SelectInput
        source='businessUnit'
        choices={businessUnitOptions}
        onChange={e => setBusinessUnit(e.target.value)}
      />
      {businessUnit && (
        <ReferenceInput source={`${businessUnit === 'sub-tenant' ? 'subtenant' : businessUnit}_id`} reference={businessUnit} />
      )}
      <ReferenceInput
        key={businessUnit}
        source='role_id'
        reference='role'
        filter={
          businessUnit === 'sub-tenant'
            ? {
              category: 'tenant'

            } :
          businessUnit
            ? {
                category:
                  businessUnit === 'sub-tenant' ? 'tenant' : businessUnit,
                visibility: businessUnit,
              }
            : {}
        }
      >
        <RoleInput roles={roles} businessUnit={businessUnit} />
      </ReferenceInput>
    </>
  ) : (
    <></>
  )
}

export default InvitationFields
