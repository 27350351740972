import React from 'react'
import { AuthProvider, Logout, UserMenu, useAuthProvider, useTranslate } from 'react-admin'
import { Link } from 'react-admin'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default () => {
  const translate = useTranslate()
  const authProvider: AuthProvider = useAuthProvider()
  const [user, setUser] = React.useState(null as any)

  React.useEffect(() => {
    const fn = async () => {
      if (authProvider.getIdentity) {
        setUser(await authProvider.getIdentity())
      }
    }
    fn()
  }, [])

  return <UserMenu>
    <MenuItem>
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={`/users/${user?.id || '0'}/show`}>{translate('nclusion.user_profile')}</Link>
      </ListItemText>
    </MenuItem>
    <Logout />
  </UserMenu>
}
  
