import { Fragment, useContext, useEffect, useState } from 'react'
import ChartContext from '../ChartContext'
import { Button, useRecordContext, useTranslate } from 'react-admin'
import {
  Associate,
  Bank,
  Invitation,
  Lottery,
  Merchant,
  Mfi,
  Role,
  Tenant,
} from '@nclusion/feathers-client'
import { Link } from 'react-admin'
import { range, sortBy, startCase, uniq } from 'lodash'
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Modal,
  CardActions,
  Stack,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material'
import dataProvider from '../../../dataProvider'
import app from '../../../feathersClient'
import DeleteIcon from '@mui/icons-material/RemoveCircleOutline'
import Plus from '@mui/icons-material/AddCircleOutline'
import ChildRelationshipComponent from '../../ChildRelationships'
import { InvitationData } from '@nclusion/feathers-client'

type PopulatedInvitation = {
  role_name: string
  role_id: number
  businessUnit: string
  businessUnitId: number
  businessUnitName: string
  accepted: string
  invitation_id: number
}

const DeleteInvitationButton = (invitation: PopulatedInvitation) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)

  const [error, setError] = useState('')

  const delteInvitation = async () => {
    try {
      await dataProvider.delete('invitation', { id: invitation.invitation_id })
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  return (
    <>
      <DeleteIcon
        sx={{ color: '#b01349', cursor: 'pointer', justifySelf: 'flex-end' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate('nclusion.deleteInvitation')} />
              <CardContent>
                <p>
                  {translate('nclusion.role')}: {invitation.role_name} -{' '}
                  {startCase(invitation.businessUnit)}{' '}
                  {invitation.businessUnitName}
                </p>
                <p>{translate('nclusion.confirmDeleteInvitation')}</p>
                {error && <p style={{ color: '#b01349' }}>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={delteInvitation}
                  sx={{ color: '#b01349' }}
                  label={translate('nclusion.delete')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

type EntityOptions = {
  mfi: Mfi[]
  tenant: Tenant[]
  lottery: Lottery[]
  merchant: Merchant[]
  role: Role[]
  [k: string]: any
}

const optionDataOptions = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: 'id', order: 'ASC' as any },
  filter: {}
}

const CreateInvitationButton = () => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)
  const [optionData, setOptionData] = useState<null | EntityOptions>(null)

  const [businessUnit, setBusinessUnit] = useState('sub-tenant')
  const [businessUnitId, setBusinessUnitId] = useState(1)
  const [role, setRole] = useState(1)
  const [menuItems, setMenuItems] = useState([])

  const [error, setError] = useState('')

  const createInvitation = async () => {
    const [service, id] = window.location.hash.split('/').slice(1, 3)
    let associateRecord: any
    if (service === 'associate') {
      associateRecord =
        (await dataProvider.getOne('associate', { id }))?.data || {}
    }

    try {
      await dataProvider.create('invitation', {
        data: {
          emailOrPhoneNumber:
            associateRecord?.user.phone || associateRecord?.user.email,
          tenant_id: businessUnit === 'tenant' ? businessUnitId : undefined,
          subtenant_id:
            businessUnit === 'sub-tenant' ? businessUnitId : undefined,
          merchant_id: businessUnit === 'merchant' ? businessUnitId : undefined,
          mfi_id: businessUnit === 'mfi' ? businessUnitId : undefined,
          lottery_id: businessUnit === 'lottery' ? businessUnitId : undefined,
          role_id: role
        } as InvitationData
      })
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  useEffect(() => {
    if (!open) {
      return
    }
    const fn = async () => {
      const [mfi, tenant, subtenant, lottery, merchant, role]: any =
        await Promise.all([
          dataProvider.getList('mfi', optionDataOptions),
          dataProvider.getList('tenant', optionDataOptions),
          dataProvider.getList('sub-tenant', optionDataOptions),
          dataProvider.getList('lottery', optionDataOptions),
          dataProvider.getList('merchant', optionDataOptions),
          dataProvider.getList('role', optionDataOptions)
        ])
      setOptionData({
        mfi: mfi.data,
        tenant: tenant.data,
        // @ts-ignore
        'sub-tenant': subtenant.data,
        lottery: lottery.data,
        merchant: merchant.data,
        role: role.data
      })
      setMenuItems(
        uniq(role.data.map((r: Role & { visibility: string }) => r.visibility))
      )
    }
    fn()
  }, [open])

  useEffect(() => {
    if (optionData) {
      setBusinessUnitId((optionData[businessUnit] as any)[0]?.id)
      setRole(optionData['role'][0]?.id)
    }
  }, [businessUnit])

  return (
    <>
      <Plus
        sx={{ color: 'green', height: 32, width: 32, cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate('nclusion.createInvitation')} />
              <CardContent>
                <Stack spacing={2}>
                  <Select
                    label={translate('nclusion.businessUnit')}
                    onChange={(e) => setBusinessUnit(e.target.value)}
                    value={businessUnit}
                  >
                    <MenuItem value={'sub-tenant'}>
                      {translate('nclusion.sub-tenant')}
                    </MenuItem>
                    {menuItems.map((category) =>
                      category === 'tenant' ? (
                        <MenuItem key={'tenant'} value={'tenant'}>
                          {translate('nclusion.tenant')}
                        </MenuItem>
                      ) : category === 'mfi' ? (
                        <MenuItem key={'mfi'} value={'mfi'}>
                          {translate('nclusion.mfi')}
                        </MenuItem>
                      ) : category === 'lottery' ? (
                        <MenuItem key={'lottery'} value={'lottery'}>
                          {translate('nclusion.lottery')}
                        </MenuItem>
                      ) : category === 'merchant' ? (
                        <MenuItem key={'merchant'} value={'merchant'}>
                          {translate('nclusion.merchant')}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                  <Select
                    label={translate('nclusion.businessUnitId')}
                    onChange={(e) => setBusinessUnitId(Number(e.target.value))}
                    value={`${businessUnitId}`}
                  >
                    {!!optionData &&
                      optionData[businessUnit as keyof typeof optionData].map(
                        (val: any) => (
                          <MenuItem key={val.id} value={val.id}>
                            {val.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  <Select
                    label={translate('nclusion.role')}
                    onChange={(e) => setRole(Number(e.target.value))}
                    value={`${role}`}
                  >
                    {!!optionData &&
                      optionData['role']
                        .filter((role: Role) =>
                          businessUnit === 'tenant'
                            ? role.name === 'Tenant Admin'
                            : businessUnit === 'sub-tenant'
                            ? role.category === 'tenant' &&
                              role.name !== 'Tenant Admin'
                            : role.category === businessUnit
                        )
                        .map((val: any) => (
                          <MenuItem key={val.id} value={val.id}>
                            {val.name}
                          </MenuItem>
                        ))}
                  </Select>
                  {error && <p style={{ color: '#b01349' }}>{error}</p>}
                </Stack>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  sx={{ color: 'green' }}
                  onClick={createInvitation}
                  label={translate('nclusion.invite')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

const InvitationComponent = ({
  invitation
}: {
  invitation: PopulatedInvitation
}) => {
  const translate = useTranslate()
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '4fr 4fr 4fr 1fr',
        gridTemplateRows: '32px'
      }}
    >
      <Link to={`/role/${invitation.role_id}/show`}>
        {invitation.role_name}
      </Link>
      <Link
        to={`/${invitation.businessUnit}/${invitation.businessUnitId}/show`}
      >
        {invitation.businessUnitName}
      </Link>
      <span>
        {invitation.accepted
          ? translate('nclusion.accepted')
          : translate('nclusion.pending')}
      </span>
      {!invitation.accepted ? (
        <DeleteInvitationButton {...invitation} />
      ) : (
        <></>
      )}
    </div>
  )
}

type PopulatedRole = any

type PopulatedTenantAssociate = {
  tenant_id: number
  subtenant_id: number
  tenant_name: string
  subtenant_name: string
  role_id: number
  role_name: string
  bank_id: number[]
  bank_assignment_id: number[]
  bank_name: string[]
  bank_alias: string[]
  associate_role_id: number
}

type BanksListProps = {
  bank_id: number[]
  bank_assignment_id: number[]
  bank_name: string[]
  bank_alias: string[]
  subtenant_id: number
  subtenant_name: string
  tenant_name: string
}

const getAssociateName = ({
  tenant,
  subtenant,
  lottery,
  merchant,
  mfi
}: any) => {
  if (tenant) {
    return `${tenant.name} ${subtenant?.name}`
  } else if (lottery) {
    return lottery.name
  } else if (merchant) {
    return merchant.name
  } else if (mfi) {
    return mfi.name
  }
  return `n/a`
}

const AssignBankButton = ({
  tenant_id,
  subtenant_id
}: {
  tenant_id: number
  subtenant_id: number
}) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)

  const [associates, setAssociates] = useState<Associate[]>([])
  const [banks, setBanks] = useState<Bank[]>([])
  const [bankSearch, setBankSearch] = useState('')
  const [bank, setBank] = useState<Bank>({} as Bank)
  const [associate, setAssociate] = useState(0)

  const [error, setError] = useState('')

  const assign = async () => {
    try {
      await dataProvider.create('bank-assignment', {
        data: {
          tenant_id,
          subtenant_id,
          bank_id: bank.id,
          associate_id: associate
        }
      })
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  useEffect(() => {
    const fn = async () => {
      // @ts-ignore
      const banks =
        (
          await app.service('bank').find({
            query: bankSearch
              ? {
                  tenant_id,
                  subtenant_id,
                  name: { $ilike: `%${bankSearch}%` }
                }
              : { tenant_id, subtenant_id }
          })
        )?.data || []
      setBanks(banks)
    }
    fn()
  }, [bankSearch])

  useEffect(() => {
    const fn = async () => {
      const [service, id] = window.location.hash.split('/').slice(1, 3)

      if (service === 'users') {
        const userAssociates = (
          await dataProvider.getList('associate', {
            filter: { user_id: Number(id) },
            pagination: { perPage: 1000, page: 1 },
            sort: { field: 'id', order: 'ASC' }
          })
        )?.data

        if (userAssociates.length === 1) {
          setAssociate(userAssociates[0]?.id)
        } else if (userAssociates.length > 1) {
          setAssociates(userAssociates)
          setAssociate(userAssociates[0]?.id)
        }
      } else {
        setAssociate(Number(id))
      }
    }
    fn()
  }, [])

  useEffect(() => {
    if (!open) {
      setBank({} as Bank)
      setBanks([])
    }
  }, [open])

  return (
    <>
      <Plus
        sx={{ color: 'green', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate('nclusion.assignBank')} />
              <CardContent>
                {associates.length > 1 && (
                  <Select
                    value={associate}
                    onChange={(e) => setAssociate(Number(e.target.value))}
                    label={translate('nclusion.associate')}
                  >
                    {associates.map((associate: Associate) => (
                      <MenuItem
                        key={associate.id}
                        value={associate.id}
                      >{`${getAssociateName(associate)}`}</MenuItem>
                    ))}
                  </Select>
                )}
                <Autocomplete
                  inputValue={bankSearch}
                  onInputChange={(e: any, t: any) => setBankSearch(t || '')}
                  value={bank}
                  getOptionLabel={(val) => val.name || ''}
                  onChange={(e: any, b: any) => setBank(b)}
                  renderInput={(props: any) => <TextField {...props} />}
                  options={banks}
                />
                {error && <p style={{ color: '#b01349' }}>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={assign}
                  label={translate('nclusion.assignBank')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

const RemoveBankAssignmentButton = ({
  bank_name,
  tenant_name,
  subtenant_name,
  bank_assignment_id
}: PopulatedRole) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)

  const [error, setError] = useState('')

  const removeAssignment = async () => {
    try {
      await dataProvider.delete('bank-assignment', { id: bank_assignment_id })
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  return (
    <>
      <DeleteIcon
        sx={{ color: '#b01349', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate('nclusion.removeBankAssignment')} />
              <CardContent>
                <p>
                  {translate('nclusion.bank')}: {bank_name} ({tenant_name} -{' '}
                  {subtenant_name})
                </p>
                <p>{translate('nclusion.confirmRemoveBankAssignment')}</p>
                {error && <p style={{ color: '#b01349' }}>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={removeAssignment}
                  sx={{ color: '#b01349' }}
                  label={translate('nclusion.unassign')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

type PopulatedRelationship = {
  associate_id: number
  business_unit: string
  relationship_id: number
  relationship_name: string
  mfi_id?: number
  tenant_id?: number
  subtenant_id?: number
  merchant_id?: number
  lottery_id?: number
  relationship_tree_id?: number
  parent_associate_id?: number
  parent_associate_first_name?: string
  parent_associate_last_name?: string
  parent_associate_alias?: string
  parent_associate_phone?: string
}

const AssignRelationshipButton = ({
  associate_id,
  tenant_id,
  subtenant_id,
  mfi_id,
  merchant_id,
  lottery_id,
  relationship_id,
  relationship_name
}: Partial<PopulatedRelationship>) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)
  const [error, setError] = useState('')
  const [associates, setAssociates] = useState<Associate[]>([])
  const [parent, setParent] = useState<Associate>({} as Associate)
  const [parentSearch, setParentSearch] = useState('')

  const assign = async () => {
    try {
      await dataProvider.create('relationship-tree', {
        data: {
          relationship_id,
          associate_id,
          relative_id: parent.id
        }
      })
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  useEffect(() => {
    const fn = async () => {
      const newAssociates = await app
        .service('parent-associate-autocomplete' as any)
        .find({
          query: {
            tenant_id,
            subtenant_id,
            mfi_id,
            merchant_id,
            lottery_id,
            relationship_id,
            ...(parentSearch ? { parentSearch } : {})
          }
        })
      setAssociates(
        newAssociates.filter((associate: any) => associate.id !== associate_id)
      )
    }
    fn()
  }, [parentSearch])

  useEffect(() => {
    if (!open) {
      setParent({} as Associate)
      setAssociates([])
    }
  }, [open])

  return (
    <>
      <Plus
        sx={{ color: 'green', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader
                title={`${translate('nclusion.assignRelationship')} ${translate(
                  relationship_name || ''
                )}`}
              />
              <CardContent>
                <Autocomplete
                  inputValue={parentSearch}
                  onInputChange={(e: any, t: any) => setParentSearch(t || '')}
                  value={parent.id ? parent : null}
                  getOptionLabel={(val) => val.name || ''}
                  onChange={(e: any, b: any) => setParent(b)}
                  renderInput={(props: any) => <TextField {...props} />}
                  options={associates}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
                {error && <p style={{ color: '#b01349' }}>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={assign}
                  sx={{ color: 'green' }}
                  label={translate('nclusion.assignRelationship')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

const UnassignRelationshipButton = ({
  relationship_tree_id,
  parent_associate_first_name,
  parent_associate_last_name,
  relationship_name
}: Partial<PopulatedRelationship>) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)

  const [error, setError] = useState('')

  const unassign = async () => {
    try {
      await app.service('relationship-tree').remove(relationship_tree_id!)
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  return (
    <>
      <DeleteIcon
        sx={{ color: '#b01349', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate(relationship_name || '')} />
              <CardContent>
                <p>
                  {translate(relationship_name || '')}:{' '}
                  {parent_associate_first_name} {parent_associate_last_name}
                </p>
                <p>{translate('nclusion.confirmUnassignRelationship')}</p>
                {error && <p>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={unassign}
                  sx={{ color: '#b01349' }}
                  label={translate('nclusion.unassign')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

const RelationshipComponent = ({
  relationship
}: {
  relationship: PopulatedRelationship
}) => {
  const translate = useTranslate()

  const {
    associate_id,
    relationship_id,
    relationship_name,
    mfi_id,
    tenant_id,
    subtenant_id,
    merchant_id,
    lottery_id,
    relationship_tree_id,
    parent_associate_id,
    parent_associate_first_name,
    parent_associate_last_name,
    parent_associate_alias,
    parent_associate_phone
  } = relationship

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <h4 style={{ margin: '1rem 0' }}>{translate(relationship_name)}</h4>
        {parent_associate_id && <></>}
        {!parent_associate_id && (
          <AssignRelationshipButton
            tenant_id={tenant_id}
            subtenant_id={subtenant_id}
            associate_id={associate_id}
            mfi_id={mfi_id}
            merchant_id={merchant_id}
            lottery_id={lottery_id}
            relationship_id={relationship_id}
            relationship_name={relationship_name}
          />
        )}
      </div>
      {parent_associate_id && (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 4fr 3fr 1fr',
              padding: '0 2rem'
            }}
          >
            <span>{parent_associate_alias}</span>
            <Link to={`/associate/${parent_associate_id}/show`}>
              {parent_associate_first_name} {parent_associate_last_name}
            </Link>
            <a href={`tel://${parent_associate_phone}`}>
              {parent_associate_phone}
            </a>
            <UnassignRelationshipButton
              associate_id={associate_id}
              parent_associate_first_name={parent_associate_first_name}
              parent_associate_last_name={parent_associate_last_name}
              relationship_tree_id={relationship_tree_id}
              relationship_name={relationship_name}
            />
          </div>
        </>
      )}
      {!parent_associate_id && (
        <>
          <div style={{ padding: '0 2rem', marginTop: '-1rem' }}>
            <p>{translate('nclusion.noParentAssigned')}</p>
          </div>
        </>
      )}
    </>
  )
}

const RemoveRoleButton = ({
  role_name,
  assignmentId,
  tenant_name,
  subtenant_name,
  lottery_name,
  merchant_name,
  mfi_name
}: PopulatedRole) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)

  const [error, setError] = useState('')

  const service = tenant_name
    ? 'associate-roles'
    : lottery_name
    ? 'lottery-roles'
    : merchant_name
    ? 'merchant-roles'
    : 'mfi-roles'
  const entity_name = tenant_name
    ? `${tenant_name} - ${subtenant_name}`
    : lottery_name || merchant_name || mfi_name

  const remove = async () => {
    if (!assignmentId) {
      alert('no assignment id')
      return
    }
    try {
      await dataProvider.delete(service, { id: assignmentId })
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  return (
    <>
      <DeleteIcon
        sx={{ color: '#b01349', cursor: 'pointer', justifySelf: 'flex-end' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate('nclusion.removeRole')} />
              <CardContent>
                <p>
                  {translate('nclusion.role')}: {role_name} ({entity_name})
                </p>
                <p>{translate('nclusion.confirmRemoveRole')}</p>
                {error && <p>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={remove}
                  sx={{ color: '#b01349' }}
                  label={translate('nclusion.removeRole')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

const BanksList = ({
  bank_id,
  bank_name,
  bank_assignment_id,
  bank_alias,
  subtenant_id,
  subtenant_name,
  tenant_name
}: BanksListProps) => {
  const translate = useTranslate()
  const indices = range(0, bank_id?.length)

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '2fr 5fr 2fr 1fr' }}>
      {indices.map((idx: number) => (
        <Fragment key={`${bank_id[idx]}`}>
          <span>{bank_alias[idx]}</span>
          <Link to={`/bank/${bank_id[idx]}`}>{bank_name[idx]}</Link>
          <Link to={`/sub-tenant/${subtenant_id}`}>{subtenant_name}</Link>
          <RemoveBankAssignmentButton
            bank_name={bank_name[idx]}
            bank_assignment_id={bank_assignment_id[idx]}
            tenant_name={tenant_name}
            subtenant_name={subtenant_name}
          />
        </Fragment>
      ))}
      {!bank_id && (
        <>
          <div style={{ padding: '0 2rem', marginTop: '-1rem' }}>
            <p>{translate('nclusion.noBanksAssigned')}</p>
          </div>
        </>
      )}
    </div>
  )
}

const TenantAssociate = ({
  tenant_id,
  subtenant_id,
  tenant_name,
  subtenant_name,
  role_id,
  role_name,
  bank_id,
  bank_name,
  bank_alias,
  bank_assignment_id,
  associate_role_id
}: PopulatedTenantAssociate) => {
  const translate = useTranslate()

  return (
    <div
      style={{
        borderTop: '1px solid #888',
        paddingTop: '0.5rem',
        marginBottom: '2rem'
      }}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '3fr 3fr 3fr 1fr' }}>
        <Link to={`/role/${role_id}/show`} style={{ color: 'inherit' }}>
          {role_name}
        </Link>
        <Link to={`/tenant/${tenant_id}/show`}>{tenant_name}</Link>
        <Link to={`/sub-tenant/${subtenant_id}/show`}>{subtenant_name}</Link>
        <RemoveRoleButton
          assignmentId={associate_role_id}
          role_name={role_name}
          tenant_name={tenant_name}
          subtenant_name={subtenant_name}
        />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h4 style={{ margin: '1rem 0' }}>
            {translate('nclusion.assignedBanks')}
          </h4>
          <AssignBankButton tenant_id={tenant_id} subtenant_id={subtenant_id} />
        </div>
        <div style={{ padding: '0 2rem' }}>
          <BanksList
            bank_id={bank_id}
            bank_name={bank_name}
            bank_assignment_id={bank_assignment_id}
            bank_alias={bank_alias}
            subtenant_id={subtenant_id}
            subtenant_name={subtenant_name}
            tenant_name={tenant_name}
          />
        </div>
      </div>
    </div>
  )
}

const LotteryAssociateComponent = ({
  role_id,
  role_name,
  lottery_id,
  lottery_name,
  lottery_associate_role_id
}: PopulatedRole) => {
  return (
    <div
      style={{
        borderTop: '1px solid #888',
        paddingTop: '0.5rem',
        marginBottom: '2rem'
      }}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '4fr 4fr 1fr' }}>
        <Link to={`/role/${role_id}/show`} style={{ color: 'inherit' }}>
          {role_name}
        </Link>
        <Link to={`/tenant/${lottery_id}/show`}>{lottery_name}</Link>
        <RemoveRoleButton
          assignmentId={lottery_associate_role_id}
          role_name={role_name}
          lottery_name={lottery_name}
        />
      </div>
    </div>
  )
}

const MerchantAssociateComponent = ({
  role_id,
  role_name,
  merchant_id,
  merchant_name,
  merchant_associate_role_id
}: PopulatedRole) => {
  return (
    <div
      style={{
        borderTop: '1px solid #888',
        paddingTop: '0.5rem',
        marginBottom: '2rem'
      }}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '4fr 4fr 1fr' }}>
        <Link to={`/role/${role_id}/show`} style={{ color: 'inherit' }}>
          {role_name}
        </Link>
        <Link to={`/tenant/${merchant_id}/show`}>{merchant_name}</Link>
        <RemoveRoleButton
          assignmentId={merchant_associate_role_id}
          role_name={role_name}
          merchant_name={merchant_name}
        />
      </div>
    </div>
  )
}

const MfiAssociateComponent = ({
  role_id,
  role_name,
  mfi_id,
  mfi_name,
  mfi_associate_role_id
}: PopulatedRole) => {
  return (
    <div
      style={{
        borderTop: '1px solid #888',
        paddingTop: '0.5rem',
        marginBottom: '2rem'
      }}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '4fr 4fr 1fr' }}>
        <Link to={`/role/${role_id}/show`} style={{ color: 'inherit' }}>
          {role_name}
        </Link>
        <Link to={`/tenant/${mfi_id}/show`}>{mfi_name}</Link>
        <RemoveRoleButton
          assignmentId={mfi_associate_role_id}
          role_name={role_name}
          mfi_name={mfi_name}
        />
      </div>
    </div>
  )
}

const RoleComponent = ({ role }: { role: PopulatedRole }) => {
  if (role.tenant_id) {
    return <TenantAssociate {...role} />
  } else if (role.lottery_id) {
    return <LotteryAssociateComponent {...role} />
  } else if (role.merchant_id) {
    return <MerchantAssociateComponent {...role} />
  } else if (role.mfi_id) {
    return <MfiAssociateComponent {...role} />
  }
  return <></>
}

const RelatedAssociates = ({ id }: { id: string }) => {
  const { search } = useContext(ChartContext)
  const { registerAggregation, onResults } = search
  const [results, setResults] = useState<any>({ data: [] })
  const translate = useTranslate()

  onResults(
    id,
    ({
      results: newResults
    }: {
      results: {
        roles: Role[]
        invitaitons: Invitation[]
        parent_relationships: PopulatedRelationship[]
      }
    }) => setResults(newResults)
  )

  const { id: recordId, user_id } = useRecordContext() || {}
  const userId = user_id || recordId

  useEffect(() => {
    if (userId) {
      registerAggregation({
        id,
        aggregationId: 'relatedAssociates',
        service: 'users',
        recordId: Number(userId)
      })
    }
  }, [userId])

  const { roles, invitations, parent_relationships, children_relationships } =
    results

  const sortedRoles = sortBy(roles, (role: any) =>
    role.tenant_id ? 1 : role.lottery_id ? 2 : role.merchant_id ? 3 : 4
  )

  return invitations ? (
    <div style={{ padding: '1rem' }}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h2>Invitations</h2>
          <CreateInvitationButton />
        </div>

        <div style={{ borderTop: '1px solid #888', padding: '0.5rem 0' }}>
          {/* @ts-ignore */}
          {invitations.map((invitation: PopulatedInvitation) => (
            <InvitationComponent
              key={invitation.invitation_id}
              invitation={invitation}
            />
          ))}
        </div>
      </div>
      <div>
        <h2>Roles</h2>
        {sortedRoles.map((role: PopulatedRole) => (
          <RoleComponent
            key={`${role.role_id}-${role.tenant_id}-${role.subtenant_id}-${role.merchant_id}-${role.lottery_id}-${role.mfi_id}`}
            role={role}
          />
        ))}
      </div>
      <div>
        <h2>{translate('nclusion.Relationships')}</h2>
        <div style={{ borderTop: '1px solid #888', padding: '0.5rem 0' }}>
          {parent_relationships.map((relationship: PopulatedRelationship) => (
            <RelationshipComponent
              key={`${relationship.relationship_id}-${relationship.tenant_id}-${relationship.subtenant_id}-${relationship.merchant_id}-${relationship.lottery_id}-${relationship.mfi_id}`}
              relationship={relationship}
            />
          ))}
          <ChildRelationshipComponent children={children_relationships} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RelatedAssociates
