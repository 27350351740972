import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar
} from 'react-admin'
import ChartController from '../../components/charts/ChartController'
import RelatedAssociates from '../../components/charts/aggregations/RelatedAssociates'
import PhoneField from '../../components/PhoneField'
import { getLocale } from '../../util'
import authProvider from '../../authProvider'

export default () => {
  const locale = getLocale()
  const { permissions } = authProvider.getIdentitySync()
  const canEditAssociate = permissions.find(
    (p: any) => p.method === 'patch' && p.service === 'associate'
  )

  return (
    <Show
      actions={
        <TopToolbar>{canEditAssociate ? <EditButton /> : null}</TopToolbar>
      }
    >
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="alias" />
          <ReferenceField source="entity_id" reference="entity" />
          <ReferenceField source="user_id" reference="users" />
          <TextField source="name" />
          <NumberField source="user.id" locales={locale} />
          <EmailField source="user.email" />
          <BooleanField source="user.verified" />
          <PhoneField source="user.phone" />
          <BooleanField source="user.verified_phone" />
          <ReferenceField
            source="parent_associate_id"
            reference="associate"
            link="show"
          />          
          <ReferenceField source="tenant_id" reference="tenant" />
          <NumberField source="tenant.id" locales={locale} />
          <ReferenceField source="subtenant_id" reference="sub-tenant" />
          <NumberField source="subtenant.id" locales={locale} />
          <NumberField
            source="metadata.commission"
            label="nclusion.commission"
            locales={locale}
          />
          <TextField source="status" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <ReferenceManyField
            reference="accounts-associate-credit"
            target="associate_id"
            label="Account"
          >
            <Datagrid bulkActionButtons={false} rowClick="show">
              <TextField source="status" sortable={false} />
              <NumberField
                source="available_balance"
                sortable={false}
                locales={locale}
              />
              <NumberField
                source="pending_balance"
                sortable={false}
                locales={locale}
              />
              <NumberField
                source="credit_limit"
                sortable={false}
                locales={locale}
              />
              <TextField source="currency" sortable={false} locales={locale} />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
        <ChartController>
          <RelatedAssociates id="relatedAssociates" />
        </ChartController>
      </div>
    </Show>
  )
}
