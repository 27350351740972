import {
  ChipField,
  Datagrid,
  NumberField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
} from 'react-admin'
import { getLocale, schemaFilters } from '../../util'
import AssociateNameFilter from './AssociateNameFilter'
import NotOpenTodayFilter from './NotOpenTodayFilter'
import OperationalStatusFilter from './OperationalStatusFilter'
import AssociateNameFilterContext, {
  AssociateNameContextType,
} from './AssociateNameFilterContext'
import { useState } from 'react'
import i18nProvider from '../../i18nProvider'
import { NclusionList } from '../../components/NclusionList'

const associateNameFilters = [
  <AssociateNameFilter source='agent' />,
  <AssociateNameFilter source='supervisor' />,
  <OperationalStatusFilter
    source='$operationalStatus'
    label={i18nProvider.translate('nclusion.operationalStatus')}
  />,
  <NotOpenTodayFilter
    source='$notOpenToday'
    label={i18nProvider.translate('nclusion.notOpenToday')}
  />,
]

const listFilters = () => schemaFilters('bank', associateNameFilters, ['id'])

export default () => {
  const [providerValue, setProviderValue] = useState<AssociateNameContextType>(
    {}
  )
  const setValue = (key: string, val: number[]) =>
    setProviderValue({ ...providerValue, [key]: val })

  return (
    <AssociateNameFilterContext.Provider value={{ ...providerValue, setValue }}>
      <NclusionList resource='bank' filters={listFilters()}>
        <Datagrid rowClick='show' bulkActionButtons={false}>
          <NumberField source='id' locales={getLocale()} />
          <TextField source='alias' />
          <TextField source='subtenant.name' sortable={false} />
          <TextField source='name' />
          <TextField source='status' />
          <ReferenceManyField reference='bank-assignment' target='bank_id'>
            <SingleFieldList linkType='show'>
              <ChipField source='associate.name' />
            </SingleFieldList>
          </ReferenceManyField>
        </Datagrid>
      </NclusionList>
    </AssociateNameFilterContext.Provider>
  )
}
