import React from 'react'

import Login from './LoginPage'
import Verify from './VerifyPage'
import VerifySuccess from './VerifySuccessPage'
import RequestReset from './RequestResetPage'
import ResetPassword from './PasswordResetPage'
import PasswordResetSuccess from './PasswordResetSuccessPage'
import ResendConfimation from './ResendConfimationPage'
import SignUp from './CustomSignUpPage'

import { useLocation } from 'react-router-dom'

const isLogin = () => window.location.hash.indexOf('?') < 0
const isSignup = () => window.location.hash.indexOf('?signup') >= 0
const isVerifySuccess = () => window.location.hash.indexOf('?successful-verification') >= 0
const isVerify = () => window.location.hash.indexOf('?verify') >= 0
const isRequestReset = () => window.location.hash.indexOf('?request-reset') >= 0
const isResetSuccess = () => window.location.hash.indexOf('?reset-success') >= 0
const isReset = () => window.location.hash.indexOf('?reset') >= 0 && !isResetSuccess()
const isResend = () => window.location.hash.indexOf('?resend-confirmation') >= 0

export default () => {
  const location = useLocation()

  React.useEffect(() => {
    // this makes the page rerender just by getting triggered
  }, [location])

  return <>
    {isLogin() && <Login />}
    {isSignup() && <SignUp />}
    {isVerify() && <Verify />}
    {isVerifySuccess() && <VerifySuccess />}
    {isRequestReset() && <RequestReset />}
    {isReset() && <ResetPassword />}
    {isResetSuccess() && <PasswordResetSuccess />}
    {isResend() && <ResendConfimation />}
  </> 
}
