import React from 'react'
import { Layout } from 'react-admin'
import { ReactQueryDevtools } from 'react-query/devtools'
import AppBar from './AppBar'
import Menu from './NavMenu'
import { uniqueId } from 'lodash'

export default (props: any) => {
  return <>
    <Layout
      {...props}
      // @ts-ignore
      {...{ appBar: AppBar, menu: (prps: any) => <Menu {...prps} schemas={props.schemas} /> }}
    />
    <ReactQueryDevtools initialIsOpen={false} />
  </>
}