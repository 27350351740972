import { AutocompleteInput, ReferenceInput, useInput, useRecordContext, useRedirect } from 'react-admin'
import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import { startCase, camelCase, get } from 'lodash'
import { feathersResourceComponents } from './FeathersCRUD'

export default ({ source, reference, schema, disableCreate }: any) => {
  const [showModal, setShowModal] = React.useState(false)
  const Create = feathersResourceComponents(camelCase(reference.replace(new RegExp(`^${schema.category}-`), '').replace('users', 'user')))['create']
  const [selected, setSelected] = React.useState(0)
  const redirect = useRedirect()
  const record = useRecordContext() || {}
  let constraint: any
  if (record[schema.properties[source].constrainBy]) {
    constraint = { [schema.properties[source].constrainBy]: record[schema.properties[source].constrainBy] }
  }
  if (schema.properties[source].constrainBy) {
    const { field: { value } } = useInput({ source: schema.properties[source].constrainBy })
    if (value) {
      constraint = { [schema.properties[source].constrainBy]: value }
    }
  }

  return <>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <ReferenceInput
        key={source}
        source={source}
        reference={reference}
        {...(constraint ? { filter: constraint } : {})}
      ><AutocompleteInput {...(selected ? { defaultValue: selected } : {})} /></ReferenceInput>
      {!disableCreate && <Button variant='text' onClick={() => setShowModal(true)}>Create New {startCase(reference.replace('users', 'user'))}</Button>}
      {(selected || get(record, source)) && <Button variant='text' onClick={() => 
        redirect('edit', reference.replace('users', 'user'), selected ||  get(record, source))}>Edit Selected {startCase(reference.replace('users', 'user'))}</Button>}
    </div>
    <Dialog
      open={showModal}
    >
      <DialogTitle>Create New {startCase(reference)}</DialogTitle>
      <DialogContent>
        <Create resource={'address'} mutationOptions={{ onSuccess: ({ id }: any) => {
          setSelected(id)
          setShowModal(false)
        } }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowModal(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </>
}
