import ChartController from "../../components/charts/ChartController"
import TemporalLineChartWithPeriodSelector from "../../components/charts/aggregations/TemporalLineChart"
import DateRangePicker from "../../components/charts/filters/DateRangePicker"


const DeveloperDashboard = () => {

  return <ChartController currency="HTG" storageKey="developer-dashboard">
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <DateRangePicker id={'createdAt'} field={['createdAt']} />
    </div>
    <TemporalLineChartWithPeriodSelector
      id="transactionsByPeriod"
      service="transaction"
      aggregationId="dateHistogram"
      title="Transaction Volume"
      agg="sum"
      period="day"
      isCurrency={true}
      periods={['minute', 'hour', 'day', 'week', 'month', 'quarter', 'year']}
      keyField="createdAt"
      valueField="total_amount"
    />
    <TemporalLineChartWithPeriodSelector
      id="invitationsCreatedByPeriod"
      service="invitation"
      aggregationId="dateHistogram"
      title="Invitations Created"
      agg="count"
      period="day"
      periods={['minute', 'hour', 'day', 'week', 'month', 'quarter', 'year']}
      keyField="createdAt"
      valueField="id"
      config={{
        axisLeft: {
          format: '>-.0f',
          tickValues: 2,
        }
      }}
    />
  </ChartController>
}
export default DeveloperDashboard