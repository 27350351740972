import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from './components/Copyright'
import { useTranslate } from 'react-admin'

export default function ResetSuccess() {
  const translate = useTranslate()
  return (
      <Container key={1} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 160, height: 160 }}>
            <img style={{ width: '100%' }} src="/nclusion-ring-logo.png" />
          </div>
          <Typography component="h1" variant="h5">
            {translate('nclusion.successful_reset')}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography component="p" sx={{ textAlign: 'center', mb: 3}}>
              You have reset your password. <Link href="#/login">{translate('nclusion.sign_in')}</Link>
            </Typography>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
  );
}
