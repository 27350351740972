import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useDataProvider, useListContext, useTranslate } from 'react-admin'
import { useEffect, useState } from 'react'
import { omit } from 'lodash'

type AgentFilterOption = {
  id: number
  name: string
  bank_ids: number[]
}

const AgentFilter = ({
  alwaysOn,
  source,
  label
}: {
  alwaysOn?: boolean
  label?: string
  source: 'transaction/associate/id'
}) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()
  const [options, setOptions] = useState<AgentFilterOption[]>([])
  const [selected, setSelected] = useState<null | AgentFilterOption>(null)
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const getOptions = async (e: any, name: string) => {
    const newOptions = await dataProvider.getList('associate-bank-search', {
      filter: { name, associateType: 'agent' },
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'ASC' }
    })
    setOptions(newOptions?.data || [])
  }

  useEffect(() => {
    getOptions(null, '')
  }, [])

  useEffect(() => {
    if (selected) {
      setFilters(
        { ...filterValues, 'transaction/associate/id': selected.id },
        displayedFilters
      )
    } else {
      setFilters(
        omit(filterValues, ['transaction/associate/id']),
        displayedFilters
      )
    }
  }, [selected?.id])

  const onSelectAssociate = (e: any, data: null | AgentFilterOption) => {
    if (data) {
      setSelected(data)
    } else {
      setSelected(null)
    }
  }

  return (
    <Autocomplete
      sx={{ minWidth: 180 }}
      options={options.map((option) => ({
        ...option,
        label: `${option.name} #${option.id}`,
        key: option.name
      }))}
      onInputChange={getOptions}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={onSelectAssociate}
      renderInput={(params) => (
        <TextField {...params} label={translate('nclusion.agent')} />
      )}
    />
  )
}

export default AgentFilter
