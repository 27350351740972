import {
  Datagrid,
  DateField,
  Identifier,
  NumberField,
  RaRecord,
  ReferenceOneField,
  TextField,
  useResourceContext,
  useTranslate,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  FilterButton
} from 'react-admin'
import { getLocale, schemaFilters } from '../../util'
import { NclusionList } from '../../components/NclusionList'
import { useNavigate } from 'react-router-dom'


const Actions = () => {
  const translate = useTranslate()
  const navigate = useNavigate()

  return <TopToolbar>
    <Button onClick={() => navigate('/ticket-denormalized')} label={translate('nclusion.purchasedTickets')} />
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
}

export default () => {
  const locale = getLocale()
  const translate = useTranslate()

  return (
    <NclusionList
      actions={<Actions />}
      title={translate('nclusion.allTickets')}
      resource='ticket'
      filters={schemaFilters(useResourceContext())}
      filterDefaultValues={{ currency: 'HTG' }}
    >
      <Datagrid
        rowClick={(id: Identifier, resource: string, data: RaRecord) =>
          ['purchased', 'refunded'].includes(data.status) ? `/ticket-denormalized/${data.uuid}/show` : `${data.uuid}/show`
        }
        bulkActionButtons={false}
      >
        <TextField source='uuid' />
        <NumberField source='total_wager' sortable={false} locales={locale} />
        <NumberField source='total_paid' sortable={false} locales={locale} />
        <TextField source='status' />
        <ReferenceOneField
          source='lottery_id'
          reference='lottery'
          target='id'
        />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
        <DateField source='expiresAt' showTime />
      </Datagrid>
    </NclusionList>
  )
}
