import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  WithRecord,
  useTranslate
} from 'react-admin'
import ConfirmationField from '../../components/ConfirmationField'
import { schemaFilters } from '../../util'
import { isNumber } from 'lodash'
import { NclusionList } from '../../components/NclusionList'
import { langToLocale, getLanguage } from '../../i18nProvider'
import Associate from '../../components/Associate'

const ReconciliationList = () => {
  const translate = useTranslate()

  const listFilters = () => schemaFilters('reconciliation')

  return (
    <NclusionList filters={listFilters()}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <Associate prop="associate" />
        <Associate prop="conductor" />
        <ReferenceField source="bank_id" reference="bank" />
        <BooleanField source="initial" />
        <NumberField source="expected" />
        <NumberField source="actual" />
        <WithRecord
          label={translate('cashManagement.discrepancy')}
          render={({ expected, actual }) =>
            isNumber(expected) && isNumber(actual) ? (
              <span
                style={{
                  color: actual - expected >= 0 ? '#f44336' : '#66bb6a'
                }}
              >
                {Intl.NumberFormat(langToLocale(getLanguage())).format(expected - actual)}
              </span>
            ) : (
              <></>
            )
          }
        />
        <TextField source="reason" />
        <TextField source="explanation" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
        <DateField source="completedAt" showTime={true} />
        <BooleanField source="abandoned" />
        <WithRecord
          label="Approved"
          render={(record) =>
            record.completedAt ? (
              <ConfirmationField source="approved" showOnDecline={true} />
            ) : (
              <></>
            )
          }
        />
        <ReferenceField source="approver_id" reference="associate" />
        <DateField source="approvedAt" showTime={true} />
        <ReferenceField source="subtenant_id" reference="sub-tenant" />
        <ReferenceField source="tenant_id" reference="tenant" />
      </Datagrid>
    </NclusionList>
  )
}

export default ReconciliationList
