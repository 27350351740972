import * as React from 'react'
const { Suspense } = React
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useRecordContext, useTranslate, useCheckAuth } from 'ra-core'

import { sanitizeFieldRestProps } from 'react-admin'
import { FieldProps, fieldPropTypes } from 'ra-ui-materialui/src/field/types'
import { SxProps } from '@mui/system'
import AuthenticatedImage from './AuthenticatedImage'
import AttachFileIcon from '@mui/icons-material/AttachFile'

const AcceptedPreviewMimeTypesR = /image\/.*/

export const ImageField = <
  RecordType extends Record<string, any> = Record<string, any>
>(
  props: ImageFieldProps<RecordType> & { showPreview?: boolean }
) => {
  const { className, emptyText, source, src, title, showPreview, ...rest } =
    props
  const record = useRecordContext(props)
  const sourceValue = get(record, source as any)
  const translate = useTranslate()

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    ) : (
      <Typography
        component="div"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      />
    )
  }

  if (Array.isArray(sourceValue)) {
    return (
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <ul className={ImageFieldClasses.list}>
          {sourceValue.map((file: any, index: any) => {
            const fileTitleValue = get(file, title as any) || title
            const srcValue =
              get(file, src as any) || sourceValue[index].src || title

            if (!showPreview) {
              const href = srcValue + '?disposition=false'
              return (
                <li key={index}>
                  <AttachFileIcon />
                  <a href={href} target="_blank">
                    {titleValue}
                  </a>
                </li>
              )
            }

            return (
              <li key={index}>
                <AuthenticatedImage
                  alt={fileTitleValue}
                  title={fileTitleValue}
                  src={srcValue}
                  className={ImageFieldClasses.image}
                />
              </li>
            )
          })}
        </ul>
      </Root>
    )
  }

  const titleValue = get(record, title as any)?.toString() || title

  if (!showPreview) {
    return (
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <AttachFileIcon />
        <a href={sourceValue?.toString() + '?disposition=false'}>
          {titleValue}
        </a>
      </Root>
    )
  }

  return (
    <Root className={className} {...sanitizeFieldRestProps(rest)}>
      <AuthenticatedImage
        title={titleValue}
        alt={titleValue}
        src={sourceValue?.toString()}
        className={ImageFieldClasses.image}
      />
    </Root>
  )
}

// What? TypeScript loses the displayName if we don't set it explicitly
ImageField.displayName = 'ImageField'

ImageField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string
}

const PREFIX = 'RaImageField'

export const ImageFieldClasses = {
  list: `${PREFIX}-list`,
  image: `${PREFIX}-image`
}

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})({
  [`& .${ImageFieldClasses.list}`]: {
    display: 'flex',
    listStyleType: 'none'
  },
  [`& .${ImageFieldClasses.image}`]: {
    margin: '0.25rem',
    width: 200,
    height: 100,
    objectFit: 'contain'
  }
})

export interface ImageFieldProps<
  RecordType extends Record<string, any> = Record<string, any>
> extends FieldProps<RecordType> {
  src?: string
  title?: string
  sx?: SxProps
}
