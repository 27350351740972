import React, {
  Children,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect
} from 'react'
import {
  useInput,
  useTranslate,
  shallowEqual,
  RecordContextProvider
} from 'ra-core'
import { useTheme, styled } from '@mui/material/styles'
import {
  Labeled,
  InputHelperText,
  sanitizeInputRestProps
} from 'ra-ui-materialui'
import { FileInputPreview } from './FileInputPreview'
import clsx from 'clsx'
import FormHelperText from '@mui/material/FormHelperText'
import {
  useDropzone,
  type DropzoneOptions,
  type DropEvent,
  type FileRejection
} from 'react-dropzone'
import { host } from './ImageInputPortalForm'

import { type SvgIconProps } from '@mui/material'
import { type CommonInputProps } from 'react-admin'
import { type SxProps } from '@mui/system'

const transformFile = (file: File | unknown) => {
  if (!(file instanceof File)) {
    return file
  }

  const preview = URL.createObjectURL(file)
  const transformedFile = {
    rawFile: file,
    src: preview,
    title: file.name,
    mimetype: file.type,
    size: file.size
  }
  // // @ts-ignore
  // file.srcOld = file.src

  // // @ts-ignore
  // file.src = preview
  return transformedFile
}

const PREFIX = 'RaFileInput'

export const FileInputClasses = {
  dropZone: `${PREFIX}-dropZone`,
  removeButton: `${PREFIX}-removeButton`
}

const StyledLabeled = styled(Labeled, {
  name: PREFIX,
  overridesResolver: (prop, styles) => styles.root
})(({ theme }) => ({
  width: '100%',
  [`& .${FileInputClasses.dropZone}`]: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default)
  },
  [`& .${FileInputClasses.removeButton}`]: {}
}))

export interface NclusionImageInputProps extends CommonInputProps {
  accept?: DropzoneOptions['accept']
  children?: ReactNode
  className?: string
  maxSize?: DropzoneOptions['maxSize']
  minSize?: DropzoneOptions['minSize']
  multiple?: DropzoneOptions['multiple']
  options?: DropzoneOptions
  removeIcon?: FC<SvgIconProps>
  onRemove?: (file: File) => void
  validateFileRemoval?(file: File): boolean | Promise<boolean>
  labelMultiple?: string
  labelSingle?: string
  sx?: SxProps
  form?: string
  inputProps?: any
  placeholder?: ReactNode
  onChangeControllerValue?: (files: File[] | File | null) => void
  disableButton?: boolean
}
export const NclusionImageInput: FC<NclusionImageInputProps> = ({
  accept,
  children,
  className,
  label,
  labelMultiple = 'ra.input.file.upload_several',
  labelSingle = 'ra.input.file.upload_single',
  helperText,
  placeholder,
  inputProps: inputPropsOptions,
  minSize,
  maxSize,
  removeIcon,
  options = {}, // Dropzone Options
  format,
  multiple = false,
  onRemove: onRemoveProp,
  validateFileRemoval,
  parse,
  source,
  validate,
  disabled,
  resource,
  readOnly,
  form,
  onChangeControllerValue,
  disableButton,
  ...rest
}) => {
  const { onDrop: onDropProp } = options

  const translate = useTranslate()

  const transformFiles = (files: any[]) => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    field: { onChange, onBlur, value },
    fieldState,
    formState: { isSubmitted },
    isRequired
  } = useInput({
    format: format || transformFiles,
    parse: parse || transformFiles,
    source: `fileUpload-${source}`,
    validate,
    disabled,
    readOnly,
    ...rest
  })

  const { isTouched, error, invalid } = fieldState
  const files = value ? (Array.isArray(value) ? value : [value]) : []
  const onDrop = async (
    newFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    if (multiple) {
      onChange(updatedFiles)
      onChangeControllerValue && onChangeControllerValue(updatedFiles)
      onBlur()
    } else {
      onChange(updatedFiles[0])
      onChangeControllerValue && onChangeControllerValue(updatedFiles[0])
      onBlur()
    }

    if (onDropProp) {
      onDropProp(newFiles, rejectedFiles, event)
    }
  }
  const onRemove = (file: File) => async () => {
    if (validateFileRemoval) {
      try {
        await validateFileRemoval(file)
      } catch (e) {
        return
      }
    }
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file)
      )
      onChange(filteredFiles as any)
      onChangeControllerValue && onChangeControllerValue(filteredFiles)
      onBlur()
    } else {
      onChange(null)
      onChangeControllerValue && onChangeControllerValue(null)
      onBlur()
    }

    if (onRemoveProp) {
      onRemoveProp(file)
      onChangeControllerValue && onChangeControllerValue(null)
    }
  }

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? (Children.only(children) as ReactElement<any>)
      : undefined

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple,
    disabled: disabled || readOnly,
    ...options,
    onDrop
  })

  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid)
  const theme = useTheme()
  const isButtonDisabled =
    value == null || disableButton === true ? true : disabled

  return (
    <StyledLabeled
      htmlFor={id}
      label={label}
      className={clsx('ra-input', `ra-input-${source}`, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      color={(isTouched || isSubmitted) && invalid ? 'error' : undefined}
      sx={{
        cursor: disabled || readOnly ? 'default' : 'pointer',
        ...rest.sx
      }}
      {...sanitizeInputRestProps(rest)}
    >
      <>
        <div
          {...getRootProps({
            className: FileInputClasses.dropZone,
            'data-testid': 'dropzone',
            style: {
              color:
                disabled || readOnly
                  ? theme.palette.text.disabled
                  : inputPropsOptions?.color || theme.palette.text.primary,
              backgroundColor:
                disabled || readOnly
                  ? theme.palette.action.disabledBackground
                  : inputPropsOptions?.backgroundColor
            }
          })}
        >
          <input
            id={`imageinput-${id}`}
            name="files"
            {...getInputProps({
              ...inputPropsOptions
            })}
            form={form}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        {renderHelperText ? (
          <FormHelperText error={(isTouched || isSubmitted) && invalid}>
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          </FormHelperText>
        ) : null}
        {children && (
          <div className="previews">
            {files.map((file, index) => (
              <FileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={FileInputClasses.removeButton}
                removeIcon={removeIcon}
              >
                <RecordContextProvider value={file}>
                  {childrenElement}
                </RecordContextProvider>
              </FileInputPreview>
            ))}
          </div>
        )}
        <button
          id={`imageinput-btn-image_url`}
          type="submit"
          form={form}
          disabled={isButtonDisabled}
          className={clsx(
            'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-y70s1k-MuiButtonBase-root-MuiButton-root-RaSaveButton-root',
            { 'Mui-disabled': isButtonDisabled }
          )}
        >
          Upload
        </button>
      </>
    </StyledLabeled>
  )
}
