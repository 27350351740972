import app, { envMap, restClient } from './feathersClient'
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult
} from 'react-admin'
import appState from './appState'

import {
  diffToSetAndUnset,
  flattenObject,
  handleComparisonFilters,
  safeParseJSON,
  unflattenObject
} from './util'
import { Ticket } from '@nclusion/feathers-client'
import {
  camelCase,
  flow,
  isObject,
  isString,
  mapValues,
  omitBy,
  pickBy
} from 'lodash'

type ServiceName = Parameters<typeof app.service>[0]

const host =
  process.env.TOUSSAINT_API_URL ||
  envMap[window.location.origin] ||
  'http://localhost:3030'

const handleError = (e: any) => {
  appState.notify(e.message || `${e}`, { type: 'error', multiline: true })
  return { data: { id: 'error' } }
}

const getClient = (): any => (appState.user ? app : restClient)

const update = async (
  resource: ServiceName,
  { id, data, previousData }: UpdateParams
): Promise<UpdateResult> => {
  const { $set, $unset } = diffToSetAndUnset(data, previousData)

  const result = await getClient()
    .service(resource)
    .patch(id, unflattenObject({ ...$set, ...$unset }), {}, {} as any)
    .catch(handleError)

  return { data: result }
}

const del = async (
  resource: ServiceName,
  { id }: DeleteParams
): Promise<DeleteResult> => {
  const result = await getClient()
    .service(resource)
    .remove(id, {}, null as any)
    .catch(handleError)

  return { data: result }
}

const idSwapServices: Partial<{ [k in ServiceName]: string }> = {
  ticket: 'uuid',
  'ticket-denormalized': 'uuid'
}

type RAFilterValues = { [k: string]: any }
type FilterProps = { [k: string]: string }

const prepareJSONFilters: (f: RAFilterValues) => FilterProps = flow(
  flattenObject,
  Object.entries,
  (values) =>
    values.reduce((filters: RAFilterValues, [key, val]: [string, any]) => {
      const [column, ...path] = key.split('/')
      filters[column] = filters[column] || []

      if (isObject(val as { value: any; filter: string } | any)) {
        filters[column].push(JSON.stringify(val.filter))
        val = (val as any).value
      }

      filters[column].push(
        JSON.stringify({ path: path.join('.'), operator: '=', value: val })
      )
      return filters
    }, {}),
  (filters) =>
    mapValues(
      filters,
      (jsonStringArray: string[]) => `[${jsonStringArray.join(',')}]`
    )
)

const isJsonField = (schema: any) => (val: any, key: string) =>
  schema?.jsonFields?.includes(key.split('/')[0])

export const prepJSONFields = (
  schema: any,
  filter: RAFilterValues,
  meta?: GetListParams['meta']
) => {
  const isJson = isJsonField(schema)
  const jsonFilters: RAFilterValues = pickBy(filter, isJson)
  if (meta?.jsonReferenceFilter) {
    Object.assign(jsonFilters, meta.jsonReferenceFilter)
  }

  return {
    ...omitBy(filter, isJson),
    ...prepareJSONFilters(jsonFilters)
  }
}

// show error toast etc
export default {
  getList: async (
    resource: ServiceName,
    { filter, sort, pagination, meta }: GetListParams
  ): Promise<GetListResult> => {
    if ((resource as unknown) === 'googles') {
      return { data: [], total: 0 }
    }

    const schema =
      app.get('schemas')[camelCase(resource === 'users' ? 'user' : resource)]

    const { data, total } = await getClient()
      .service(resource)
      .find(
        {
          query: {
            ...(await handleComparisonFilters(
              resource,
              prepJSONFields(schema, filter, meta)
            )),
            ...(sort
              ? { $sort: { [sort.field]: sort.order === 'ASC' ? 1 : -1 } }
              : {}),
            $skip: pagination.perPage * (pagination.page - 1),
            $limit: pagination.perPage
          }
        },
        null as any
      )
      .catch((e: any) => {
        handleError(e)
        localStorage.removeItem(`RsStore.${resource}.listParams`)
        return { data: [], total: 0 }
      })

    return { data, total }
  },

  getOne: async (
    resource: ServiceName,
    { id }: GetOneParams
  ): Promise<GetOneResult> => {
    const result: Ticket = await getClient()
      .service(resource)
      .get(id, {}, null as any)
      .catch((e: any) => {
        handleError(e)
        return { data: { id } }
      })

    if (Object.keys(idSwapServices).includes(resource)) {
      result.id = result[idSwapServices[resource] as keyof typeof result] as any
    }

    if (resource === 'bank') {
      // @ts-ignore
      if (typeof result.profile_pic_id === 'string') {
        // @ts-ignore
        const profilePicId = result.profile_pic_id
        const url = `${host}/file-upload/${profilePicId}`
        // @ts-ignore
        result[`fileUpload-profile_pic_id`] = [
          {
            id: profilePicId,
            title: 'profile_pic',
            src: url
          }
        ]
      }
      if (
        // @ts-ignore
        typeof result.documents_id == 'number' &&
        // @ts-ignore
        result.documents.length > 0
      ) {
        // @ts-ignore
        result[`fileUpload-documents_id`] = result.documents.map((doc) => {
          return {
            doc_id: doc.id,
            id: doc.file_upload.id,
            title: doc.file_upload.filename,
            src: `${host}/file-upload/${doc.file_upload.id}`
          }
        })
      }
    }

    return { data: result }
  },

  getMany: async (
    resource: ServiceName,
    { ids, meta }: GetManyParams
  ): Promise<GetManyResult> => {
    ids = ids.map((id) => (isString(id) ? safeParseJSON(id).value || id : id))
    const { $limit } = meta || {}
    const { sort } = meta || {}
    const { data } = await getClient()
      .service(resource)
      .find(
        {
          query: {
            id: { $in: ids },
            $limit,
            ...(sort
              ? { $sort: { [sort.field]: sort.order === 'ASC' ? 1 : -1 } }
              : {})
          }
        },
        null as any
      )
      .catch((e: any) => {
        handleError(e)
        return { data: [] }
      })

    return { data }
  },

  getManyReference: async (
    resource: ServiceName,
    { target, id, pagination, sort, filter }: GetManyReferenceParams
  ): Promise<GetManyReferenceResult> => {
    const result = await getClient()
      .service(resource)
      .find({
        query: {
          [target]: id,
          ...filter,
          ...(sort
            ? { $sort: { [sort.field]: sort.order === 'ASC' ? 1 : -1 } }
            : {}),
          $skip: pagination.perPage * (pagination.page - 1),
          $limit: pagination.perPage
        }
      })
      .catch((e: any) => {
        handleError(e)
        return { data: [], total: 0 }
      })

    return result || { data: [], total: 0 }
  },

  create: async (
    resource: ServiceName,
    { data }: CreateParams
  ): Promise<CreateResult> => {
    const result = await getClient()
      .service(resource)
      .create(data, {}, {} as any)
      .catch(handleError)

    return { data: result }
  },

  update: async (...args: [any, any]) => {
    const dataKeys = Object.keys(args[1].data)
    for (let i = 0; i < dataKeys.length; i++) {
      if (dataKeys[i].match(/^fileUpload[-]{1}/) != null) {
        args[1].data[dataKeys[i]] = null
        delete args[1].data[dataKeys[i]]
      }
    }
    const prevKeys = Object.keys(args[1].previousData)
    for (let i = 0; i < prevKeys.length; i++) {
      if (prevKeys[i].match(/^fileUpload[-]{1}/) != null) {
        args[1].data[prevKeys[i]] = null
        delete args[1].previousData[prevKeys[i]]
      }
    }
    args[1].id = parseInt(args[1].id)

    return update(...args)
  },

  updateMany: async (resource: ServiceName, params: any) => ({
    data: await Promise.all(
      params.ids.map((id: string | number) =>
        update(resource, { ...params, id })
      )
    )
  }),

  delete: async (...args: [any, any]) => del(...args),

  deleteMany: async (resource: ServiceName, params: any) => ({
    data: await Promise.all(
      params.ids.map((id: string | number) => del(resource, { ...params, id }))
    )
  })
}
