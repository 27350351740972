
import { useListContext } from "react-admin"
import ServiceQuery from "../filters/ServiceQuery"
import ChartController from "../ChartController"

type LCATBProps = { children: JSX.Element | JSX.Element[], listen?: string[] }

const ListContextAwareTotalsBar = ({ children, listen }: LCATBProps) => {
  const { filterValues } = useListContext()

  return <ChartController key={filterValues.currency || 'currency'} currency={filterValues.currency} listen={listen as any || []}>
    <ServiceQuery key={JSON.stringify(filterValues)} filterValues={filterValues} />
    <>{children}</>
  </ChartController>
}

export default ListContextAwareTotalsBar