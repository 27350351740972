import { Identifier, RaRecord } from 'react-admin'
import { formatNumbersOrSales } from '../../util'
import { clamp } from 'lodash'

export const getNetPurchase = (record: RaRecord<Identifier>) => {
  const purchase = record.purchase || 0
  const refund = record.refund || 0

  return formatNumbersOrSales(
    Number(purchase) - Number(refund),
    record.currency
  )
}

export const getRemainingPayout = (record: RaRecord<Identifier>) => {
  const payout = record.payout || 0
  const redeemed = record.redemption || 0

  return formatNumbersOrSales(
    clamp(Number(payout) - Number(redeemed), 0, Infinity),
    record.currency
  )
}

export const getTotalCash = (record: RaRecord<Identifier>) => {
  const purchase = record?.purchase || 0
  const refund = record?.refund || 0
  const netSales = Number(purchase) - Number(refund) || 0
  const agentCommission = record?.agent_commission || 0
  const totalPayout = record?.payout || 0

  return Number(netSales) - Number(agentCommission) - Number(totalPayout)
}
