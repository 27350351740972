import {
  Datagrid,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
  useTranslate,
  WrapperField,
  ReferenceInput,
  AutocompleteInput,
  NumberField,
  FunctionField
} from 'react-admin'
import { RegexHilighter, RegexPosition } from '../../components/RegexField'
import { getLocale, schemaFilters } from '../../util'
import { NclusionList } from '../../components/NclusionList'

export default () => {
  const translate = useTranslate()
  const additionalFilters = [
    <ReferenceInput source="game_id" reference="game">
      <AutocompleteInput
        source="game_id"
        optionText={(record) => translate(record.name)}
        inputText={(choice) => translate(choice.name)}
      />
    </ReferenceInput>
  ]

  const listFilters = () =>
    schemaFilters('rtp', additionalFilters, [
      'allocation',
      'drawing_id',
      'game_id',
      'session_id'
    ])

  return (
    <NclusionList resource="rtp" filters={listFilters()}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        {/* <ReferenceField source='game_id' reference='game'> */}
        <FunctionField
          render={(record: { name: string }) => translate(record.name)}
        />
        {/* </ReferenceField> */}
        <TextField source="match_regex" />
        <WrapperField label={translate('nclusion.positionDescription')}>
          <RegexPosition />
        </WrapperField>
        <WrapperField label={translate('nclusion.positionHighlighted')}>
          <RegexHilighter />
        </WrapperField>
        <NumberField source="multiplier" locales={getLocale()} />
        <BooleanField source="mutex" />
        <TextField source="promotion" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </NclusionList>
  )
}
