import {
  AutocompleteInput,
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  FilterButton,
  FunctionField,
  ReferenceInput,
  TopToolbar,
  UrlField,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import {
  formatDrawingName,
  formatWinningNumbersString,
  schemaFilters,
} from '../../util'
import { DrawingNumbers } from '@nclusion/feathers-client'
import ConfirmationField from '../../components/ConfirmationField'
import authProvider from '../../authProvider'
import { NclusionList } from '../../components/NclusionList'

type ConfirmationFilter = {
  confirmed?: boolean
  date?: {}
  drawing_id?: undefined
}

const WinningTickets = ({
  source,
  sortable,
}: {
  source: string
  sortable: boolean
}) => {
  let { id, tickets }: DrawingNumbers | any = useRecordContext()
  const translate = useTranslate()
  tickets = tickets || { winning_tickets: 0, total_wager: 0, total_payout: 0 }

  return (
    <div
      style={{
        minWidth: 240,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1rem 1rem',
        gap: 8,
      }}
    >
      <span>
        {translate('nclusion.winning_tickets')}:{' '}
        <span style={{ fontWeight: 'bold' }}>{tickets.winning_tickets}</span>
      </span>
      <span>
        {translate('nclusion.total_wager')}:{' '}
        <span style={{ fontWeight: 'bold' }}>{tickets.total_wager}</span>
      </span>
      <span>
        {translate('nclusion.rtp')}:{' '}
        <span style={{ fontWeight: 'bold' }}>
          {tickets.total_wager
            ? ((tickets.total_payout / tickets.total_wager) * 100).toFixed(1)
            : '0'}
          %
        </span>
      </span>
      <span>
        {translate('nclusion.total_payout')}:{' '}
        <span style={{ fontWeight: 'bold' }}>{tickets.total_payout}</span>
      </span>
    </div>
  )
}

export default ({
  confirmationFilter,
}: {
  confirmationFilter?: ConfirmationFilter
}) => {
  const translate = useTranslate()
  const { permissions } = authProvider.getIdentitySync()
  const canConfirm = permissions.find(
    (p: any) => 
      p.method === 'patch' &&
      p.visibility === 'lottery' &&
      p.service === 'drawing-numbers'
  )

  const ListActions = () => (
    <TopToolbar>
      {confirmationFilter ? <CreateButton /> : <FilterButton />}
    </TopToolbar>
  )
  const additionalFilters = [
    <DateInput source='date' />,
    <ReferenceInput source='drawing_id' reference='drawing'>
      <AutocompleteInput
        source='drawing_id'
        optionText={record => translate(record.name)}
        inputText={choice => translate(choice.name)}
      />
    </ReferenceInput>,
  ]
  const listFilters = () =>
    confirmationFilter
      ? undefined
      : schemaFilters('drawingNumbers', additionalFilters, [
          'date',
          'drawing_id',
          'id',
        ])

  return (
    <NclusionList
      resource='drawing-numbers'
      filters={confirmationFilter ? undefined : listFilters()}
      actions={<ListActions />}
      filter={confirmationFilter}
    >
      <Datagrid
        rowClick={() => (canConfirm ? 'show' : false)}
        bulkActionButtons={false}
      >
        <FunctionField
          source='drawing.name'
          sortable={false}
          render={(record: { drawing: { name: string } }) =>
            formatDrawingName(record.drawing.name)
          }
        />
        <FunctionField
          source='winning_numbers'
          render={(record: { winning_numbers: string }) =>
            formatWinningNumbersString(record.winning_numbers)
          }
        />
        <DateField source='date' />
        {!canConfirm && <BooleanField source='confirmed' />}
        {canConfirm && (
          <>
            {confirmationFilter ? (
              <ConfirmationField source='confirmed' />
            ) : (
              <BooleanField source='confirmed' />
            )}
          </>
        )}
        <DateField source='createdAt' showTime={true} />
        {confirmationFilter ? (
          <UrlField source='drawing.link' target='_blank' sortable={false} />
        ) : (
          <WinningTickets source='tickets' sortable={false} />
        )}
      </Datagrid>
    </NclusionList>
  )
}
