import {
  Create,
  TabbedForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  DateInput,
  required,
  AutocompleteInput,
  SelectInput,
  useTranslate
} from 'react-admin'
import { getType } from '../../components/FeathersCRUD'
import SelectOrCreateInModal from '../../components/SelectOrCreateInModal'
import app from '../../feathersClient'
import { startCase } from 'lodash'

export default () => {
  const translate = useTranslate() 
  const bankSchema = app.get('schemas').bank
  const choices = getType(bankSchema.properties.status)

  return <Create>
    <TabbedForm>
      <TabbedForm.Tab label={translate('nclusion.info')}>
        <TextInput source='name' validate={[required()]} fullWidth />
        <TextInput source='image_url' fullWidth/>
        <TextInput source='building_type' fullWidth />
      </TabbedForm.Tab>
      <TabbedForm.Tab label={translate('nclusion.tenantInfo')}>
        <ReferenceInput reference='tenant' source='tenant_id'>
          <AutocompleteInput label='Tenant' fullWidth />
        </ReferenceInput>
        <ReferenceInput reference='sub-tenant' source='subtenant_id'>
          <AutocompleteInput label='Sub Tenant' fullWidth />
        </ReferenceInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab label={translate('nclusion.leaseInfo')}>
        <NumberInput source='lease_amount' />
        <DateInput source='lease_start_timestamp'  />
        <DateInput source='lease_end_timestamp' />
        <DateInput source='open_since_timestamp' />
        <DateInput source='closed_timestamp' />
      </TabbedForm.Tab>
      <TabbedForm.Tab label={translate('nclusion.contactInfo')}>
        <TextInput source='contact_name' fullWidth />
        <TextInput source='contact_phone_number' fullWidth />
        <TextInput source='contact_email_address' fullWidth />
        <SelectOrCreateInModal 
          source={'address_id'}
          schema={bankSchema}
          reference={'address'}
          fullWidth
        />
        <SelectInput source='status' label='Status' fullWidth
          choices={choices?.enum?.map((val: any) =>
            ({ id: val, name: typeof val === 'string' ? startCase(val): val }))}
        />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
}
