import { AutocompleteInput, ReferenceInput, useCreateContext, useEditContext, useListContext, useTranslate } from "react-admin"

const DynamicReferenceFilter = ({ source, reference }: { source: string, reference: string }) => {
  const { filterValues } = useListContext()

  const translate = useTranslate()

  const dynamicReference = filterValues ? filterValues[reference] : null

  return dynamicReference ? <ReferenceInput source={source} reference={dynamicReference}>
    <AutocompleteInput />
  </ReferenceInput> : <span>{translate('nclusion.select')} {translate(`nclusion.${reference}`)}</span>
}

export default DynamicReferenceFilter
