import { Customer, Transaction, Ticket, Relationship, CryptoExchange } from "@nclusion/feathers-client"
import { TenantPromotion } from "@nclusion/feathers-client"
import { Address, Lls, User, Tenant, Promotion, SubTenant, Merchant, Lottery, Drawing, Game, Session, GameDrawing, Mfi, Bank, Role, Entity, Allocation, Account } from "@nclusion/feathers-client"
import PostalAddress from 'i18n-postal-address'
import { omit } from 'lodash'
import i18nProvider from './i18nProvider'

type NameBearer = { name: string}
type IdAndUserBearer = {
  id: number
  user: User
}

export const user = (u: User) => [u?.firstName, u?.lastName].filter(x => x).join(' ')

const userNameAndLocalId = <T extends IdAndUserBearer>({ id, user: u }: T) => `${user(u)} #${id}`
const name = <T extends NameBearer>({ name }: T) => name

export const address = (a: Address) => new PostalAddress(omit(a, ['firstName', 'lastName']) as any).toString()

//export const customer = userNameAndLocalId
export const associate = userNameAndLocalId

export const tenant = name<Tenant>
export const subTenant = name<SubTenant>
export const merchant = name<Merchant>
export const lottery = name<Lottery>
export const drawing = ({ name }: Drawing) => `${i18nProvider.translate(`game.${name.split('.')[2]}`)} ${i18nProvider.translate(`game.${name.split('.')[3]}`)}`
export const game = ({ name }: Game) => `${i18nProvider.translate(`game.${name.split('.')[2]}`)}`
export const session = name<Session>
export const gameDrawing = name<GameDrawing>
export const mfi = name<Mfi>
export const bank = name<Bank>
export const role = name<Role>
export const entity = name<Entity>
export const allocation = name<Allocation>
export const account = name<Account>
export const customer = name<Customer>
export const lls = name<Lls>
export const transaction = name<Transaction>
export const ticket = name<Ticket>
export const promotion = name<Promotion>
export const tenantPromotion = name<TenantPromotion>
export const relationship = name<Relationship>
export const cryptoExchange = name<CryptoExchange>