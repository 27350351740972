import { Edit, useTranslate } from 'react-admin'
import TranslationForm from './Form'
import RelatedTranslations from './RelatedTranslations'

let CreateComponent = () => {
  const translate = useTranslate()

  return <Edit>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}>
      <TranslationForm />
      <div>
        <h3 style={{ marginBottom: 0 }}>{translate('nclusion.relatedTranslations')}</h3>
        <RelatedTranslations />
      </div>
    </div>
  </Edit>
}

export default CreateComponent
