import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useNavigate } from 'react-router-dom'
import { BooleanField, useRecordContext, useResourceContext, useUpdate } from 'react-admin'

const ConfirmationField = ({ source = 'confirmed', showOnDecline = false }: { source?: string, showOnDecline?: boolean }) => {
  const navigate = useNavigate()
  const record = useRecordContext()
  const resource = useResourceContext()
  const [update] = useUpdate()

  return record[source] ? <BooleanField source={source} color='green' />
    : <> <CheckCircleOutlineIcon color='success'
      onClick={e => {
        e.stopPropagation()
        update(resource, {
          data: { [source]: true },
          previousData: { [source]: false },
          id: record.id
        })
      }} />
    <HighlightOffIcon color='error'
      onClick={e => {
        e.stopPropagation()
        navigate(`/${resource}/${record.id}${showOnDecline ? '/show' : ''}`)
      }} />
  </>
}

export default ConfirmationField
