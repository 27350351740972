import { useState, Fragment, useEffect } from 'react'
import { createPortal } from 'react-dom'
// import { ImageField } from 'react-admin'
import { NclusionImageInput } from './ImageInput'
import { ImageInputPortalForm } from './ImageInputPortalForm'
import { ImageField } from './ImageField'
import { useInput } from 'ra-core'
import { useRecordContext } from 'react-admin'
import { envMap } from '../feathersClient'

const host =
  process.env.TOUSSAINT_API_URL ||
  envMap[window.location.origin] ||
  'http://localhost:3030'

const isProfilePicABankDocument = false

// TODO: localize
const labels = {
  profile_pic_id: 'Bank Profile Photo',
  documents_id: 'Uploaded Documents'
}

export const FORM_STATUS = Object.freeze({
  DISABLED: Symbol('disabled'),
  ENABLED: Symbol('enabled')
})

export const toggleGlobalFormButton = (status: symbol, globalOnly = true) => {
  const buttons = Array.from(
    document.querySelectorAll('button[type="submit"]')
  ).filter((el) => {
    return globalOnly ? !el.hasAttribute('form') : true
  }) as HTMLButtonElement[]
  switch (status) {
    case FORM_STATUS.DISABLED:
      buttons.forEach((el) => {
        el.disabled = true
        el.classList.add('Mui-disabled')
      })
      break
    case FORM_STATUS.ENABLED:
    default:
      buttons.forEach((el) => {
        el.disabled = false
        el.classList.remove('Mui-disabled')
      })
      break
  }
}

export const addToBankDocuments =
  ({ bank_id }: { bank_id: number }) =>
  async (file: Record<string, any>) => {
    const jwt = localStorage.getItem('feathers-jwt')
    if (!jwt) {
      throw new Error('Not Authenticated')
    }
    const file_upload_id = file.id

    return fetch(`${host}/bank-document`, {
      method: 'POST',
      body: JSON.stringify({ bank_id, file_upload_id }),
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
  }

export interface ImageInputControllerProps {
  propName: string
  formId: string
  portalOptions?: Record<string, any>
  subTypeOptions?: Record<string, any>
  uploadKey?: string
}
export const ImageInputController = ({
  propName,
  formId,
  portalOptions = {},
  subTypeOptions = {},
  uploadKey = 'file',
  ...props
}: ImageInputControllerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [filesObj, setFilesObj] = useState<File | File[] | null>(null)
  const multiple = subTypeOptions.multiple || false

  const { id } = useRecordContext()

  const {
    field: { onChange: onChangeReferenceId, value }
  } = useInput({
    source: propName
  })

  useEffect(() => {
    if (isLoading === true) {
      toggleGlobalFormButton(FORM_STATUS.DISABLED)
    } else {
      toggleGlobalFormButton(FORM_STATUS.ENABLED)
    }
  }, [isLoading])

  let onRemove = ({ ...props }) => {
    const jwt = localStorage.getItem('feathers-jwt')
    if (!jwt) {
      throw new Error('Not Authenticated')
    }

    if (props.doc_id) {
      return fetch(`${host}/bank-document/${props.doc_id}`, {
        method: 'DELETE',

        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
    }
  }

  return (
    <Fragment key={`fragment-${propName}`}>
      <NclusionImageInput
        key={propName}
        source={propName}
        label={labels[propName as keyof typeof labels]}
        form={formId}
        multiple={multiple}
        disabled={isLoading}
        disableButton={filesObj === null}
        onChangeControllerValue={(files) => {
          setFilesObj(files)
          if (files === null) {
            onChangeReferenceId(null)
          }
        }}
        onRemove={onRemove}
      >
        <ImageField
          source="src"
          title="title"
          showPreview={
            typeof subTypeOptions.preview != 'undefined'
              ? subTypeOptions.preview
              : true
          }
        />
      </NclusionImageInput>
      {multiple === false && (
        <input
          id={propName}
          type="hidden"
          name={propName}
          value={value}
          onChange={(event) => {
            if (event.target.value) {
              onChangeReferenceId(event.target.value)
            }
          }}
        />
      )}
      {/* {multiple == true && } */}
      {document.body &&
        portalOptions?.resource &&
        createPortal(
          <div
            id="featherscrud-portal-subform"
            style={{ visibility: 'visible' }}
          >
            <ImageInputPortalForm
              id={formId}
              resourceAssetKey={propName}
              resource={portalOptions.resource}
              onSubmitWithInfo={({ resource, record }) =>
                async (event) => {
                  event.preventDefault()
                  setIsLoading(true)
                  const isUploadingFlag = true

                  try {
                    const form: HTMLElement | null =
                      document.getElementById(formId)
                    if (!form) {
                      throw new Error(`No form found with id ${formId}`)
                    }

                    const formData = new FormData()
                    const jwt = localStorage.getItem('feathers-jwt')
                    if (!jwt) {
                      throw new Error('Not Authenticated')
                    }

                    // TODO: check if formData is missing files (b/c nested form={x} didn't work)
                    let file = filesObj
                    if (file) {
                      if (file instanceof Array) {
                        if (multiple) {
                          // TODO: handle multiple by refactoring into separate fetch calls over a map?
                          file.forEach((f) => {
                            formData.append(uploadKey, f)
                          })
                        } else {
                          file = file[0]
                          formData.append(uploadKey, file)
                        }
                      } else {
                        formData.append(uploadKey, file, file.name)
                      }
                    }

                    isUploadingFlag &&
                      fetch(`${host}/file-upload`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                          Authorization: `Bearer ${jwt}`
                        }
                      })
                        .then((response) => {
                          return response.json()
                        })
                        .then(async (response) => {
                          if (multiple) {
                            // no changes to referenceId
                            await Promise.all(
                              response.map(
                                addToBankDocuments({ bank_id: id as number })
                              )
                            )
                          } else {
                            if (response instanceof Array) {
                              onChangeReferenceId(response[0].id)
                              if (isProfilePicABankDocument) {
                                await Promise.all(
                                  response.map(
                                    addToBankDocuments({
                                      bank_id: id as number
                                    })
                                  )
                                )
                              }
                            } else {
                              onChangeReferenceId(response.id)
                              if (isProfilePicABankDocument) {
                                await addToBankDocuments({
                                  bank_id: id as number
                                })(response.id)
                              }
                            }
                          }
                        })
                        .catch((err) => {
                          throw err // TODO: display error message here
                        })
                        .finally(() => {
                          setIsLoading(false)
                          setFilesObj(null)
                        })
                  } catch (e) {
                    console.error(e)
                    setIsLoading(false)
                  }
                }}
            />
          </div>,
          document.body
        )}
    </Fragment>
  )
}
