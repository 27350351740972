import { Box } from "@mui/material"
import { DateInput, DateTimeInput, useTranslate } from "react-admin"

export const DateFilter = ({ source, alwaysOn, label, day }: { source: string, alwaysOn?: boolean, label?: string, day?: boolean }) => {
  const translate = useTranslate()
  const Component = day ? DateInput : DateTimeInput

  return <Box>
      <Component label={translate(`nclusion.${source}.start`)} source={`${source}${day ? '_day' : ''}_gte`} sx={{ mr: 1}} alwaysOn />
      <Component label={translate(`nclusion.${source}.end`)} source={`${source}${day ? '_day' : ''}_lt`} alwaysOn />
  </Box>
}
