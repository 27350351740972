import { Show, useTranslate, TextField, BooleanField, SimpleShowLayout } from 'react-admin'
import RelatedTranslations from './RelatedTranslations'

let CreateComponent = () => {
  const translate = useTranslate()

  return <Show>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}>
      <SimpleShowLayout>
        <TextField source="language" />
        <TextField source="country" />
        <TextField source="key" />
        <TextField source="text" />
        <BooleanField source="confirmed" />
      </SimpleShowLayout>
      <div>
        <h4 style={{ marginBottom: 0 }}>{translate('nclusion.allTranslations')} <TextField source="key" /></h4>
        <RelatedTranslations />
      </div>
    </div>
  </Show>
}

export default CreateComponent
