import ChartContext from '../ChartContext'
import { ResponsiveBar } from '@nivo/bar'
import { useContext, useEffect, useState } from 'react'
import { useTheme, useTranslate } from 'react-admin'
import { castArray, intersection } from 'lodash'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Card } from '@mui/material'
import { AggregateFunction, ClientApplication, GroupedAggregation, ScalarAggregate, SimpleGroup } from '@nclusion/feathers-client'

export type BarChartProps = Omit<GroupedAggregation, 'service'> & {
  id: string
  labels?: any
  config?: any
  title?: string
  selectOptions?: string[]
  height?: number
  transform?: (d: BarChartDataPoint[]) => any
  agg?: AggregateFunction
  service: Parameters<ClientApplication['service']>[0]
}

const aggOptions = ['count', 'sum', 'avg', 'min', 'max', 'uniq']

type BarChartDataPoint = {
  data: {
    [k: string]: string | number
  }
}

const BarChart = ({ id, aggregationId = 'simpleGroup', agg, service, keyField, valueField, config = {}, selectOptions, title = '', height = 320, transform }: BarChartProps) => {
  const { search: { registerAggregation, onResults, savedValues } } = useContext(ChartContext)
  const translate = useTranslate()
  const [results, setResults] = useState<BarChartDataPoint[]>([])
  const [theme] = useTheme()
  const [selectValue, setSelectValue] = useState((savedValues?.aggregations[id] as ScalarAggregate & { agg: AggregateFunction })?.agg || agg || 'count')

  onResults(id, ({ results }: any) => {
    switch (selectValue) {
      case 'count':
      case 'uniq':
        setResults(results)
        break
      default: 
        setResults(results.map((r: {id: string, value: string}) => {return {...r, value: Number(r.value).toFixed(2)}}))
    }
  })

  useEffect(() => {
    registerAggregation({
      id,
      service,
      keyField,
      valueField,
      agg: selectValue,
      aggregationId
    } as SimpleGroup)
  }, [selectValue])

  return results ? <Card sx={{ height, padding: 4 }}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <h3 style={{ margin: 0 }}>{title}</h3>
      {intersection<string>(aggOptions, selectOptions).length > 1 ?
        <Select
          value={selectValue}
          onChange={(e: SelectChangeEvent) => setSelectValue(e.target.value as AggregateFunction)}
          style={{ width: 100, margin: 10}}
        >
          {intersection<string>(aggOptions, selectOptions).map(a => <MenuItem value={a} key={a}>{a}</MenuItem>)}
        </Select> :
        <></>
      }
    </div>
    <ResponsiveBar
      indexBy={keyField}
      keys={castArray(valueField)}
      data={transform ? transform(results) : results}
      colors={{ scheme:  theme === 'dark' ? 'dark2' : 'set2' }}
      theme={{ text: { fill:  theme === 'dark' ? '#eee' : '#333', fontSize: 12 } }}
      margin={{ left: 40, right: 40, top: 40, bottom: 40 }}
      enableGridY={false}
      tooltip={(datum: any) => <div style={{
        backgroundColor: theme === 'dark' ? '#434343' : '#f4f4f4',
        border: `1px solid ${theme === 'dark' ? '#888' : '#bbb'}`,
        boxShadow: `1px 1px 3px ${theme === 'dark' ? '#121212' : '#e1e1e1'}`,
        padding: 4
      }}>
        {`${translate(`nclusion.bankStatus.${datum.data[keyField]}`)}: ${datum.data[valueField]}`}
      </div>}
      {...(typeof config === 'function' ? config(results) : config)}
    />
  </Card> : <></>
}

export default BarChart
