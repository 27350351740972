import LotteryDashboard from '../../components/LotteryDashboard'

const ShowLottery = () => {
  const pathname = window.location.hash
  const id = pathname.split('/')[2]

  return id ? <LotteryDashboard lottery_id={Number(id)} /> : <p>not found</p>
}

export default ShowLottery
