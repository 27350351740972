import {
  BooleanField,
  ImageField,
  EmailField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useAuthProvider,
  useRecordContext,
  TopToolbar,
  EditButton,
  useTranslate,
} from 'react-admin'
import app from '../../feathersClient'
import type { User } from '@nclusion/feathers-client'
import { startCase, get, noop } from 'lodash'
import React from 'react'
import PhoneField from '../../components/PhoneField'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import ChartController from '../../components/charts/ChartController'
import RelatedAssociates from '../../components/charts/aggregations/RelatedAssociates'
import { getLocale } from '../../util'

const VerificationGroup = ({
  method,
  children,
  label,
}: {
  method: 'email' | 'phone'
  children: any
  label: string
}): any => {
  const user: User = useRecordContext()
  const authProvider: any = useAuthProvider()
  const [currentUser, setCurrentUser] = React.useState<User>(
    null as unknown as User
  )
  const [sendCodeOpen, setSendCodeOpen] = React.useState(false)
  const [sendError, setSendError] = React.useState('')
  const [enterCodeOpen, setEnterCodeOpen] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [codeError, setCodeError] = React.useState('')
  const translate = useTranslate()
  noop(label)

  React.useEffect(() => {
    const fn = async () => {
      setCurrentUser(await authProvider.getIdentity())
    }
    fn()
  }, [])

  return (
    currentUser && (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {children}
        {get(user, method) && (
          <>
            {get(
              user,
              `${method === 'email' ? 'verified' : 'verified_phone'}`
            ) ? (
              <BooleanField
                valueLabelTrue='verified'
                source='verified'
                sx={{ marginLeft: 2, color: 'green' }}
              />
            ) : (
              <div
                style={{
                  fontSize: 14,
                  marginLeft: 4,
                  marginRight: 4,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span>{startCase(method)} not verified.</span>
                {currentUser.id === user?.id ? (
                  <>
                    <Button
                      variant='text'
                      onClick={() => setSendCodeOpen(true)}
                    >
                      Send Code
                    </Button>
                    <Dialog open={sendCodeOpen}>
                      <DialogTitle>Send Confirmation Code</DialogTitle>
                      <DialogContent>
                        <Typography>
                          The code will be sent to your {method}.
                        </Typography>
                        {sendError && (
                          <Typography sx={{ color: 'red' }}>
                            {sendError}
                          </Typography>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setSendCodeOpen(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={async () => {
                            try {
                              await app
                                .service('verification')
                                .create({ [method]: get(user, method) })
                            } catch (e) {
                              if (!String(e).startsWith('NotFound')) {                            
                                setSendError(translate(`${e}`))
                              }
                            }
                            if (!sendError) {
                              setSendCodeOpen(false)
                            }
                          }}
                        >
                          Send
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Button
                      variant='text'
                      style={{ marginLeft: 4 }}
                      onClick={() => setEnterCodeOpen(true)}
                    >
                      Enter Code
                    </Button>
                    <Dialog open={enterCodeOpen}>
                      <DialogTitle>
                        Enter {startCase(method)} Confirmation Code
                      </DialogTitle>
                      <DialogContent sx={{ textAlign: 'center' }}>
                        <Input onChange={e => setCode(e.target.value)} />
                        {codeError && (
                          <Typography sx={{ color: 'red' }}>
                            {codeError}
                          </Typography>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setEnterCodeOpen(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={async () => {
                            try {
                              await app
                                .service('verification')
                                .patch(user[method], { code })
                            } catch (e) {
                              if (!String(e).startsWith('NotFound')) {
                                setCodeError(translate(`${e}`))
                              }
                            }
                            if (!codeError) {
                              setEnterCodeOpen(false)
                            }
                          }}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        )}
      </div>
    )
  )
}

const ShowActions = () => {
  const navigate = useNavigate()
  const user = useRecordContext()
  const authProvider: any = useAuthProvider()
  const [currentUser, setCurrentUser] = React.useState<User>(
    null as unknown as User
  )

  React.useEffect(() => {
    const fn = async () => {
      setCurrentUser(
        authProvider.getIdentity && ((await authProvider.getIdentity()) as User)
      )
    }
    fn()
  }, [])

  return (
    <TopToolbar>
      {user?.id === currentUser?.id && (
        <Button color='primary' onClick={() => navigate('/change-password')}>
          Change Password
        </Button>
      )}
      <EditButton />
    </TopToolbar>
  )
}

export const UserShow = () => {
  const authProvider = useAuthProvider()
  const { verified } = authProvider.getIdentitySync()

  return <Show actions={<ShowActions />}>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
      <SimpleShowLayout>
        <TextField source='firstName' />
        <TextField source='lastName' />
        <TextField source='id' />
        <TextField source='googleId' />
        <VerificationGroup method='email' label='Email'>
          <EmailField source='email' />
        </VerificationGroup>
        <VerificationGroup method='phone' label='Phone'>
          <PhoneField source='phone' />
        </VerificationGroup>
        <ImageField source='image_url' />
        {verified && <ReferenceField source='address_id' reference='address' />}
        <NumberField source='address.id' locales={getLocale()} />
      </SimpleShowLayout>
      <ChartController>
        {verified && <RelatedAssociates id='relatedAssociates' />}
      </ChartController>
    </div>
  </Show>
}
