import { Box } from "@mui/material"
import { NumberInput, useTranslate } from "react-admin"

export const NumberFilter = ({ source, alwaysOn, label }: { source: string, alwaysOn?: boolean, label?: string }) => {
  const translate = useTranslate()

  return <Box>
      <NumberInput label={label || translate(`nclusion.${source}.min`)} source={`${source}_gte`}  sx={{ mr: 1}} alwaysOn />
      <NumberInput label={label || translate(`nclusion.${source}.max`)} source={`${source}_lt`} alwaysOn />
  </Box>
}
