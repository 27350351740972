import io from 'socket.io-client'
import socketio from '@feathersjs/socketio-client'
import * as api from '@nclusion/feathers-client'
import rest from '@feathersjs/rest-client'
import { feathers } from '@feathersjs/feathers'

export const envMap: any = {
  'https://toussaint-web-application-onsc6kcz3a-uc.a.run.app': 'https://nclusion-api-service-onsc6kcz3a-uc.a.run.app',
  'https://qa-toussaint-web-onsc6kcz3a-wn.a.run.app': 'https://qa-api-onsc6kcz3a-wn.a.run.app',
  'https://demo-admin-onsc6kcz3a-uw.a.run.app': 'https://demo-api-onsc6kcz3a-uw.a.run.app',
  'https://staging-admin-onsc6kcz3a-ue.a.run.app': 'https://staging-api-onsc6kcz3a-ue.a.run.app',
  'https://toussaint.nclusion.com': 'https://prod-api-onsc6kcz3a-ue.a.run.app'
}

const connection = socketio(io(process.env.TOUSSAINT_API_URL || envMap[window.location.origin] || 'http://localhost:3030', {
  transports: ["websocket"]
}))

const client = api.createClient(connection as any)

const httpClient = rest(process.env.TOUSSAINT_API_URL || envMap[window.location.origin] || 'http://localhost:3030')
export const restClient = feathers().configure(httpClient.fetch(window.fetch.bind(window)))

export default client
