import Checkbox from '@mui/material/Checkbox'
import { useListContext, useTranslate } from "react-admin"
import { useEffect, useState } from 'react'
import { omit } from 'lodash'
import { FormControlLabel } from '@mui/material'

const UnassignedFilter = ({ alwaysOn, source, label }: { alwaysOn?: boolean, source: string, label: string }) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()
  const [on, setOn] = useState(false)
  const translate = useTranslate()

  useEffect(() => {
    if (on) {
      setFilters({ ...filterValues, $unassigned: true }, displayedFilters)
    } else {
      setFilters(omit(filterValues, '$unassigned'), displayedFilters)
    }
  }, [on])

  return <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox
    value={on}
    onChange={() => setOn(!on)}
  />} label={translate(`nclusion.unassigned`)} />
}

export default UnassignedFilter
