import { ResourceContextProvider } from 'react-admin'
import AssociateDashboard from '../../resources/associates/List'

const OperationsDashboard = () => (
  <ResourceContextProvider value='associate'>
    <AssociateDashboard />
  </ResourceContextProvider>
)

export default OperationsDashboard
