import { useRecordContext } from 'react-admin'

const char = '0'
const wchar = '#'
const color1 = 'green'
const color2 = '#9933ff'
const hilightMap = new Map()
hilightMap.set('^.{1}(.{2}).{4}$', <span>{char}<span style={{background:color1}}>{wchar}{wchar}</span>{char}{char}{char}{char}</span>)
hilightMap.set('^.{3}(.{2}).{2}$', <span>{char}{char}{char}<span style={{background:color1}}>{wchar}{wchar}</span>{char}{char}</span>)
hilightMap.set('^.{5}(.{2})$', <span>{char}{char}{char}{char}{char}<span style={{background:color1}}>{wchar}{wchar}</span></span>)
hilightMap.set('^(.{3}).{4}$', <span><span style={{background:color1}}>{wchar}{wchar}{wchar}</span>{char}{char}{char}{char}</span>)
hilightMap.set('^.{1}(?<order_1>.{2})(?<order_2>.{2}).{2}$', <span>{char}<span style={{background:color1}}>{wchar}{wchar}</span><span style={{background:color2}}>{wchar}{wchar}</span>{char}{char}</span>)
hilightMap.set('^.{1}(?<order_2>.{2})(?<order_1>.{2}).{2}$', <span>{char}<span style={{background:color1}}>{wchar}{wchar}</span><span style={{background:color2}}>{wchar}{wchar}</span>{char}{char}</span>)
hilightMap.set('^.{3}(?<order_1>.{2})(?<order_2>.{2})$', <span>{char}{char}{char}<span style={{background:color1}}>{wchar}{wchar}</span><span style={{background:color2}}>{wchar}{wchar}</span></span>)
hilightMap.set('^.{3}(?<order_2>.{2})(?<order_1>.{2})$', <span>{char}{char}{char}<span style={{background:color1}}>{wchar}{wchar}</span><span style={{background:color2}}>{wchar}{wchar}</span></span>)
hilightMap.set('^.{1}(?<order_1>.{2}).{2}(?<order_2>.{2})$', <span>{char}<span style={{background:color1}}>{wchar}{wchar}</span>{char}{char}<span style={{background:color2}}>{wchar}{wchar}</span></span>)
hilightMap.set('^.{1}(?<order_2>.{2}).{2}(?<order_1>.{2})$', <span>{char}<span style={{background:color1}}>{wchar}{wchar}</span>{char}{char}<span style={{background:color2}}>{wchar}{wchar}</span></span>)
hilightMap.set('^.{3}(.{4})$', <span>{char}{char}{char}<span style={{background:color1}}>{wchar}{wchar}{wchar}{wchar}</span></span>)
hilightMap.set('^.{1}(.{4}).{2}', <span>{char}<span style={{background:color1}}>{wchar}{wchar}{wchar}{wchar}</span>{char}{char}</span>)
hilightMap.set('^.{1}(.{2}).{2}(.{2})$', <span>{char}<span style={{background:color1}}>{wchar}{wchar}</span>{char}{char}<span style={{background:color2}}>{wchar}{wchar}</span></span>)
hilightMap.set('^(.{5}).{2}$', <span><span style={{background:color1}}>{wchar}{wchar}{wchar}{wchar}{wchar}</span>{char}{char}{char}</span>)
hilightMap.set('^(.{3}).{2}(.{2})$', <span><span style={{background:color1}}>{wchar}{wchar}{wchar}</span>{char}{char}<span style={{background:color2}}>{wchar}{wchar}</span></span>)
hilightMap.set('^.{2}(.{5})$', <span>{char}{char}{char}<span style={{background:color1}}>{wchar}{wchar}{wchar}{wchar}{wchar}</span></span>)

const positionMap = new Map()
positionMap.set('^.{1}(.{2}).{4}$', 'Position 1')
positionMap.set('^.{3}(.{2}).{2}$', 'Position 2')
positionMap.set('^.{5}(.{2})$', 'Position 3')
positionMap.set('^(.{3}).{4}$', '')
positionMap.set('^.{1}(?<order_1>.{2})(?<order_2>.{2}).{2}$', '')
positionMap.set('^.{1}(?<order_2>.{2})(?<order_1>.{2}).{2}$', '')
positionMap.set('^.{3}(?<order_1>.{2})(?<order_2>.{2})$', '')
positionMap.set('^.{3}(?<order_2>.{2})(?<order_1>.{2})$', '')
positionMap.set('^.{1}(?<order_1>.{2}).{2}(?<order_2>.{2})$', '')
positionMap.set('^.{1}(?<order_2>.{2}).{2}(?<order_1>.{2})$', '')
positionMap.set('^.{3}(.{4})$', '')
positionMap.set('^.{1}(.{4}).{2}', '')
positionMap.set('^.{1}(.{2}).{2}(.{2})$', '')
positionMap.set('^(.{5}).{2}$', '')
positionMap.set('^(.{3}).{2}(.{2})$', '')
positionMap.set('^.{2}(.{5})$', '')

export const RegexHilighter = () => {
  const record = useRecordContext()
  return hilightMap.get(record.match_regex)
}

export const RegexPosition = () => {
  const record = useRecordContext()
  return positionMap.get(record.match_regex) || ''
}