import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useTranslate,
  useRecordContext
} from 'react-admin'
import PhoneField from '../../components/PhoneField'
import {
  Button,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/material'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ChartController from '../../components/charts/ChartController'
import RelatedSimpleAggregate from '../../components/charts/aggregations/RelatedSimpleAggregate'
import BankRTP from '../../components/charts/aggregations/BankRTP'
import { useNavigate } from 'react-router-dom'
import { Bank } from '@nclusion/feathers-client'
import { getLocale } from '../../util'
import { ImageField } from '../../components/ImageField'

export default () => {
  const translate = useTranslate()
  const navigate = useNavigate()

  return (
    <Show>
      <Container sx={{ margin: 5 }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/bank')}
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{ marginBottom: 3 }}
        >
          {translate('nclusion.backToBanks')}
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card
              sx={{
                padding: 3,
                height: '100%',
                flexDirection: 'column',
                display: 'flex'
              }}
            >
              <List>
                <ListItem sx={{ justifyContent: 'space-between' }}>
                  <ImageField
                    source="fileUpload-profile_pic_id"
                    title="Bank Image"
                    showPreview={true}
                  />
                </ListItem>
                <ListItem>
                  <Typography>{translate('nclusion.status')}</Typography>
                  <TextField
                    source="status"
                    sx={{ margin: 1, fontWeight: 'bold' }}
                  />
                </ListItem>
              </List>
            </Card>
          </Grid>
          <ChartController>
            <>
              <Grid item xs={4}>
                <Card
                  sx={{
                    padding: 3,
                    height: '100%',
                    textAlign: 'center'
                  }}
                >
                  <RelatedSimpleAggregate
                    id="ticketSales"
                    service="ticket"
                    aggregationId="bankTicketCount"
                    aggs="count"
                    labels={{ count: translate('nclusion.ticketsSold') }}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  sx={{
                    padding: 3,
                    height: '100%',
                    flexDirection: 'column',
                    display: 'flex'
                  }}
                >
                  <List>
                    <ListItem sx={{ justifyContent: 'space-between' }}>
                      <BankRTP
                        id="rtp"
                        aggregationId="bankRTP"
                        service="transaction"
                      />
                    </ListItem>
                  </List>
                </Card>
              </Grid>
            </>
          </ChartController>
        </Grid>
        <Grid container rowSpacing={1} spacing={0} margin={10}>
          <Grid item xs={5}>
            <Typography
              gutterBottom
              component="div"
              style={{ fontWeight: 600 }}
            >
              {translate('nclusion.info')}
            </Typography>
            <SimpleShowLayout>
              <TextField source="name" />
              <TextField source="tenant.name" />
              <TextField source="subtenant.name" />
              <TextField source="building_type" />
              <FunctionField
                label="Address"
                render={(record: Bank) =>
                  `${record.address?.address1} ${
                    record.address?.address2 ? record.address.address2 : ''
                  }`
                }
              />
              <TextField source="address.city" />
              <TextField source="address.country" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={5}>
            <Typography
              gutterBottom
              component="div"
              style={{ fontWeight: 600 }}
            >
              {translate('nclusion.leaseInfo')}
            </Typography>
            <SimpleShowLayout>
              <NumberField source="lease_amount" locales={getLocale()} />
              <DateField source="lease_start_timestamp" />
              <DateField source="lease_end_timestamp" />
              <TextField source="contact_name" />
              <PhoneField source="contact_phone_number" />
              <PhoneField source="contact_email_address" />
              <DateField source="open_since" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
        <Stack sx={{ margin: 10 }}>
          <Typography gutterBottom component="div" style={{ fontWeight: 600 }}>
            {translate('nclusion.assignedAssociates')}
          </Typography>
          <ReferenceManyField target="bank_id" reference="bank-assignment">
            <Datagrid bulkActionButtons={false}>
              <TextField source="associate.alias" />
              <ReferenceField source="associate_id" reference="associate">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="associate_id"
                reference="associate"
                label="Associate Roles"
                link={false}
              >
                <ReferenceManyField
                  target="associate_id"
                  reference="associate-roles"
                >
                  <SingleFieldList linkType="show">
                    <ChipField source="role.name" />
                  </SingleFieldList>
                </ReferenceManyField>
              </ReferenceField>
              <PhoneField source="associate.user.phone" label="Phone Number" />
            </Datagrid>
          </ReferenceManyField>
        </Stack>
      </Container>
    </Show>
  )
}
