import React, { useCallback } from 'react'
import HistoricAllocationlList from './HistoricAllocationlList'
import LiveAllocationList from './LiveAllocationList'
import MaxAllocationList from './MaxAllocationList'
import RTPList from './RTPList'
import { Box, Tab, Tabs } from '@mui/material'
import { CustomTabPanel } from '../../components/CustomTabPanel'
import { useListController, useTranslate } from 'react-admin'

export default () => {
  const translate = useTranslate()
  const { setFilters, setSort } = useListController()

  const [tabValue, setTabValue] = React.useState(0)

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setFilters({}, {}, false)
      setSort({ field: 'id', order: 'DESC' })
      setTabValue(newValue)
    },
    [setFilters, setSort]
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label={translate('nclusion.live')} />
          <Tab label={translate('nclusion.historical')} />
          <Tab label={translate('nclusion.settings')} />
          <Tab label={translate('nclusion.rtp')} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <LiveAllocationList />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <HistoricAllocationlList />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <MaxAllocationList />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <RTPList />
      </CustomTabPanel>
    </Box>
  )
}
