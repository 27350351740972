import {
  Button,
  Datagrid,
  DateField,
  ExportButton,
  List,
  TextField,
  TopToolbar,
  useAuthProvider,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import app from '../../feathersClient'

const AcceptedField = ({ source }: { source: string }) => {
  const { id, accepted } = useRecordContext()
  const translate = useTranslate()

  return accepted ? (
    <span>{translate('nclusion.accepted')}</span>
  ) : (
    <Button
      onClick={async () => {
        await app.service('invitation').patch(id, { accepted: true })
        window.location.reload()
      }}
      label={translate('nclusion.acceptInvitation')}
      color='success'
    />
  )
}

const EmptyList = () => {
  const translate = useTranslate()

  return <p>{translate('nclusion.noInvitations')}</p>
}

const NewUserInvitations = () => {
  const authProvider = useAuthProvider()
  const user = authProvider.getIdentitySync()

  return (
    <List
      resource='invitation'
      empty={<EmptyList />}
      filter={{
        emailOrPhoneNumber: {
          $in: user ? [user?.email, user.phone].filter(val => val) : [],
        },
      }}
      actions={
        <TopToolbar>
          <ExportButton />
        </TopToolbar>
      }
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source='emailOrPhoneNumber' />
        <TextField source='businessUnit' />
        <TextField source='businessUnitName' />
        <TextField source='role.name' />
        <TextField source='status' />
        <DateField source='expiration' />
        <AcceptedField source='accepted' />
      </Datagrid>
    </List>
  )
}

export default NewUserInvitations
