import { useListContext, useTranslate } from "react-admin"
import { useEffect, useState } from 'react'
import { omit } from 'lodash'
import { FormControlLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

const OperationalStatusFilter = ({ alwaysOn, source, label }: { alwaysOn?: boolean, label?: string, source: string }) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()
  const [status, setStatus] = useState('all')
  const translate = useTranslate()

  useEffect(() => {
    if (status !== 'all') {
      setFilters({ ...filterValues, $operationalStatus: status }, displayedFilters)
    } else {
      setFilters(omit(filterValues, '$operationalStatus'), displayedFilters)
    }
  }, [status])

  return <Select
    sx={{ minWidth: 180 }}
    label={translate(`nclusion.operationalStatus`)}
    value={status}
    onChange={(e: SelectChangeEvent) => setStatus(e.target.value)}
  >
    <MenuItem value={'all'}>{translate('nclusion.all')}</MenuItem>
    <MenuItem value={'inactive'}>{translate('nclusion.inactive')}</MenuItem>
    <MenuItem value={'open'}>{translate('nclusion.open')}</MenuItem>
    <MenuItem value={'operationaal'}>{translate('nclusion.operational')}</MenuItem>
  </Select>
}

export default OperationalStatusFilter

