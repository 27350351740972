import ChartContext from '../ChartContext'
import { useContext, useEffect, useState } from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { BankRTP, ClientApplication } from '@nclusion/feathers-client'

export interface BankRTPProps extends Omit<BankRTP, 'service'> {
  height?: number
  labels?: any
  service: Parameters<ClientApplication['service']>[0]
}

const BankRTPComponent = ({ id }: BankRTPProps) => {
  const { search: { registerAggregation, onResults } } = useContext(ChartContext)
  const translate = useTranslate()
  const [results, setResults] = useState<{ type: 'purchase' | 'refund' | 'payout', sum: number }[]>([])

  onResults(id, ({ results }: any) => setResults(results))

  const { id: recordId } = useRecordContext() || {}

  useEffect(() => {
    if (recordId) {
      registerAggregation({
        id,
        aggregationId: 'bankRTP',
        service: 'transaction',
        ...(recordId ? { recordId } : {})
      } as any)
    }
  }, [recordId])

  const inflow = results.find(({ type }) => type === 'purchase')?.sum || 0
  const refund = results.find(({ type }) => type === 'refund')?.sum || 0
  const outflow = results.find(({ type }) => type === 'payout')?.sum || 0

  return <div>
    <div>{translate('nclusion.revenueToPayout')}: {inflow - refund} / {outflow}</div>
    <div>{translate('nclusion.net')}: {(inflow - outflow - refund).toFixed(0)}</div>
    <div>{translate('nclusion.rtp')}: {outflow && inflow && refund ? (outflow / (inflow - refund)).toFixed(3): 0}</div>
  </div>
}

export default BankRTPComponent
