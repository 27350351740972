import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin'
import MinPercentageInput from '../../components/MinPercentageInput'
import { getLocale, schemaFilters } from '../../util'
import { NclusionList } from '../../components/NclusionList'

export default () => {
  const translate = useTranslate()
  const locale = getLocale()

  const additionalFilters = [
    <ReferenceInput source="drawing_id" reference="drawing">
      <AutocompleteInput
        source="drawing_id"
        optionText={(record) => translate(record.name)}
        inputText={(choice) => translate(choice.name)}
      />
    </ReferenceInput>,
    <ReferenceInput source="game_id" reference="game">
      <AutocompleteInput
        source="game_id"
        optionText={(record) => `${translate(record.name)}`}
        inputText={(choice) => translate(choice.name)}
      />
    </ReferenceInput>,
    <MinPercentageInput
      source={'allocation'}
      maxField="max_allocation"
      label="Allocation %"
    />
  ]

  const listFilters = () =>
    schemaFilters('allocation', additionalFilters, [
      'allocation',
      'drawing_id',
      'game_id',
      'session_id'
    ])

  const ListActions = (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  )

  return (
    <NclusionList
      resource="allocation"
      filters={listFilters()}
      actions={ListActions}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <FunctionField
          label={translate('nclusion.drawing')}
          render={(record: { drawing: { name: string } }) =>
            translate(record.drawing.name)
          }
        />
        <FunctionField
          render={(record: { game: { name: string } }) =>
            translate(record.game.name)
          }
          label={translate('nclusion.game')}
        />
        <DateField
          source="session.close_timestamp"
          label={translate('nclusion.session')}
          sortable={false}
        />
        <TextField source="pick" label="Pick" />
        <NumberField
          source="allocation"
          label="Allocation"
          sortable={false}
          locales={locale}
        />
        <NumberField
          source="max_allocation"
          label={translate('nclusion.maxAllocation')}
          // sortable={false}
          locales={locale}
        />
        <FunctionField
          label="Usage %"
          render={(record: any) =>
            record.allocation && record.max_allocation
              ? `${((record.allocation / record.max_allocation) * 100).toFixed(
                  2
                )} %`
              : 0
          }
        />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </NclusionList>
  )
}
