import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { values } from 'lodash'
import { useNavigate } from 'react-router-dom'
import Copyright from './components/Copyright'
import { useTranslate } from 'react-admin'
import { restClient } from './feathersClient'
import { pwTest } from './CustomSignUpPage'
import PasswordRequirements from './components/PasswordRequirements'

interface Errors {
  username?: string
  code?: string
  password?: string
  confirm_password?: string
}

export default function Reset() {
  const [username, setUsername] = useState(sessionStorage.getItem('username') || '')
  const [code, setCode] = useState(sessionStorage.getItem('code_phone') || sessionStorage.getItem('code'))
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState<Errors>({})
  const navigate = useNavigate()
  const translate = useTranslate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updatedErrors: Errors = {}
  
    if (password !== confirm_password) {
      updatedErrors.password = translate('Auth.PasswordsMustMatch')
      setErrors(updatedErrors)
      return
    }

    if (!pwTest(password)) {
      updatedErrors.password = translate('error.passwordRequirements')
      setErrors(updatedErrors)
      return
    }

    try {
      await restClient.service('reset').patch(username, { code, password })
    } catch (e: any) {
      updatedErrors.username = translate(e.message)
    }

    setErrors(updatedErrors)
    if (values(updatedErrors).length) {
      return
    }

    sessionStorage.removeItem('resetPassword')
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('code')
    sessionStorage.removeItem('code_phone')

    navigate('/login?reset-success')
  }

  const errorStyle = { color: 'red' }

  return (
      <Container key={1} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 160, height: 160 }}>
            <img style={{ width: '100%' }} src="/nclusion-ring-logo.png" />
          </div>
          <Typography component="h1" variant="h5">
            {translate('nclusion.enter_reset_code')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography component="p" sx={{ textAlign: 'center', mb: 3 }}>
              {translate('nclusion.reset_instructions')}.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="username"
                  required
                  fullWidth
                  id="username"
                  label={translate('nclusion.email_or_phone')}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="code"
                  name="code"
                  required
                  fullWidth
                  id="code"
                  label={translate('nclusion.code')}
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={translate('nclusion.password')}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  error={!!errors.password}
                  {...(errors.password ? { helperText: errors.password } : {})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm_password"
                  label={translate('nclusion.confirm_password')}
                  type="password"
                  id="confirm_password"
                  autoComplete="confirm-new-password"
                  value={confirm_password}
                  onChange={e => setConfirmPassword(e.target.value)}
                  error={!!errors.confirm_password}
                  {...(errors.confirm_password ? { helperText: errors.confirm_password } : {})}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: 8 }}>
              <PasswordRequirements />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {translate('nclusion.reset')}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#/login?resend-confirmation" variant="body2" sx={{ mx: 4}}>
                {translate('nclusion.resend_code')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#/login" variant="body2">
                {translate('nclusion.account_already')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
  );
}
