import {
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
  TopToolbar,
  useTranslate
} from 'react-admin'
import { NclusionList } from '../../components/NclusionList'
import { getLocale } from '../../util'
import { DateFilter } from '../../components/DateFilter'

export default () => {
  const translate = useTranslate()

  const filters = [
    <DateFilter source="createdAt" />,
    <DateFilter source="updatedAt" />
  ]

  const ListActions = (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  )

  return (
    <NclusionList
      resource="max-allocation"
      filters={filters}
      actions={ListActions}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <FunctionField
          label={translate('nclusion.drawing')}
          render={(record: { name: string }) => translate(record.name)}
        />
        <TextField
          label={translate('nclusion.lottery')}
          source="lottery.name"
        />
        <NumberField
          source="max_allocation"
          locales={getLocale()}
          label={translate('nclusion.maxAllocation')}
        />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </NclusionList>
  )
}
