import React, { useEffect } from 'react'
import {
  AutocompleteInput,
  Datagrid,
  DatagridHeaderProps,
  DateTimeInput,
  FieldProps,
  FunctionField,
  Identifier,
  Link,
  List,
  ListControllerResult,
  NumberField,
  RaRecord,
  ReferenceInput,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  WithListContext,
  defaultLightTheme,
  useListContext,
  useListController,
  useRecordContext,
  useRefresh,
  useTheme,
  useTranslate
} from 'react-admin'
import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { startOfDay, endOfDay } from 'date-fns'
import app from '../../feathersClient'
import { formatNumbersOrSales } from '../../util'
import { getNetPurchase, getRemainingPayout, getTotalCash } from './hooks'

const fieldStyle: React.CSSProperties = { minWidth: '80px', textAlign: 'right' }

type filtersType = {
  role_id: number
  associate_id?: { $in: number[] }
}

const CurrencyField = ({
  source,
  label
}: {
  source: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record[source] || 0, record.currency)}
    </div>
  )
}

const NetPurchaseField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return <div style={fieldStyle}>{getNetPurchase(record)}</div>
}

const CommissionField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {`${(Number(record.commission) * 100).toFixed(0) || 0} %`}
    </div>
  )
}

const AgentCommissionField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record.agent_commission, record.currency)}
    </div>
  )
}

const RemainingPayoutField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return <div style={fieldStyle}>{getRemainingPayout(record)}</div>
}

const TotalWinningsField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record.payout || 0, record.currency)}
    </div>
  )
}

const TotalCashField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()
  const totalcash = getTotalCash(record)
  const [theme] = useTheme()

  return (
    <div
      style={{
        ...fieldStyle,
        color:
          Number(totalcash) >= 0
            ? theme === 'dark'
              ? '#36F4A4'
              : '#09AC71'
            : '#F10E60'
      }}
    >
      {formatNumbersOrSales(totalcash, record?.currency)}
    </div>
  )
}

const AgentField = ({ source, label }: { source?: string; label?: string }) => {
  const record = useRecordContext()
  const translate = useTranslate()
  const { perPage, sort } = useListContext()

  if (record['id'] === null) {
    return <div>{''}</div>
  } else {
    return (
      <FunctionField
        source="id"
        render={(record: { name: any; id: any }) => (
          <Link to={`/associate/${record.id}/show`}>
            {`${record.name} #${record.id}`}
          </Link>
        )}
        emptyText={`${translate('nclusion.deactivatedAssociate')} #${
          record.id
        }`}
        label={translate('nclusion.Agent')}
        queryoptions={{ meta: { $limit: perPage + 1, sort: sort } }}
      />
    )
  }
}

const CashControllerField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()
  const translate = useTranslate()

  if (record['id'] === null) {
    return (
      <div style={{ fontWeight: 'bold' }}>{translate('nclusion.total')}</div>
    )
  } else {
    return (
      <ReferenceManyField
        source="id"
        target="associate_id"
        reference="relationship-tree"
        filter={{ relationship_id: 2 }}
      >
        <SingleFieldList linkType={false}>
          <FunctionField
            render={(record: {
              relative: { id: number; name: string }
              name: any
              id: any
            }) => (
              <Link to={`/associate/${record.relative.id}/show`}>
                {`${record.relative.name} #${record.relative.id}`}
              </Link>
            )}
          />
        </SingleFieldList>
      </ReferenceManyField>
    )
  }
}

export default ({ fullReport }: { fullReport?: boolean }) => {
  const translate = useTranslate()
  const [theme] = useTheme()
  const { setFilters, filterValues, data } = useListController()
  const refresh = useRefresh()

  useEffect(() => {
    if (!filterValues.start && !filterValues.end) {
      setFilters(
        { start: startOfDay(new Date()), end: endOfDay(new Date()) },
        {}
      )
    }
  }, [])

  useEffect(() => {
    app.service('transaction').on('patched', refresh)
    app.service('session').on('patched', refresh)

    return () => {
      app.service('transaction').removeListener('patched', refresh)
      app.service('session').removeListener('patched', refresh)
    }
  })

  const evenColumnBackground = {
    backgroundColor: theme === 'dark' ? '#313131' : '#f4f4f4'
  }
  const oddColumnBackground = {
    backgroundColor:
      theme === 'dark'
        ? '#212121'
        : defaultLightTheme.palette?.background?.default
  }

  const headerMapping: {
    [key: string]: string[]
  } = {
    info: ['agent', 'bank', 'cashController', 'subtenant'],
    commissions: [
      'purchase',
      'refund',
      'grossSales',
      'commission',
      'agentCommission'
    ],
    redemption: ['payout', 'redemption', 'remaining'],
    netSales: [
      'operational_days',
      'totalGrossSales',
      'totalAgentCommission',
      'totalPayout',
      'totalBalance'
    ]
  }

  const columnStyle = (headerArray: string[]) => {
    let style: {
      [key: string]: { backgroundColor?: string }
    } = {}
    headerArray.forEach((source: string) => {
      style[`& .column-${source}`] = evenColumnBackground
      style[`& .RaDatagrid-row:hover .column-${source}`] = {
        backgroundColor: theme === 'dark' ? '#404040' : '#eeeeee'
      }
    })

    return style
  }

  const AgentFilter = ({ alwaysOn }: { alwaysOn?: boolean }) => {
    const { filterValues } = useListContext()

    const filters: filtersType = { role_id: 1 }
    if (filterValues?.children) {
      filters['associate_id'] = { $in: filterValues.children }
    }
    return (
      <ReferenceInput
        source="associate_id"
        reference="associate-roles"
        alwaysOn={alwaysOn}
        filter={filters}
      >
        <AutocompleteInput
          label={translate('nclusion.Agent')}
          optionText={(option) =>
            `${option.associate?.name} #${option.associate_id}`
          }
          optionValue="associate.id"
          sx={{ minWidth: 248 }}
          getOptionKey={(option) => option.id}
        />
      </ReferenceInput>
    )
  }

  const fillInTotalRow = (header: string, record: RaRecord<Identifier>) => {
    switch (header) {
      case 'agent':
        return translate('nclusion.total')
      case 'bank':
      case 'cashController':
      case 'subtenant':
        return ''
      case 'grossSales':
      case 'totalGrossSales':
        return getNetPurchase(record)
      case 'commission':
        return translate('nclusion.varies')
      case 'remaining':
        return getRemainingPayout(record)
      case 'operational_days':
        return record.operational_days || 0
      case 'agentCommission':
      case 'totalAgentCommission':
        return formatNumbersOrSales(record.agent_commission, record.currency)
      case 'totalPayout':
        return formatNumbersOrSales(record.payout, record.currency)
      case 'totalBalance':
        return formatNumbersOrSales(getTotalCash(record), record.currency)
      default:
        return formatNumbersOrSales(record[header], record.currency)
    }
  }

  const TotalRow = ({ data }: ListControllerResult<RaRecord<Identifier>>) =>
    data && data[0].id === null ? (
      <TableRow sx={{ backgroundColor: '#73999C' }}>
        {(fullReport
          ? Object.keys(headerMapping)
          : Object.keys(headerMapping).filter(
              (f) => f === 'netSales' || f === 'info'
            )
        ).map((key) =>
          headerMapping[key].map((k: string) => (
            <TableCell
              style={
                k === 'agent'
                  ? { fontWeight: 600 }
                  : { ...fieldStyle, fontWeight: 600 }
              }
              key={`${key}-${k}`}
            >
              {fillInTotalRow(k, data[0])}
            </TableCell>
          ))
        )}
      </TableRow>
    ) : null

  const DatagridHeader = ({ children }: DatagridHeaderProps) => (
    <TableHead
      style={{
        position: 'sticky',
        top: 0
      }}
    >
      <TableRow>
        {Object.keys(headerMapping)
          .filter((f) => (fullReport ? null : f === 'netSales' || f === 'info'))
          .map((headerLabel) =>
            headerMapping[headerLabel].map((header: string, index: number) => (
              <TableCell
                key={header}
                sx={
                  headerLabel === 'commissions' || headerLabel === 'netSales'
                    ? evenColumnBackground
                    : oddColumnBackground
                }
              >
                {index === 0 ? translate(`nclusion.${headerLabel}`) : null}
              </TableCell>
            ))
          )}
      </TableRow>
      <TableRow>
        {React.Children.map(children, (child) =>
          React.isValidElement<FieldProps>(child) ? (
            <TableCell
              sx={{
                backgroundColor:
                  headerMapping['commissions'].includes(child.props.source!) ||
                  headerMapping['netSales'].includes(child.props.source!)
                    ? evenColumnBackground
                    : oddColumnBackground
              }}
              key={`${child?.props?.label}`}
            >
              {child.props.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
      <WithListContext render={TotalRow} />
    </TableHead>
  )

  const Filters = [
    <ReferenceInput
      source="subtenant_id"
      reference="sub-tenant"
      label={translate('nclusion.subtenant')}
      key="sub-tenant-filter"
    />,
    <ReferenceInput
      reference="relationship-relata"
      alwaysOn
      filter={{
        relationship: 'nclusion.controllerRelationship',
        prop: 'relative_id'
      }}
      source="children"
    >
      <AutocompleteInput
        label={translate('nclusion.cashcontroller')}
        resource="relationship-relata"
        source="relative_id"
        optionText={(option) => `${option.name} #${option.id}`}
        optionValue="children"
        filterToQuery={(searchText) => ({
          name: { $ilike: `%${searchText}%`}
        })}
        sx={{ minWidth: 248 }}
        getOptionKey={(option) => option.id}
      />
    </ReferenceInput>,
    <AgentFilter alwaysOn />,
    <DateTimeInput source="start" alwaysOn />,
    <DateTimeInput source="end" alwaysOn />
  ]

  return (
    <List filters={Filters}>
      <Datagrid
        header={<DatagridHeader />}
        isRowSelectable={(record) => record.id !== null}
        bulkActionButtons={false}
        rowSx={(record) => ({ display: record.id === null ? 'none' : '' })}
        sx={{
          ...columnStyle(headerMapping['commissions']),
          ...columnStyle(headerMapping['netSales']),
          '& .column-agent': { minWidth: 180 },
          '& .column-supervisor': { minWidth: 180 },
          '& .RaDatagrid-table': { borderCollapse: 'unset' }
        }}
      >
        <CashControllerField
          source="cash-controller"
          label={translate('nclusion.cashcontroller')}
        />
        <AgentField source="agent" label={translate('nclusion.Agent')} />
        <ReferenceManyField
          reference="bank-assignment"
          target="associate_id"
          label={translate('AgentsScreen.AssignedBank')}
        >
          <SingleFieldList linkType="show">
            <TextField source="bank.name" />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          reference="bank-assignment"
          target="associate_id"
          label={translate('nclusion.subtenant')}
        >
          <SingleFieldList linkType="show">
            <TextField source="bank.subtenant.name" />
          </SingleFieldList>
        </ReferenceManyField>
        {fullReport && (
          <CurrencyField
            source="purchase"
            label={`${translate('nclusion.sales')} (${data?.[0]?.currency})`}
          />
        )}
        {fullReport && (
          <CurrencyField
            source="refund"
            label={`${translate('nclusion.refunded')} (${data?.[0]?.currency})`}
          />
        )}
        {fullReport && (
          <NetPurchaseField
            source="grossSales"
            label={`${translate('nclusion.grossSales')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <CommissionField
            source="commission"
            label={`${translate('nclusion.commission')} (%)`}
          />
        )}
        {fullReport && (
          <AgentCommissionField
            source="agentCommission"
            label={`${translate('nclusion.agentCommission')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <CurrencyField
            source="payout"
            label={`${translate('nclusion.totalPayout')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <CurrencyField
            source="redemption"
            label={`${translate('nclusion.redemption')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <RemainingPayoutField
            source="remainingPayout"
            label={`${translate('nclusion.remainingPayout')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        <NumberField source="operational_days" label={translate('nclusion.operationalDays')} />
        <NetPurchaseField
          source="totalGrossSales"
          label={`${translate('nclusion.grossSales')} (${data?.[0]?.currency})`}
        />
        <AgentCommissionField
          source="totalAgentCommission"
          label={`${translate('nclusion.agentCommission')} (${
            data?.[0]?.currency
          })`}
        />
        <TotalWinningsField
          source="totalPayout"
          label={`${translate('nclusion.totaPayout')} (${data?.[0]?.currency})`}
        />
        <TotalCashField
          source="totalBalance"
          label={`${translate('nclusion.balance')} (${data?.[0]?.currency})`}
        />
      </Datagrid>
    </List>
  )
}
