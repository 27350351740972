import { useTranslate } from 'react-admin'

const PasswordRequirements = () => {
  const translate = useTranslate()

  return <div>
    <div style={{ fontSize: 14 }}>{translate('Auth.PasswordRequirementsHeader')}</div>
    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
      <div style={{ fontSize: 14 }}>• {translate('Auth.PasswordRequirementsSixCharacters')}</div>
      <div style={{ fontSize: 14 }}>• {translate('Auth.PasswordRequirementsOneLetter')}</div>
      <div style={{ fontSize: 14 }}>• {translate('Auth.PasswordRequirementsOneNumber')}</div>
      <div style={{ fontSize: 14 }}>• {translate('Auth.PasswordRequirementsOneSpecial')}</div>
    </div>
  </div>
}

export default PasswordRequirements
