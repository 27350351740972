import {BooleanField, EmailField, ReferenceField, ImageField, TextField, Datagrid } from 'react-admin'
import { schemaFilters } from '../../util'
import { NclusionList } from '../../components/NclusionList'

const listFilters = () => schemaFilters('user')

export default () => <NclusionList filters={listFilters()}>
  <Datagrid rowClick='show'>
    <ImageField label='' sx={{ width: 20, height: 20, '& .RaImageField-image': { width: '100%', height: 'auto', marginTop: 0 }}} source='image_url' />
    <TextField source='firstName' />
    <TextField source='lastName' />
    <TextField source='id' />
    <EmailField source='email' />
    <BooleanField source='verified' label='Verified email' />
    <TextField source='phone' />
    <BooleanField source='verified_phone' />
    <TextField source='googleId' />
    <ReferenceField source='address_id' reference='address' />
  </Datagrid>
</NclusionList>
