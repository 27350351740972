import {
  FilterButton,
  AutocompleteInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  Datagrid,
  DateField,
  Link,
  NumberField,
  ReferenceInput,
  TextField,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { schemaFilters } from '../../util'
import { NclusionList } from '../../components/NclusionList'
import ListContextAwareTotalsBar from '../../components/charts/aggregations/ListContextAwareTotalsBar'
import SimpleAggregate from '../../components/charts/aggregations/SimpleAggregate'
import { useNavigate } from 'react-router-dom'
import { partition } from 'lodash'
import AgentFilter from './AgentFilter'

const listFilters = () => {
  const translate = useTranslate()
  return schemaFilters(
    'ticket-denormalized',
    [
      <ReferenceInput
        source="mfi_id"
        reference="mfi"
        label={translate('nclusion.mfi')}
      >
        <AutocompleteInput label={translate('nclusion.mfi')} />
      </ReferenceInput>,
      <ReferenceInput
        source="merchant_id"
        reference="merchant"
        label={translate('nclusion.merchant')}
      >
        <AutocompleteInput label={translate('nclusion.merchant')} />
      </ReferenceInput>,
      <ReferenceInput
        source="lottery_id"
        reference="lottery"
        label={translate('nclusion.lottery')}
      >
        <AutocompleteInput label={translate('nclusion.lottery')} />
      </ReferenceInput>,
      <ReferenceInput
        source="tenant_id"
        reference="tenant"
        label={translate('nclusion.tenant')}
      >
        <AutocompleteInput label={translate('nclusion.tenant')} />
      </ReferenceInput>,
      <ReferenceInput
        source="subtenant_id"
        reference="sub-tenant"
        label={translate('nclusion.subtenant')}
      >
        <AutocompleteInput label={translate('nclusion.subtenant')} />
      </ReferenceInput>,
      <ReferenceInput
        source="transaction/bank/id"
        reference="bank"
        label={translate('nclusion.bank')}
      >
        <AutocompleteInput label={translate('nclusion.bank')} />
      </ReferenceInput>,
      <ReferenceInput
        source="wagers/session/game_drawing/game/id"
        reference="game"
        label={translate('nclusion.game')}
      >
        <AutocompleteInput label={translate('nclusion.game')} />
      </ReferenceInput>,
      <ReferenceInput
        source="wagers/session/game_drawing/drawing/id"
        reference="drawing"
        label={translate('nclusion.drawing')}
      >
        <AutocompleteInput label={translate('nclusion.drawing')} />
      </ReferenceInput>,
      <ReferenceInput
        source="refund/bank/id"
        reference="bank"
        label={translate('nclusion.refundBank')}
      >
        <AutocompleteInput label={translate('nclusion.refundBank')} />
      </ReferenceInput>,
      <ReferenceInput
        source="refund/associate/id"
        reference="bank"
        label={translate('nclusion.refundAgent')}
      >
        <AutocompleteInput label={translate('nclusion.refundAgent')} />
      </ReferenceInput>,
      <ReferenceInput
        source="redemptions/bank/id"
        reference="bank"
        label={translate('nclusion.redemptionBank')}
      >
        <AutocompleteInput label={translate('nclusion.redemptionBank')} />
      </ReferenceInput>,
      <ReferenceInput
        source="redemptions/associate/id"
        reference="associate"
        label={translate('nclusion.redemptionAgent')}
      >
        <AutocompleteInput label={translate('nclusion.redemptionAgent')} />
      </ReferenceInput>,
      <ReferenceInput
        source="relations/relative_id"
        filterToQuery={(value: any) =>
          value && {
            value,
            filter: {
              path: 'relations.relationship.name',
              operator: '=',
              value: 'legacy.supervisor'
            }
          }
        }
        reference="associate"
        label={translate('nclusion.supervisor')}
      >
        <AutocompleteInput label={translate('nclusion.supervisor')} />
      </ReferenceInput>,
      <AgentFilter
        source="transaction/associate/id"
        label={translate('nclusion.agent')}
      />
    ],
    [
      'supervisor',
      'transaction',
      'wagers',
      'redemptions',
      'refund',
      'lottery',
      'lottery_id',
      'merchant_id',
      'mfi_id',
      'tenant_id',
      'subtenant_id',
      'id',
      'ticket_id',
      'relations'
    ]
  )
}

const Actions = () => {
  const translate = useTranslate()
  const navigate = useNavigate()

  return (
    <TopToolbar>
      <Button
        onClick={() => navigate('/ticket')}
        label={translate('nclusion.allTickets')}
      />
      <FilterButton />
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  )
}

const TicketField = ({ label }: { label: string }) => {
  const { uuid, name } = useRecordContext() || {}
  return (
    <Link to={`/ticket/${uuid}/show`} onClick={(e: any) => e.stopPropagation()}>
      {name}
    </Link>
  )
}

const AgentField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction?.associate || {}

  return (
    <Link
      to={`/associate/${id}/show`}
      onClick={(e: any) => e.stopPropagation()}
    >
      {name}
    </Link>
  )
}

const fromRelationLink = ({
  relative: { id, name },
  relationship: { name: relationshipName }
}: any) => {
  const translate = useTranslate()

  return (
    <p key={id}>
      {translate(`nclusion.relationship.${relationshipName}`)}:{' '}
      <Link
        to={`/associate/${id}/show`}
        onClick={(e: any) => e.stopPropagation()}
      >
        {name}
      </Link>
    </p>
  )
}

const toRelationLink = ({
  associate: { id, name },
  relationship: { name: relationshipName }
}: any) => {
  const translate = useTranslate()

  return (
    <p key={id}>
      {translate(`nclusion.relationship.${relationshipName}`)} (inverse):{' '}
      <Link
        to={`/associate/${id}/show`}
        onClick={(e: any) => e.stopPropagation()}
      >
        {name}
      </Link>
    </p>
  )
}

const RelationsField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const relations = record.relations || []
  if (!Array.isArray(relations)) {
    return <></>
  }

  const [fromRelations, toRelations] = partition(
    relations,
    (r) => r.associate.id === record.transaction.associate.id
  )

  // from relations need to be mapped to the relation name and the relative
  // to relations need to be mapped to a label that exresses the inverse of the relation plus the associate name

  return (
    <>
      {fromRelations.map(fromRelationLink)}
      {toRelations.map(toRelationLink)}
    </>
  )
}

const LotteryField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.lottery || {}
  return id ? (
    <Link to={`/lottery/${id}/show`} onClick={(e: any) => e.stopPropagation()}>
      {name}
    </Link>
  ) : (
    <></>
  )
}

const TransactionField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction || {}
  return id ? (
    <Link
      to={`/transaction/${id}/show`}
      onClick={(e: any) => e.stopPropagation()}
    >
      {name}
    </Link>
  ) : (
    <></>
  )
}

const MfiField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction?.mfi || {}
  return id ? (
    <Link to={`/mfi/${id}/show`} onClick={(e: any) => e.stopPropagation()}>
      {name}
    </Link>
  ) : (
    <></>
  )
}

const MerchantField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction?.merchant || {}
  return id ? (
    <Link to={`/merchant/${id}/show`} onClick={(e: any) => e.stopPropagation()}>
      {name}
    </Link>
  ) : (
    <></>
  )
}

const TenantField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction?.tenant || {}
  return id ? (
    <Link to={`/tenant/${id}/show`} onClick={(e: any) => e.stopPropagation()}>
      {name}
    </Link>
  ) : (
    <></>
  )
}

const SubtenantField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction?.subtenant || {}
  return id ? (
    <Link
      to={`/sub-tenant/${id}/show`}
      onClick={(e: any) => e.stopPropagation()}
    >
      {name}
    </Link>
  ) : (
    <></>
  )
}

const WagersField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { wagers } = record || {}
  return wagers ? <span>{wagers.length}</span> : <></>
}

const BankField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, name } = record.transaction?.bank || {}
  return id ? (
    <Link to={`/bank/${id}/show`} onClick={(e: any) => e.stopPropagation()}>
      {name}
    </Link>
  ) : (
    <></>
  )
}

const RefundField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const { id, bank } = record.refund || { bank: {} }
  return id ? (
    <Link
      to={`/transaction/${id}/show`}
      onClick={(e: any) => e.stopPropagation()}
    >
      {bank?.name || ''}
    </Link>
  ) : (
    <></>
  )
}

const RedemptionsField = ({ label }: { label: string }) => {
  const record = useRecordContext()
  const count = record.redemptions?.length
  if (!count) {
    return <></>
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {record.redemptions.map(({ id, bank }: { id: number; bank: any }) => (
        <Link
          to={`/transaction/${id}/show`}
          onClick={(e: any) => e.stopPropagation()}
        >
          {bank?.name}
        </Link>
      ))}
    </div>
  )
}

// we should add an "in progress" tab that shows the regular ticket collection and only incomplete tickets
const TicketDenormalizedList = () => {
  const translate = useTranslate()
  return (<>
    <NclusionList
      title={translate('nclusion.purchasedTickets')}
      resource="ticket-denormalized"
      filters={listFilters()}
      actions={<Actions />}
      filterDefaultValues={{ currency: 'HTG' }}
      listen={['refresh-publication', 'session']}
    >
      <ListContextAwareTotalsBar listen={['refresh-publication', 'session']}>
        <div
          style={{
            display: 'grid',
            width: '420px',
            gap: 20,
            gridTemplateColumns: '140px 140px 140px',
            padding: '4px 12px 12px'
          }}
        >
          <SimpleAggregate
            id="totalWager"
            aggregationId={'simpleAggregate'}
            service={'ticket-denormalized' as any}
            aggs={['sum']}
            valueField="total_wager"
            isCurrency={true}
            labels={{
              sum: translate('nclusion.totalWager')
            }}
          />
          <SimpleAggregate
            id="totalPayout"
            aggregationId={'simpleAggregate'}
            service={'ticket-denormalized' as any}
            aggs={['sum']}
            valueField="total_paid"
            isCurrency={true}
            labels={{
              sum: translate('nclusion.totalPayout')
            }}
          />
          <SimpleAggregate
            id="totalRedeemed"
            aggregationId={'simpleAggregate'}
            service={'ticket-denormalized' as any}
            aggs={['sum']}
            valueField="total_redemption"
            isCurrency={true}
            labels={{
              sum: translate('nclusion.totalRedeemed')
            }}
          />
        </div>
      </ListContextAwareTotalsBar>
      <Datagrid
        rowClick={(id, resource, { uuid }) => `/ticket-denormalized/${uuid}/show`}
        bulkActionButtons={false}
      >
        <TicketField label={translate('nclusion.ticket')} />
        <AgentField label={translate('nclusion.agent')} />
        <RelationsField label={translate('nclusion.relations')} />
        <WagersField label={translate('nclusion.wagers')} />
        <NumberField source="total_wager" />
        <NumberField source="total_paid" />
        <NumberField source="total_redemption" />
        <TransactionField label={translate('nclusion.purchase')} />
        <MfiField label={translate('nclusion.mfi')} />
        <MerchantField label={translate('nclusion.merchant')} />
        <LotteryField label={translate('nclusion.lottery')} />
        <TenantField label={translate('nclusion.tenant')} />
        <SubtenantField label={translate('nclusion.subTenant')} />
        <BankField label={translate('nclusion.bank')} />
        <RefundField label={translate('nclusion.refund')} />
        <RedemptionsField label={translate('nclusion.redemption')} />
        <TextField source="status" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
        <DateField source="expiresAt" showTime={true} />
      </Datagrid>
    </NclusionList>
  </>)
}

export default TicketDenormalizedList
