import { useEffect, useState } from "react"
import LotteryDashboard from "../../components/LotteryDashboard"
import { Select, MenuItem, SelectChangeEvent } from "@mui/material"
import { Lottery } from "@nclusion/feathers-client"
import app from "../../feathersClient"
import { useTranslate } from "react-admin"

const allLotteriesOption = (translate: Function) => ({ id: 0, name: translate('nclusion.allLotteries')} as Lottery)

export default () => {
  const translate = useTranslate()
  const [lotteryId, setLotteryId] = useState<number>(0)
  const [lotteries, setLotteries]  = useState<Lottery[]>([allLotteriesOption(translate)])

  useEffect(() => {
    const fn = async () => {
      const { data } = await app.service('lottery').find({ query: { $limit: 1000 } }) || {}
      setLotteries([
        allLotteriesOption(translate),
        ...data
      ])
    }
    fn()
  }, [])

  return <>
    <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '1rem 0' }}>
      <Select
        value={`${lotteryId}`}
        onChange={(e: SelectChangeEvent) => setLotteryId(Number(e.target.value))}
      >
        {lotteries.map(
          ({ id, name }: Lottery) => <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        )}
      </Select>
    </div>
    <LotteryDashboard key={lotteryId} lottery_id={Number(lotteryId) || undefined}/>
  </>
} 
