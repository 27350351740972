import list from './List'
import create from './Create'
import edit from './Edit'
import show from './Show'

export default {
  list,
  create, 
  edit,
  show
}
