import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState } from 'react'
import { useAuthProvider, useLogin, useNotify, useTranslate } from 'react-admin'
import Copyright from './components/Copyright'
import GoogleIcon from '@mui/icons-material/Google';
import { envMap } from './feathersClient'

export default () => {
  const [username, setUsername] = useState(sessionStorage.getItem('username') || '')
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()
  const translate = useTranslate()
  const authProvider = useAuthProvider()
    
  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      const payload = username.indexOf('@') >= 0 ? { email: username } : { phone: username }
      await login({ ...payload, password })
      await authProvider.checkAuth(true)
    } catch (e: any) {
      notify(translate(`${e?.message || e}`))
    }
  }

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 160, height: 160 }}>
            <img style={{ width: '100%' }} src="/nclusion-ring-logo.png" />
          </div>
          <Typography component="h1" variant="h5">
            {translate('nclusion.sign_in')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={translate('nclusion.email_or_phone')}
              name="username"
              autoComplete="username"
              autoFocus
              onChange={e => setUsername(e?.target?.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={translate('nclusion.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e?.target?.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {translate('nclusion.sign_in')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#/login?request-reset" variant="body2">
                {translate('nclusion.forgot_password')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#/login?signup" variant="body2">
                  {translate('nclusion.no_account_sign_up')}
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Link href="#/login?verify" variant="body2">
                  {translate('nclusion.received_verification_code')}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              onClick={() => {
                window.location.href = `${(envMap as any)[window.location.origin] || 'http://localhost:3030'}/oauth/google`
              }}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, px: 4, py: 1 }}
            >
              <GoogleIcon />
              <span style={{ marginLeft: 8 }}>Sign in with Google</span>
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  )
}
