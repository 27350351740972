import { ResponsiveLine } from '@nivo/line'
import ChartContext from '../ChartContext'
import { useContext, useEffect, useState } from 'react'
import { AggregateFunction, ClientApplication, DateHistogramAggregation, Wheres } from '@nclusion/feathers-client'
import { useTheme, useTranslate } from 'react-admin'
import { intersection, omit, findKey, uniqueId, compact, nth } from 'lodash'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Card from '@mui/material/Card'
import {
  startOfMinute,
  startOfHour,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  parseISO
} from 'date-fns'
import i18n, { langToLocale } from '../../../i18nProvider'
import { formatNumber, formatCurrency, currencyToLocale } from '../../../util'
import { TemporalHistogramAggregation } from '@nclusion/feathers-client'

type NivoLineChartConfig = any // fiugure out nivo types

export type TemporalLineChartProps = Omit<TemporalHistogramAggregation, 'service'> & {
  title: string
  isCurrency?: boolean
  config?: NivoLineChartConfig
  height?: number
  periods?: string[] // emum allowed periods
  valueField?: string
  fixedPeriod?: boolean
  agg: AggregateFunction
  lottery_ids?: number[]
  where?: Wheres
  service: Parameters<ClientApplication['service']>[0]
}

const periodStarts = {
  minute: startOfMinute,
  hour: startOfHour,
  day: startOfDay,
  week: startOfWeek,
  month: startOfMonth,
  quarter: startOfQuarter,
  year: startOfYear
}

const isStartOfPeriod = (period: keyof typeof periodStarts) => {
  const findStart = periodStarts[period]

  return (date: Date) => date.toISOString() === findStart(date).toISOString()
}

const ticks = {
  minute: {
    minute: {
     low: 0,
     high: 120
    },
    hour: {
      low: 120,
      high: 25 * 60
    },
    day: {
      low: 25 * 60,
      high: 14 * 24 * 60,
    },
    week: {
      low: 14 * 24 * 60,
      high: 60 * 24 * 60
    },
    month: {
      low: 60 * 24 * 60,
      high: 180 * 24 * 60
    },
    quarter: {
      low: 180 * 24 * 60,
      high: 720 * 24 * 60
    },
    year: {
      low: 720 * 24 * 60,
      high: Infinity
    }
  },
  hour: {
    hour: {
      low: 0,
      high: 48
    },
    day: {
      low: 24,
      high: 14 * 24,
    },
    week: {
      low: 14 * 24,
      high: 60 * 24
    },
    month: {
      low: 60 * 24,
      high: 180 * 24
    },
    quarter: {
      low: 180 * 24,
      high: 720 * 24
    },
    year: {
      low: 720 * 24,
      high: Infinity
    }
  },
  day: {
    day: {
      low: 0,
      high: 14
    },
    week: {
      low: 14,
      high: 60
    },
    month: {
      low: 60,
      high: 180
    },
    quarter: {
      low: 180,
      high: 720
    },
    year: {
      low: 720,
      high: Infinity
    }
  },
  week: {
    week: {
      low: 0,
      high: 15,
    },
    month: {
      low: 15,
      high: 26
    },
    quarter: {
      low: 26,
      high: 104
    },
    year: {
      low: 104,
      high: Infinity
    }
  },
  month: {
    month: {
      low: 0,
      high: 12
    },
    quarter: {
      low: 12,
      high: 36
    },
    year: {
      low: 36,
      high: Infinity
    }
  },
  quarter: {
    quarter: {
      low: 0,
      high: 12
    },
    year: {
      low: 12,
      high: Infinity
    }
  },
  year: {
    month: {
      low: 0,
      high: Infinity
    }
  }
}

const fallbackLocales = {
  'en-US': 'en-US',
  'es-DO': ['es-DO', 'es-ES'],
  'fr-HT': ['fr-HT', 'fr-FR'],
  'ht-HT': ['ht-HT', 'fr-HT', 'fr-FR']
}

const localeWithFallback = (locale: string) => (fallbackLocales[locale as keyof typeof fallbackLocales])

const formatters = (period: string, locale: string) => ({
  minute: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    hour: 'numeric',
    minute: 'numeric',
  }).format(val),
  hour: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    hour: 'numeric',
    minute: 'numeric',
  }).format(val) ,
  day: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    month: 'short',
    day: 'numeric',
  }).format(val),
  week: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    month: 'short',
    day: 'numeric',
  }).format(val),
  month: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    month: 'short',
    day: 'numeric',
  }).format(val),
  quarter: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    year: 'numeric',
    month: 'numeric',
  }).format(val),
  year: (val: Date) => Intl.DateTimeFormat(localeWithFallback(locale), {
    year: 'numeric',
    month: 'short',
  }).format(val),
}[period])

const formatTick = (x: any, period: string): any =>
  // @ts-ignore
  x ? formatters(period, langToLocale(i18n.lang), Intl.DateTimeFormat().resolvedOptions().timeZone)(x) : x  

const prettifyTicks = (results: any, period: string) => {
  const tickSpecs = ticks[period as keyof typeof ticks]
  const count = results.length
  const tickPeriod = findKey(tickSpecs, (spec: any) => spec.low <= count && spec.high > count)!

  return ({ x, y }: { x: string, y: number }) => {
    const val = parseISO(x)

    if (period === 'week') {
      return { x: formatTick(val, tickPeriod), y }
    }

    if (isStartOfPeriod(tickPeriod as keyof typeof periodStarts)(val)) {
      return { x: formatTick(val, tickPeriod), y }
    } else {
      return { x: '', y }
    }
  }
}

const TemporalLineChart = ({ id, service, aggregationId, keyField, valueField, fixedPeriod, period = 'day', agg, height = 360, config, lottery_ids, where, isCurrency }: TemporalLineChartProps) => {
  const translate = useTranslate()
  const [theme] = useTheme()

  const { search: { registerAggregation, onResults, currency } } = useContext(ChartContext)
  const [results, setResults] = useState<any[]>([])
  const [settings, setSettings] = useState<any>([])

  onResults(id, ({ results: newResults, settings: newSettings }: any) => {
    setResults(newResults)
    setSettings(newSettings)
  })

  useEffect(() => {
    registerAggregation({
      id,
      service,
      ...(keyField ? { keyField } : {}),
      ...(valueField ? { valueField } : {}),
      ...(agg ? { agg } : {}),
      period,
      where,
      aggregationId,
      fixedPeriod,
      ...(lottery_ids ? { lottery_ids } : {})
    } as any)
  }, [period])

  const prettifiedResults = results.map(prettifyTicks(results, settings?.period || period))
  const tickValues = compact<string>(prettifiedResults.map(({ x }: any) => x))
  const fullResults = prettifiedResults.map(({ x, y }: any, idx: number) => ({ x: x || results[idx]?.x, y }))

  // we need to compute the displayable form of the endpoints and replace first and last tickValues
  // and the x value for first and last in fullResults

  return results.length ? <div style={{ height, padding: 40 }}>
    <ResponsiveLine
      data={[{
        id: `${id}_${uniqueId()}`,
        data: fullResults
      }]}
      theme={{ text: { fill:  theme === 'dark' ? '#eee' : '#333'} }}
      tooltip={(t: any) => <div style={{
        backgroundColor: theme === 'dark' ? '#434343' : '#f4f4f4',
        border: `1px solid ${theme === 'dark' ? '#888' : '#bbb'}`,
        boxShadow: `1px 1px 3px ${theme === 'dark' ? '#121212' : '#e1e1e1'}`,
        padding: 4
      }}>{`${t.point.data.xFormatted}: ${t.point.data.yFormatted}`}</div>}
      xScale={{ type: 'point' }}
      xFormat={(val: any) => val.length < 10 ? val : formatTick(parseISO(val), period)}
      yFormat={(val: number) => (isCurrency ? formatCurrency(val, currency, true, true) : formatNumber(val, currencyToLocale(currency!), true, true))}
      colors={{ scheme: 'set2' }}
      enableArea={true}
      margin={{
        left: 80,
        bottom: 60,
        top: 20,
        right: 60
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: translate(`nclusion.${period}`),
        legendOffset: 45,
        legendPosition: 'middle',
        truncateTickAt: 0,
        tickValues
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isCurrency ? currency : translate(`nclusion.${agg || 'count'}`),
        legendOffset: -60,
        legendPosition: 'middle',
        truncateTickAt: 0,
        tickValues: 2,
        format: val => formatNumber(val, currency ? currencyToLocale(currency) : Intl.DateTimeFormat().resolvedOptions().locale, true)
      }}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      useMesh={true}
      enableCrosshair={true}
      {...config}
    />
  </div> : <></>
}

const defaultPeriods = ['minute', 'hour', 'day', 'week', 'month', 'quarter', 'year']

type Settings = {
  period: string
  periods: string[]
}

const TemporalLineChartWithPeriodSelector = (props: TemporalLineChartProps) => {
  const { search: { onResults, savedValues } } = useContext(ChartContext)
  const [settings, setSettings] = useState<Settings>({} as Settings)
  const [period, setPeriod] = useState((savedValues?.aggregations[props.id] as DateHistogramAggregation)?.period || props.period || 'day')
  const [periods, setPeriods] = useState(defaultPeriods)
  onResults(props.id, ({ settings: newSettings }: any) => setSettings(newSettings))
  const translate = useTranslate()

  useEffect(() => {
    if (settings?.period) {
      setPeriod(settings.period)
      setPeriods(settings.periods)
    }
  }, [settings])

  return <Card>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 20, paddingBottom: 20, paddingRight: 40, paddingLeft: 40 }}>
      <h3>{props.title}</h3>
      {intersection(settings.periods || periods, props?.periods || []).length > 1 ? <Select
        value={settings?.periods?.includes(settings.period) ? settings.period : nth(settings.periods, 1) || 'day'}
        onChange={(e: SelectChangeEvent) => setPeriod(e.target.value)}
      >
        {intersection(settings.periods || periods, props?.periods || []).map(
          (period: string) => <MenuItem key={period} value={period}>
            {period ? translate(`nclusion.period.${period}`) : ''}
          </MenuItem>
        )}
      </Select> : <></>}
    </div>
    {/* @ts-ignore */}
    <TemporalLineChart {...omit(props, ['periods'])} period={period} />
  </Card>
}

export default TemporalLineChartWithPeriodSelector
