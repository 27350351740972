import { Create, useTranslate } from 'react-admin'
import TranslationForm from './Form'
import RelatedTranslations from './RelatedTranslations'
import { urlQueryParams } from '../../util'

let CreateComponent = () => {
  const translate = useTranslate()
  const { key } = urlQueryParams()

  return <Create redirect="show">
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}>
      <TranslationForm />
      <div>
      </div>
    </div>
  </Create>
}

export default CreateComponent
