import { useInput } from 'react-admin'
import { TextField } from '@mui/material'

const MinPercentageInput = ({ source, maxField, label, alwaysOn }: { source: string, maxField: string, label: string, alwaysOn?: boolean }) => {
  const { id, field, fieldState } = useInput({
    source,
    parse: (val: string) => ({ $minPercent: [val ? Number(val) : 0, maxField] }),
    format: formValue => {
      const val = formValue?.$minPercent && formValue?.$minPercent[0]
      return val ? `${val}` : ''
    }
  })

  return (
    <label htmlFor={id} style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField label='Allocation %' type='numeric' id={id} {...field} />
      {fieldState.error && <span>{fieldState.error.message}</span>}
    </label>
  )
}

export default MinPercentageInput
