import Create from './Create'
import Edit from './Edit'
import List from './List'
import Show from './Show'

export default {
    list: List,
    show: Show,
    create: Create,
    edit: Edit
}
