import { SimpleForm, TextInput, BooleanInput } from "react-admin"
import { urlQueryParams } from '../../util'

const TranslationForm = () => {
  const {country, language, key } = urlQueryParams()

  return <SimpleForm>
    <TextInput disabled={!!language} source="language" {...(language ? { defaultValue: language} : {})} sx={{ alignSelf: 'stretch' }} />
    <TextInput disabled={!!country} source="country" {...(country ? { defaultValue: country} : {})} sx={{ alignSelf: 'stretch' }} />
    <TextInput disabled={!!key} source="key" {...(key ? { defaultValue: key} : {})} sx={{ alignSelf: 'stretch' }} />
    <TextInput source="text" sx={{ alignSelf: 'stretch' }} />
    <BooleanInput source="confirmed" sx={{ alignSelf: 'stretch' }} />
  </SimpleForm>
}

export default TranslationForm
