import {
  BooleanField,
  TextField,
  useRecordContext,
  useTranslate,
  Link,
  DateField,
  FunctionField,
} from 'react-admin'
import ChartController from './charts/ChartController'
import TemporalLineChart from './charts/aggregations/TemporalLineChart'
import DateRangePicker from './charts/filters/DateRangePicker'
import SimpleAggregate from './charts/aggregations/SimpleAggregate'
import ResultsTable from './charts/aggregations/ResultsTable'
import { Tabs, Tab, Chip } from '@mui/material'
import { useState } from 'react'
import PieChart from './charts/aggregations/PieChart'
import { useLocation } from 'react-router-dom'
import { castArray } from 'lodash'
import ShareButton from './charts/ShareButton'
import { formatNumbersOrSales, formatWinningNumbersString } from '../util'

const DrawingNumbersLink = ({
  source,
  sortable,
  label,
}: {
  source: string
  sortable: boolean
  label: string
}) => {
  const { id, drawing, confirmed } = useRecordContext()
  const translate = useTranslate()

  return (
    <Link
      onClick={e => e.stopPropagation()}
      to={`/drawing-numbers/${id}${confirmed ? '/show' : ''}`}
    >
      {translate(drawing.name)}
    </Link>
  )
}
const SessionLink = ({
  source,
  sortable,
  label,
}: {
  source: string
  sortable: boolean
  label: string
}) => {
  const { id, name } = useRecordContext()
  const translate = useTranslate()
  const nameSplit = name.split(' ')

  return (
    <Link onClick={e => e.stopPropagation()} to={`/session/${id}/show`}>
      {`${translate(nameSplit[0])} ${translate(nameSplit[1])} (${
        nameSplit[2]
      })`}
    </Link>
  )
}

export default ({ lottery_id }: { lottery_id?: number | number[] }) => {
  const translate = useTranslate()
  const [activeTab, setActiveTab] = useState(0)
  const location = useLocation()
  const lottery_ids = lottery_id ? castArray<number>(lottery_id) : []

  return (
    <div>
      <Tabs value={activeTab} onChange={(e, val) => setActiveTab(val)}>
        <Tab label={translate('nclusion.sales')} />
        <Tab label={translate('nclusion.networkStatus')} />
      </Tabs>
      {activeTab === 0 && (
        <div style={{ marginTop: '1rem' }}>
          <ChartController
            key={'foo'}
            storageKey={'lottery-sales'}
            listen={['refresh-publication', 'session']}
            currency='HTG'
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'bottom',
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gap: 20,
                      gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    }}
                  >
                    <SimpleAggregate
                      id='ticketCount'
                      service='transaction'
                      aggregationId='totalWagerAggregate'
                      valueField='total_amount'
                      aggs={['count']}
                      where={['status', 'purchased']}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                      labels={{
                        count: translate('nclusion.ticketsSold'),
                      }}
                    />
                    <SimpleAggregate
                      id='ticketSales'
                      service='transaction'
                      aggregationId='totalWagerAggregate'
                      valueField='total_amount'
                      aggs={['sum']}
                      where={['status', 'purchased']}
                      isCurrency={true}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                      labels={{
                        sum: translate('nclusion.totalSales'),
                      }}
                    />
                    <SimpleAggregate
                      id='bankCount'
                      service='transaction'
                      aggregationId='transactionEntityStats'
                      valueField='bank_id'
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                      aggs={['uniq']}
                      labels={{
                        uniq: translate('nclusion.banks'),
                      }}
                    />
                    <SimpleAggregate
                      id='associateCount'
                      service='transaction'
                      aggregationId='transactionEntityStats'
                      valueField='associate_id'
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                      aggs={['uniq']}
                      labels={{
                        uniq: translate('nclusion.agents'),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingRight: 12,
                    }}
                  >
                    <DateRangePicker
                      id={'createdAt'}
                      field={[
                        'createdAt',
                        { service: 'session', field: 'close_timestamp' },
                      ]}
                    />
                    <ShareButton />
                  </div>
                </div>
              </div>
              <TemporalLineChart
                title={translate(`nclusion.ticketCount`)}
                id='lotterySales'
                service='transaction'
                aggregationId='ticketSalesHistogram'
                period='day'
                agg='count'
                keyField='createdAt'
                valueField='id'
                {...(lottery_ids.length ? { lottery_ids } : {})}
                periods={[
                  'minute',
                  'hour',
                  'day',
                  'week',
                  'month',
                  'quarter',
                  'year',
                ]}
                config={{
                  useMesh: true,
                  enableCrosshair: true,
                }}
              />
              <div style={{ overflowY: 'auto', maxWidth: '100%' }}>
                <h3>{translate('nclusion.drawingResults')}</h3>
                <ResultsTable
                  id='drawingNumbers'
                  service='drawing-numbers'
                  aggregationId='results'
                  {...(lottery_ids.length
                    ? { where: ['lottery_id', lottery_ids] }
                    : {})}
                >
                  <DrawingNumbersLink
                    source='link'
                    sortable={false}
                    label={translate('nclusion.drawing')}
                  />
                  <DateField source='createdAt' showTime={true} />
                  <BooleanField source='confirmed' />
                  <FunctionField
                    source='winning_numbers'
                    render={(record: { winning_numbers: string }) =>
                      formatWinningNumbersString(record.winning_numbers)
                    }
                  />
                </ResultsTable>
              </div>
              <div>
                <div style={{ overflowY: 'auto', maxWidth: '100%' }}>
                  <h3>{translate('nclusion.sessionPayouts')}</h3>
                  <ResultsTable
                    id='sessionStats'
                    service='session'
                    aggregationId='sessionPayoutResults'
                    {...(lottery_ids.length ? { lottery_ids } : {})}
                  >
                    <SessionLink
                      source='link'
                      label={translate('nclusion.session')}
                      sortable={false}
                    />
                    <FunctionField
                      label={translate('nclusion.multiplier')}
                      sortable={false}
                      render={(record: { multiplier: string[] }) => {
                        if ((record.multiplier || []).length === 1) {
                          return (
                            <Chip
                              label={formatNumbersOrSales(
                                Number(record.multiplier[0])
                              )}
                            />
                          )
                        } else {
                          return record.multiplier?.map(rec => (
                            <Chip key={rec} label={rec} />
                          ))
                        }
                      }}
                    />
                    <TextField source='ticket_count' sortable={false} />
                    <TextField source='total_wager' sortable={false} />
                    <TextField source='total_payout' sortable={false} />
                    <TextField source='total_promotion' sortable={false} />
                  </ResultsTable>
                </div>
              </div>
            </div>
          </ChartController>
        </div>
      )}
      {activeTab === 1 && (
        <div style={{ marginTop: '1rem' }}>
          <ChartController storageKey='network-status' listen={['transaction']}>
            <>
              <div>
                <h3>{translate('nclusion.bankStatus.title')}</h3>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                      gap: 20,
                    }}
                  >
                    <SimpleAggregate
                      id='bankCount'
                      service='lottery' // remove
                      aggregationId='lotteryBanks'
                      valueField='id'
                      aggs={['count']}
                      labels={{
                        count: translate('nclusion.banks'),
                      }}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                    />
                    <SimpleAggregate
                      id='assignedBankCount'
                      service='lottery'
                      aggregationId='lotteryAssignedBanks'
                      valueField='bank_id'
                      aggs={['uniq']}
                      labels={{
                        uniq: translate('nclusion.assigned'),
                      }}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                    />
                  </div>
                  <div style={{ height: 420, width: 720 }}>
                    <PieChart
                      title={translate('nclusion.openBanks')}
                      id='bankStatusChart'
                      aggregationId='openBanks'
                      service='transaction'
                      keyField='id'
                      valueField='value'
                      height={420}
                      config={{
                        valueFormat: '>-d',
                      }}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3>{translate('nclusion.agentNetwork')}</h3>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                      gap: 20,
                    }}
                  >
                    <SimpleAggregate
                      id='agentCount'
                      service='transaction'
                      aggregationId='activeAgents'
                      valueField='associate_id'
                      where={['role_id', 1]}
                      aggs={['uniq']}
                      labels={{
                        uniq: translate('nclusion.agentCount'),
                      }}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                    />
                    <SimpleAggregate
                      id='operationalAgents'
                      valueField='id'
                      service='transaction'
                      aggregationId='operationalAgents'
                      aggs={['count']}
                      labels={{
                        count: translate('nclusion.operationalAgents'),
                      }}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                    />
                  </div>
                  <div style={{ height: 420, width: 720 }}>
                    <PieChart
                      title={translate('nclusion.activeAgents')}
                      id='agentStatusChart'
                      aggregationId='activeAgents'
                      keyField='id'
                      valueField='value'
                      service='transaction'
                      height={420}
                      config={{
                        valueFormat: '>-d',
                      }}
                      {...(lottery_ids.length ? { lottery_ids } : {})}
                    />
                  </div>
                </div>
              </div>
            </>
          </ChartController>
        </div>
      )}
    </div>
  )
}
