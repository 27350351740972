import { CreateButton, Datagrid, FilterButton, Link, SelectInput, TextField, TopToolbar, useTranslate } from 'react-admin'
import PartialMatchFilter from '../../components/PartialMatchFilter'
import { NclusionList } from '../../components/NclusionList'

const ListFilters = [
  <SelectInput
    source='language'
    choices={[
      {id: 'en', name: 'English'},
      {id: 'ht', name: 'Haitian Creole'},
      {id: 'es', name: 'Spanish'},
      {id: 'fr', name: 'French'}
    ]}
  />,
  <SelectInput
    source='country'
    choices={[
      {id: 'US', name: 'United States'},
      {id: 'HT', name: 'Haiti'},
      {id: 'DO', name: 'Dominican Republic'}
    ]}
  />,
  <PartialMatchFilter source='key' />,
  <PartialMatchFilter source='text' />
]

const ListActions = () => {
  const translate = useTranslate()

  return <TopToolbar sx={{ alignItems: 'center' }}>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-keys'}>{translate('nclusion.untranslatedKeys')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-unconfirmed'}>{translate('nclusion.unconfirmedTranslations')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-keys-dynamic'}>{translate('nclusion.dynamicTranslationKeys')}</Link>
    <FilterButton filters={ListFilters}></FilterButton>
    <CreateButton resource='translation' />
  </TopToolbar>
}

export default () => <NclusionList filters={ListFilters} actions={<ListActions />}>
  <Datagrid bulkActionButtons={false} rowClick='show'>
    <TextField source='language' />
    <TextField source='country' />
    <TextField source='key' />
    <TextField source='text' />
  </Datagrid>
</NclusionList>
