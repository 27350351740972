import { Query } from "@feathersjs/client"
import { useContext, useEffect } from "react"
import ChartContext from "../ChartContext"
import { useResourceContext } from "react-admin"
import { handleComparisonFilters } from "../../../util"
import { prepJSONFields } from "../../../dataProvider"
import app from "../../../feathersClient"
import { camelCase } from "lodash"


interface ServiceQueryProps {
  id?: string
  filterValues: Query
}

const ServiceQuery = ({ id, filterValues }: ServiceQueryProps) => {
  const { search: { setFilter} } = useContext(ChartContext)
  const resource = useResourceContext()
  const schema = app.get('schemas')[camelCase(resource)]

  useEffect(() => {
    const fn = async () => {
      setFilter({ id: id || 'serviceQuery', filterId: 'serviceQuery', serviceQuery: await handleComparisonFilters(resource as any, prepJSONFields(schema, filterValues)), service: resource } as any)
    }
    fn()
  }, [filterValues])

  return <></>
}

export default ServiceQuery