import {
  Edit,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  useRecordContext,
  useTranslate,
  SelectInput,
  required
} from 'react-admin'

const EditForm = (props: any) => {
  const record = useRecordContext(props)
  const translate = useTranslate()

  return (
    <SimpleForm {...props}>
      <ReferenceInput
        reference="sub-tenant"
        source="subtenant_id"
        filter={{
          tenant_id: record.tenant_id
        }}
      >
        <AutocompleteInput label="Sub Tenant" fullWidth />
      </ReferenceInput>
      <NumberInput
        source="metadata.commission"
        label={translate('commission')}
        min={0}
        max={1}
      />
      <SelectInput
        source="status"
        choices={[
          { id: 'active', name: 'active' },
          { id: 'suspended', name: 'suspended' }
        ]}
        validate={required()}
      />
    </SimpleForm>
  )
}

export default (props: any) => (
  // Here, the data hasn't been fetched yet
  <Edit {...props}>
    <EditForm />
  </Edit>
)
