import ChartContext from '../ChartContext'
import { ResponsivePie } from '@nivo/pie'
import { useContext, useEffect, useState } from 'react'
import { useTheme, useTranslate } from 'react-admin'
import { intersection, startCase } from 'lodash'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Card } from '@mui/material'
import { AggregateFunction, GroupedAggregation, ScalarAggregate } from '@nclusion/feathers-client'
import { ClientApplication } from '@nclusion/feathers-client'

export type PieChartProps = Omit<GroupedAggregation, 'aggs' | 'service'> & {
  id: string
  height?: number
  labels?: any
  config?: any
  title?: string
  selectOptions?: string[]
  lottery_ids?: number[]
  agg?: AggregateFunction
  aggs?: AggregateFunction[]
  service: Parameters<ClientApplication['service']>[0]
}

interface ComputedDatum {
  datum: {
    data: {
      id: string
      value: string
    }
  }
}

const aggOptions = ['count', 'sum', 'avg', 'min', 'max', 'uniq']

type PieChartDataPoint = any

const PieChart = ({ id, aggregationId = 'simpleGroup', agg, service, keyField, valueField = 'id',config = {}, selectOptions, title = '', height = 320, lottery_ids}: PieChartProps) => {
  const { search: { registerAggregation, onResults, savedValues } } = useContext(ChartContext)
  const translate = useTranslate()
  const [results, setResults] = useState<PieChartDataPoint[]>([])
  const [theme] = useTheme()
  const [selectValue, setSelectValue] = useState((savedValues?.aggregations[id] as ScalarAggregate & { agg: AggregateFunction })?.agg || agg || 'count')

  onResults(id, ({ results }: any) => {
    switch (agg) {
      case 'count':
      case 'uniq':
        setResults(results)
        break
      default: 
        setResults(results.map((r: {id: string, value: string}) => {return {...r, value: Number(r.value).toFixed(2)}}))
    }
  })

  useEffect(() => {
    registerAggregation({
      id,
      service,
      keyField,
      valueField,
      agg: selectValue,
      aggregationId,
      ...(lottery_ids ? { lottery_ids } : {})
    } as any)
  }, [selectValue])

  return results.length > 0 ? <Card sx={{ height, padding: 4 }}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <h3 style={{ margin: 0 }}>{title}</h3>
      {intersection<string>(aggOptions, selectOptions).length > 1 ?
        <Select
          value={selectValue}
          onChange={(e: SelectChangeEvent) => setSelectValue(e.target.value as AggregateFunction)}
          style={{ width: 100, margin: 10}}
        >
          {intersection<string>(aggOptions, selectOptions).map(a => <MenuItem value={a} key={a}>{a}</MenuItem>)}
        </Select> :
        <></>
      }
    </div>
    <ResponsivePie
      data={results.map(val => ({ id: val[keyField], value: val[valueField], label: startCase(val.id) }))}
      colors={{ scheme:  theme === 'dark' ? 'dark2' : 'set2' }}
      theme={{ text: { fill:  theme === 'dark' ? '#eee' : '#333', fontSize: 12} }}
      margin={{ left: 40, right: 40, top: 40, bottom: 40 }}
      arcLinkLabel={({ id }: { id: string }) => startCase(id)}
      tooltip={({ datum }: ComputedDatum) => <div style={{
        backgroundColor: theme === 'dark' ? '#434343' : '#f4f4f4',
        border: `1px solid ${theme === 'dark' ? '#888' : '#bbb'}`,
        boxShadow: `1px 1px 3px ${theme === 'dark' ? '#121212' : '#e1e1e1'}`,
        padding: 4
      }}>
        {`${startCase(translate(datum.data.id))}: ${datum.data.value}`}
      </div>}
      {...config}
    />
  </Card> : <></>
}

export default PieChart
