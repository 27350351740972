import { CreateButton, Datagrid, Link, TextField, TopToolbar, useTranslate } from 'react-admin'
import { NclusionList } from '../../components/NclusionList'

const ListActions = () => {
  const translate = useTranslate()
  return <TopToolbar sx={{ alignItems: 'center' }}>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation'}>{translate('nclusion.translations')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-keys'}>{translate('nclusion.untranslatedKeys')}</Link>
    <Link sx={{ textTransform: 'uppercase', fontSize: 14 }} to={'/translation-unconfirmed'}>{translate('nclusion.unconfirmedTranslations')}</Link>
    <CreateButton resource="translation" />
  </TopToolbar>
}

const TranslationKeysDynamicList = () => {
  const translate = useTranslate()

  return <div>
    <p>{translate('nclusion.dynamicKeysInstructions')}</p>
    <NclusionList
      title={translate('nclusion.dynamicTranslationKeys')}
      actions={<ListActions />}
    >
      <Datagrid
        rowClick={false}
        bulkActionButtons={false}
      >
        <TextField source="key" />
      </Datagrid>
    </NclusionList>
  </div>
}

export default TranslationKeysDynamicList
