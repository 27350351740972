import { TextField } from '@mui/material'
import { useController } from 'react-hook-form'
import { startCase } from 'lodash'

const PartialMatchFilter = ({ source, label, alwaysOn }: { source: string, label?: string, alwaysOn?: boolean }) => {
  const { field } = useController({ name: source, defaultValue: '' })

  return <TextField
    {...field}
    value={field.value?.$ilike?.substring(1, field.value?.$ilike?.length ? field.value.$ilike.length - 1 : 1) || ''}
    onChange={e => field.onChange(e.target.value ? { $ilike: `%${e.target.value}%` } : '')}
    label={label || startCase(source)}
  />
}

export default PartialMatchFilter
