import { List, Datagrid, TextField, useRecordContext } from 'react-admin'
import HiddenFilter from '../../components/HiddenFilter'
import { urlQueryParams } from '../../util'

const RelatedTranslations = () => {
  const { key: queryKey } = urlQueryParams()
  const { key: recordKey } = useRecordContext() || {}

  return <List
    disableSyncWithLocation={true}
    resource="translation"
    {...(queryKey || recordKey) ? { filter: { key: queryKey || recordKey } } : {} }
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="language" />
      <TextField source="country" />
      <TextField source="text" />
    </Datagrid>
  </List>
}

export default RelatedTranslations
