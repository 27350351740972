import { useAuthProvider, useTranslate } from 'react-admin'

import * as React from 'react'
import { values } from 'lodash'
import { useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import { pwTest } from '../../CustomSignUpPage'
import app from '../../feathersClient'
import PasswordRequirements from '../../components/PasswordRequirements'

interface Errors {
  password?: string
  confirm_password?:string
  old_password?:string
}

export default () => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const authProvider: any = useAuthProvider()
  const [password, setPassword] = React.useState('')
  const [confirm_password, setConfirmPassword] = React.useState('')
  const [old_password, setOldPassword] = React.useState('')
  const [errors, setErrors] = React.useState<Errors>({})
  const [bruteForceAttempts, setBruteForceAttempts] = React.useState(0)
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updatedErrors: Errors = {}

    if (password !== confirm_password) {
      updatedErrors.confirm_password = 'New passwords do not match'
    }
  
    if (!pwTest(password)) {
      updatedErrors.password = 'Password must be at least 6 characters long with at least 1 numeral and one special character'
    }

    const { id } = await authProvider.getIdentity()

    try {
      await app.service('users').patch(id, { password, old_password })
    } catch (e: any) {
      setBruteForceAttempts(bruteForceAttempts + 1)
      updatedErrors.password = translate(e.message)
    }
  
    setErrors(updatedErrors)
    if (values(updatedErrors).length) {
      return
    }

    navigate('/change-password-success')
  }

  return <div> 
      <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="old_password"
              label="Current Password"
              type="password"
              id="old-password"
              autoComplete="old-password"
              value={old_password}
              onChange={e => setOldPassword(e.target.value)}
              error={!!errors.old_password}
              {...(errors.old_password ? { helperText: errors.old_password } : {})}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              error={!!errors.password}
              {...(errors.password ? { helperText: errors.password } : {})}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              autoComplete="confirm-new-password"
              value={confirm_password}
              onChange={e => setConfirmPassword(e.target.value)}
              error={!!errors.confirm_password}
              {...(errors.confirm_password ? { helperText: errors.confirm_password } : {})}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 8 }}>
          <PasswordRequirements />
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Change Password
        </Button>
      </Box>
   </div>
}