import {
  Button,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  useAuthProvider,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import app from '../../feathersClient'
import { schemaFilters } from '../../util'
import { NclusionList } from '../../components/NclusionList'

const AcceptedField = ({ source }: { source: string }) => {
  const { id, accepted } = useRecordContext()
  const translate = useTranslate()

  return accepted ? (
    <span>{translate('nclusion.accepted')}</span>
  ) : (
    <Button
      onClick={async () => {
        await app.service('invitation').patch(id, { accepted: true })
        window.location.reload()
      }}
      label={translate('nclusion.acceptInvitation')}
      color='success'
    />
  )
}

const EmptyList = () => {
  const translate = useTranslate()

  return <p>{translate('nclusion.noInvitations')}</p>
}

const BusinessUnitField = ({source} : {source: string}) => {
  const record = useRecordContext()
  const translate = useTranslate()
  return <p> {
    record.subtenant_id ? translate('nclusion.subTenant') : 
    record.tenant_id ? translate('nclusion.tenant') :
    record.mfi_id ? translate('nclusion.mfi') : 
    record.merchant_id ? translate('nclusion.merchant') :
    record.lottery_id ? translate('nclusion.lottery') :
    ''}
  </p>
}

const BusinessUnitNameField = ({source} : {source: string}) => {
  const record = useRecordContext()
  
  return <ReferenceField 
    source={
      record.subtenant ? 'subtenant_id' : 
      record.tenant ? 'tenant_id' :
      record.mfi ? 'mfi_id' : 
      record.merchant ? 'merchant_id' :
      record.lottery ? 'lottery_id' :
      ''
    }
    reference={
      record.subtenant ? 'sub-tenant' : 
      record.tenant ? 'tenant' :
      record.mfi ? 'mfi' : 
      record.merchant ? 'merchant' :
      record.lottery ? 'lottery' :
      ''
    }
    link='show'
  />
}

const NewUserInvitations = () => {
  const authProvider = useAuthProvider()
  const user = authProvider.getIdentitySync()
  const listFilters = () => schemaFilters('invitation')

  return (
    <NclusionList
      resource='invitation'
      empty={<EmptyList />}
      filters={listFilters()}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source='emailOrPhoneNumber' />
        <BusinessUnitField source='businessUnit' />
        <BusinessUnitNameField source='businessUnitName' />
        <TextField source='role.name' sortable={false} />
        <TextField source='status' />
        <DateField source='expiration' />
        <AcceptedField source='accepted' />
      </Datagrid>
    </NclusionList>
  )
}

export default NewUserInvitations
