import { createContext } from "react";

export type AssociateNameContextType = {
  agent?: number[]
  supervisor?: number[]
  setValue?: (key: string, val: number[]) => void
}

const AssociateNameFilterContext = createContext<AssociateNameContextType>({})

export default AssociateNameFilterContext