import React from 'react'
import { type RaRecord, useRecordContext } from 'react-admin'
import { envMap } from '../feathersClient'

export const host =
  process.env.TOUSSAINT_API_URL ||
  envMap[window.location.origin] ||
  'http://localhost:3030'

export const portalUploadFile = ({
  body,
  headers
}: {
  body: any
  headers: HeadersInit
}) => {
  if (!body.get('files')) {
    // TODO: show error in UI
    throw new Error('No files to upload')
  }

  return fetch(`${host}/file-upload`, {
    method: 'POST',
    body,
    headers
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      throw err
    })
}

export const portalAttachUploadToResource = ({
  response,
  resource,
  record,
  key,
  headers
}: {
  response: Record<string, string>
  resource: string
  record: RaRecord
  key: string
  headers: HeadersInit
}) => {
  if (!response || !response.id) {
    throw new Error(
      `Unable to attach upload to resource '${resource}/${record.id}': bad response from portalUploadFile`
    )
  }

  const body = JSON.stringify({
    [key]: `${host}/file-upload/${response.id}`
  })
  return fetch(`${host}/${resource}/${record.id}`, {
    method: 'PATCH',
    body,
    headers
  })
    .then((response) => response.json())
    .then((response) => {
      // TODO: should this attach other values like resource, record, etc?
      return response
    })
}

export interface IImageInputPortalForm
  extends Partial<JSX.IntrinsicElements['form']> {
  resource: string // 'bank', ...
  resourceAssetKey: string
  onSubmitWithInfo?: ({
    resource,
    record
  }: {
    resource: string
    record: RaRecord
  }) => (event: React.FormEvent<HTMLFormElement>) => void
  onComplete?: ({
    response,
    resource,
    record,
    key
  }: {
    response: Record<string, any>
    resource: string
    record: RaRecord
    key: string
  }) => void
}
export const ImageInputPortalForm = ({
  resource,
  resourceAssetKey,
  id = 'uploader',
  encType = 'multipart/form-data',
  onSubmitWithInfo,
  onComplete = () => ({}),
  ...props
}: IImageInputPortalForm) => {
  const record = useRecordContext()
  const jwt = localStorage.getItem('feathers-jwt')

  const defaultImageInputPortalFormOnSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    const formEl: HTMLFormElement | null = document.querySelector('form#' + id)
    if (!formEl) {
      throw new Error(`Unknown form id passed to ImageInputPortalForm: ${id}`)
    }
    const formData = new FormData(formEl)
    const headers = {
      Authorization: `Bearer ${jwt}`
    }

    try {
      const uploadResponse = await portalUploadFile({ body: formData, headers })
      const attachResponse = await portalAttachUploadToResource({
        response: uploadResponse,
        resource,
        record,
        key: resourceAssetKey,
        headers
      })
      return await onComplete({
        response: attachResponse,
        resource,
        record,
        key: resourceAssetKey
      })
    } catch (e) {
      console.error(e)
      throw new Error(`Unable to submit ImageInputPortalForm (Server Error)`) // TODO: error message
    }
  }

  return (
    <form
      id={id}
      encType={encType}
      onSubmit={
        (onSubmitWithInfo && onSubmitWithInfo({ resource, record })) ||
        defaultImageInputPortalFormOnSubmit
      }
      {...props}
    >
      {/* <input type="hidden" name="portal" value="true" form={id} /> */}
    </form>
  )
}
