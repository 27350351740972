import { useNotify } from "react-admin"
import { User } from "@nclusion/feathers-client"
import { each, uniq, without } from "lodash"

export type AppState = {
  user: null | User
  notify: ReturnType<typeof useNotify>
  observe: Function,
  removeListener: Function
}

let user: null | User = null
let observers: Function[] = []

const appState: AppState = {
  set user (u: null | User) {
    each(observers, (observer: Function) => observer({ user: u }))
    user = u
  },
  get user (): null | User {
    return user
  },
  observe: (fn: Function) => { observers = uniq([...observers, fn ]) },
  removeListener: (fn: Function) => { observers = without(observers, fn)},
  notify: () => {}
}

export default appState
