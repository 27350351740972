import { useContext, useEffect, useState } from 'react'
import { Button, Confirm, Link, useTranslate } from 'react-admin'
import ChartContext from './charts/ChartContext'
import app from '../feathersClient'
import DeleteIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Card,
  CardHeader,
  CardContent,
  Modal,
  CardActions
} from '@mui/material'

type PopulatedChildRelationship = {
  associate_id: number
  business_unit: string
  relationship_id: number
  relationship_name: string
  mfi_id?: number
  tenant_id?: number
  subtenant_id?: number
  merchant_id?: number
  lottery_id?: number
  relationship_tree_id?: number
  child_associate_id?: number
  child_associate_first_name?: string
  child_associate_last_name?: string
  child_associate_alias?: string
  child_associate_phone?: string
  child_associate_status?: string
}

const UnassignRelationshipButton = ({
  relationship_tree_id,
  child_associate_first_name,
  child_associate_last_name,
  relationship_name
}: Partial<PopulatedChildRelationship>) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search } = useContext(ChartContext)

  const [error, setError] = useState('')

  const unassign = async () => {
    try {
      await app.service('relationship-tree').remove(relationship_tree_id!)
      search.search()
      setOpen(false)
    } catch (e) {
      setError(`${e}`)
    }
  }

  return (
    <>
      <DeleteIcon
        sx={{ color: '#b01349', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div style={{ margin: '10% 30% 0' }}>
            <Card variant="outlined">
              <CardHeader title={translate(relationship_name || '')} />
              <CardContent>
                <p>
                  {translate(relationship_name || '')}:{' '}
                  {child_associate_first_name} {child_associate_last_name}
                </p>
                <p>{translate('nclusion.confirmUnassignRelationship')}</p>
                {error && <p>{error}</p>}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  label={translate('nclusion.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  onClick={unassign}
                  sx={{ color: '#b01349' }}
                  label={translate('nclusion.unassign')}
                />
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

const RelationshipComponent = ({
  child
}: {
  child: PopulatedChildRelationship
}) => {
  const translate = useTranslate()

  const {
    associate_id,
    relationship_name,
    relationship_tree_id,
    child_associate_id,
    child_associate_first_name,
    child_associate_last_name,
    child_associate_alias,
    child_associate_phone,
    child_associate_status
  } = child

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        {!child_associate_id && (
          <div style={{ padding: '0 2rem' }}>
            <p>{translate('None')}</p>
          </div>
        )}
      </div>
      {child_associate_id && (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 3fr 1fr 3fr 1fr',
              padding: '0 2rem'
            }}
          >
            <span>{child_associate_alias}</span>
            <Link to={`/associate/${child_associate_id}/show`}>
              {child_associate_first_name} {child_associate_last_name}
            </Link>
            <a href={`tel://${child_associate_phone}`}>
              {child_associate_phone}
            </a>
            <span>{child_associate_status}</span>
            <UnassignRelationshipButton
              associate_id={associate_id}
              child_associate_first_name={child_associate_first_name}
              child_associate_last_name={child_associate_last_name}
              relationship_tree_id={relationship_tree_id}
              relationship_name={relationship_name}
            />
          </div>
        </>
      )}
    </>
  )
}

const ChildRelationshipComponent = ({
  children: initialChildren
}: {
  children: PopulatedChildRelationship[]
}) => {
  const translate = useTranslate()
  const [suspendOpen, setSuspendOpen] = useState(false)
  const [activateOpen, setActivateOpen] = useState(false)
  const [childrenState, setChildrenState] = useState(initialChildren)

  useEffect(() => {
    setChildrenState(initialChildren)
  }, [initialChildren])

  const groupedChildren = childrenState.reduce(
    (result: { [key: string]: PopulatedChildRelationship[] }, value) => {
      ;(
        result[value.relationship_name] ||
        (result[value.relationship_name] = [])
      ).push(value)
      return result
    },
    {}
  )

  const updateChildrenStatus = (status: string, relationship: string) => {
    setChildrenState((prevChildren) =>
      prevChildren.map((child) =>
        child.relationship_name === relationship
          ? { ...child, child_associate_status: status }
          : child
      )
    )
  }

  return (
    <div style={{ marginTop: 20 }}>
      <h2>{translate('nclusion.Children')}</h2>
      <div
        style={{
          borderTop: '1px solid #888',
          padding: '0.5rem 0',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {Object.keys(groupedChildren).map((relationship) => (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <h3>{translate(relationship)}</h3>
              {groupedChildren[relationship]?.[0]?.child_associate_id && (
                <div>
                  <Button
                    label={translate('nclusion.ActivateAll')}
                    onClick={() => setActivateOpen(true)}
                  />
                  <Confirm
                    isOpen={activateOpen}
                    onClose={() => setActivateOpen(false)}
                    onConfirm={async () => {
                      const ids = groupedChildren[relationship].map(
                        (groupedChild) => groupedChild.child_associate_id
                      )
                      try {
                        await app
                          .service('associate')
                          .patch(
                            null,
                            { status: 'active' },
                            { query: { id: { $in: ids } } }
                          )
                        updateChildrenStatus('active', relationship)
                      } catch (e) {
                        console.log(e)
                      }
                      setActivateOpen(false)
                    }}
                    title={translate('nclusion.ActivateAll')}
                    content={translate('nclusion.Confirm.ActivateAll')}
                  />
                  <Button
                    label={translate('nclusion.SuspendAll')}
                    onClick={() => setSuspendOpen(true)}
                  />
                  <Confirm
                    isOpen={suspendOpen}
                    onClose={() => setSuspendOpen(false)}
                    onConfirm={async () => {
                      const ids = groupedChildren[relationship].map(
                        (groupedChild) => groupedChild.child_associate_id
                      )
                      try {
                        await app
                          .service('associate')
                          .patch(
                            null,
                            { status: 'suspended' },
                            { query: { id: { $in: ids } } }
                          )
                        updateChildrenStatus('suspended', relationship)
                      } catch (e) {
                        console.log(e)
                      }
                      setSuspendOpen(false)
                    }}
                    title={translate('nclusion.SuspendAll')}
                    content={translate('nclusion.Confirm.SuspendAll')}
                  />
                </div>
              )}
            </div>
            {groupedChildren[relationship].map((groupedChild) => (
              <RelationshipComponent child={groupedChild} />
            ))}
          </>
        ))}
      </div>
    </div>
  )
}

export default ChildRelationshipComponent
