import { 
  BooleanInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useUpdate
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { urlQueryParams } from '../../util'

export default () => {
  const [update] = useUpdate()
  const navigate = useNavigate()
  const { redirect } = urlQueryParams()
  const onSubmit = (data: { confirmed: boolean; winning_numbers: string; id: number }) => {
    alert('change!')
    update('drawing-numbers', {
      data: { confirmed: true, winning_numbers: data.winning_numbers },
      id: data.id
    })
    navigate(redirect || '/drawing-numbers')
  }

  const toolbar = <Toolbar>
    <SaveButton label='Confirm' type='button'/>
  </Toolbar>

  return <Edit resource='drawing-numbers'>
    <SimpleForm toolbar={toolbar} onSubmit={form => onSubmit(form.data)}>
      <TextInput source='winning_numbers' />
      <BooleanInput source="confirmed" />
    </SimpleForm>
  </Edit>
}
