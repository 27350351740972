import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FunctionField,
  NumberField,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate
} from 'react-admin'
import { NclusionList } from '../../components/NclusionList'
import { getLocale } from '../../util'

export default () => {
  const translate = useTranslate()
  const locale = getLocale()

  const listFilters = [
    <ReferenceInput source="drawing_id" reference="drawing">
      <AutocompleteInput
        source="drawing_id"
        optionText={(record) => translate(record.name)}
        inputText={(choice) => translate(choice.name)}
      />
    </ReferenceInput>,
    <ReferenceInput source="game_id" reference="game">
      <AutocompleteInput
        source="game_id"
        optionText={(record) => translate(record.name)}
        inputText={(choice) => translate(choice.name)}
      />
    </ReferenceInput>,
    <TextInput source="pick" />
  ]

  const ListActions = (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  )

  return (
    <NclusionList
      resource="live-allocation"
      filters={listFilters}
      actions={ListActions}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <FunctionField
          label={translate('nclusion.drawing')}
          render={(record: { drawing: { name: string } }) =>
            translate(record.drawing.name)
          }
        />
        <FunctionField
          label={translate('nclusion.game')}
          render={(record: { game: { name: string } }) =>
            translate(record.game.name)
          }
        />
        <DateField
          source="session.close_timestamp"
          label={translate('nclusion.session')}
          sortable={false}
        />
        <TextField source="pick" />
        <NumberField source="allocation" locales={locale} sortable={false} />
        <NumberField
          source="max_allocation"
          locales={getLocale()}
          label={translate('nclusion.maxAllocation')}
        />
        <FunctionField
          label={translate('nclusion.usage%')}
          render={(record: any) =>
            record.allocation && record.max_allocation
              ? `${((record.allocation / record.max_allocation) * 100).toFixed(
                  2
                )} %`
              : 0
          }
        />
      </Datagrid>
    </NclusionList>
  )
}
