import polyglotI18nProvider from 'ra-i18n-polyglot'
import en from 'ra-language-english'
import fr from 'ra-language-french'
import { restClient } from './feathersClient'
const ht = {}
const es = {}

type LanguageCode = 'en' | 'es' | 'fr' | 'ht'

const localeData: any = {
  'en-US': { ...en },
  'es-DO': { ...es },
  'fr-HT': { ...fr },
  'ht-HT': { ...ht },
}

export const initTranslations = async (locale?: string) => {
  const locales = Object.keys(locale ? { [locale || 'en-US']: '' } : await restClient.service('locale').find({}))
  const blobs = await Promise.all(locales.map((l: string) => restClient.service('locale').get(l)))
  locales.map((locale: string, idx: number) => {
    localeData[locale] = Object.assign(localeData[locale] || {}, blobs[idx])
  })
}

export const langToLocale = (lang: string) => ({
  en: 'en-US',
  es: 'es-DO',
  fr: 'fr-HT',
  ht: 'ht-HT'
}[lang]) || 'en-US'

export const getLanguage = () => {
  const locale = navigator.language
  if (locale.toLowerCase().indexOf('ht') >= 0) {
    return 'ht'
  }
  const lang = locale.split('-')[0]
  if (['en', 'fr', 'es'].indexOf(lang) >= 0) {
    return lang
  }
  return 'en'
}

export const languageOptions = [
  { locale: 'en', name: 'English' },
  { locale: 'es', name: 'Español' },
  { locale: 'fr', name: 'Français' },
  { locale: 'ht', name: 'Kreyol Ayisyen' }
]

export default polyglotI18nProvider(
  (lang: string) => {
    localStorage.setItem('lang', lang)
    return localeData[langToLocale(lang)]
  },
  getLanguage(),
  languageOptions,
  { allowMissing: true }
)
