import { useEffect, useState } from 'react'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'

export interface IFetchAuthenticatedImage {
  src: IAuthenticatedImage['src']
  token: string
  headers?: HeadersInit
  callback?: Function
}
export const fetchAuthenticatedImage = async ({
  src,
  token,
  headers = {},
  callback
}: IFetchAuthenticatedImage) => {
  return fetch(src, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers
    }
  })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          try {
            let reader = new FileReader()
            reader.onload = function () {
              resolve(this.result as string)
            }
            reader.readAsDataURL(blob)
          } catch (e) {
            reject(e)
          }
        })
    )
    .then((b64img) => {
      if (callback) {
        callback(b64img as string)
      }
      return b64img as string
    })
}

export interface IAuthenticatedImage
  extends Partial<JSX.IntrinsicElements['img']> {
  src: string
}
export default ({ src, ...props }: IAuthenticatedImage) => {
  const [source, setSource] = useState<string>('')

  useEffect(() => {
    const jwt = localStorage.getItem('feathers-jwt')
    if (!jwt) {
      console.log('[warning]: no auth supplied to <AuthenticatedImage/>')
      return
    }
    fetchAuthenticatedImage({ src, token: jwt }).then((b64img) => {
      if (typeof b64img === 'string') {
        setSource(b64img as string)
      }
    })
  }, [src])

  if (source == '') {
    return (
      <div className="">
        <HourglassEmptyIcon />
        Loading...
      </div>
    )
  }

  return (
    <div className="">
      <img src={source} {...props} />
    </div>
  )
}
