import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useDataProvider, useListContext, useTranslate } from "react-admin"
import { useContext, useEffect, useState } from 'react'
import { intersection, omit, uniq } from 'lodash'
import AssociateNameFilterContext from './AssociateNameFilterContext'

type AssociateNameFilterOption = {
  id: number
  name: string
  bank_ids: number[]
}

const AssociateNameFilter = ({ alwaysOn, source }: { alwaysOn?: boolean, source: 'agent' | 'supervisor' }) => {
  const { filterValues, setFilters, displayedFilters, hideFilter } = useListContext()
  const [options, setOptions] = useState<AssociateNameFilterOption[]>([])
  const [selected, setSelected] = useState<null | AssociateNameFilterOption>(null)
  const [previouslySelected, setPreviouslySelected] = useState<null | AssociateNameFilterOption>(null)
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const nameFilterContext = useContext(AssociateNameFilterContext)

  const getOptions = async (e: any, name: string) => {
    const newOptions = await dataProvider.getList('associate-bank-search', { filter: { name, associateType: source }, pagination: { page: 1, perPage: 10 }, sort: { field: 'name', order: 'ASC' } })
    setOptions(newOptions?.data || [])
  }

  useEffect(() => {
    getOptions(null, '')
  }, [])

  useEffect(() => {
    if (selected) {
      nameFilterContext.setValue && nameFilterContext.setValue(source, selected.bank_ids)
      const newValues = filterValues?.id?.$in?.length ? intersection<number>(filterValues?.id?.$in, selected.bank_ids) : selected.bank_ids
      setFilters({ ...filterValues || {}, id: newValues.length ? { $in: newValues } : 0 }, displayedFilters)
    } else {
      nameFilterContext.setValue && nameFilterContext.setValue(source, [])

      const remainingIds = filterValues?.id?.$in?.filter((id: number) => !(previouslySelected?.bank_ids || []).includes(id)) || []

      const otherFilterKeys = Object.keys(omit(nameFilterContext, ['setValue', source]))
      const otherFilterIds = uniq(otherFilterKeys.map(k => nameFilterContext[k as keyof typeof nameFilterContext]).flat())

      const newIds = uniq([...(remainingIds || []), ...(otherFilterIds || [])])

      if (newIds.length) {
        setFilters({ ...filterValues, id: { $in: newIds } }, displayedFilters)
      } else {
        setFilters(omit(filterValues, ['id']), displayedFilters)
      }
    }
  }, [selected?.id])

  const onSelectAssociate = (e: any, data: null | AssociateNameFilterOption) => {
    setPreviouslySelected(selected)
    if (data) {
      setSelected(data)
    } else {
      setSelected(null)
    }
  }

  return <Autocomplete
    sx={{ minWidth: 180 }}
    options={options.map(option => ({ ...option, label: option.name, key: option.name }))}
    onInputChange={getOptions}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    onChange={onSelectAssociate}
    renderInput={params => <TextField {...params} label={translate(`nclusion.${source}`)}/>}
  />
}

export default AssociateNameFilter
