import type { AuthProvider } from 'react-admin'
import app, { restClient } from './feathersClient'
import appState from './appState'

export let schemasPromise: any

const getClient = (): any => appState.user ? app : restClient

const jwtAuth = async (jwt: string) => {
  const response = await restClient.service('authentication').create({ strategy: 'jwt', accessToken: jwt }).catch(appState.notify)
 
  if (!response) {
    return
  }

  await app.authenticate({ strategy: 'jwt', accessToken: response.accessToken })

  app.set('schemas', await app.service('schema').find({}))
  appState.user = response.user

  return response.user
}

const authProvider: AuthProvider = {
  login: async ({ email, phone, password }: any): Promise<void> => {
    appState.user = null
    const strategy = phone ? 'phone' : 'local'
    const response = await restClient.service('authentication').create({
      strategy,
      password,
      ...(phone ? { phone } : { email })
    })
    
    await jwtAuth(response.accessToken)

    return Promise.resolve()
  },

  checkError: () => Promise.resolve(),

  checkAuth: async () => {
    const jwt = localStorage.getItem('feathers-jwt')

    if (!appState.user && jwt) {
      await jwtAuth(jwt)
    }
    return appState.user ? Promise.resolve() : Promise.reject()
  },

  logout: async () => {
    localStorage.removeItem('feathers-jwt')
    appState.user = null
    return Promise.resolve()
  }, 

  getIdentity: () => {
    return appState.user ? Promise.resolve(appState.user) : Promise.reject()
  },

  getIdentitySync: () => appState.user,

  // this may be usable for Google SSO
  handleCallback: () => Promise.resolve(), 

  getPermissions: async () => {
    // we'll be able to do this now
    return Promise.resolve()
  },
}

export default authProvider
