import React from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import { useNavigate } from 'react-router-dom'
import app from '../../feathersClient'
import { AutocompleteInput, Form, NumberField, NumberInput, ReferenceInput, SimpleForm, useTranslate } from 'react-admin'
import { Checkbox } from '@mui/material'

export default () => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const [password, setPassword] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [origin, setOrigin] = React.useState(/*'https://qa-api-734938197041.us-west4.run.app'*/'')
  const [fromTenantId, setFromTenantId] = React.useState(true)
  const [toTenantId, setToTenantId] = React.useState(true)
  const [error, setError] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)
  const [deleteMissing, setDeleteMissing] = React.useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updatedError = ''

    if (!origin || !password || !username) {
      setError('All fields are required')
      return
    }

    try {
      setSubmitted(true)
      console.log(await app.service('bank-import').create({
        username,
        password,
        origin,
        fromTenantId,
        toTenantId,
        deleteMissing
      }))
      console.log('import done')
    } catch (e: any) {
      setError(translate(`${e}`))
      return
    }
    setSubmitted(false)

    navigate('/bank')
  }
  
  return <div>
    <h2>Import Banks</h2>
    {!!error && <p style={{ margin: '16px 0'}}>{error}</p>}
    <Box component='form' onSubmit={handleSubmit} sx={{ p: 3 }}>
      <CssBaseline />
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name='origin'
              label='Remote HTTP Origin'
              id='origin'
              value={origin}
              onChange={e => setOrigin(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name='username'
              label='Email or Phone Number'
              id='username'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberInput
              required
              fullWidth
              source='fromTenantId'
              name='fromTenantId'
              label='From: tenant id'
              id='fromTenantId'
              value={fromTenantId}
              onChange={e => setFromTenantId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput isRequired reference='tenant' source='tenant_id'>
              <AutocompleteInput label='To: Tenant' fullWidth onChange={tenant_id => setToTenantId(tenant_id)}/>
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Checkbox
              required
              name='overwrite'
              id='overwrite'
              checked={deleteMissing}
              onChange={() => setDeleteMissing(!deleteMissing)}
            />
            <label>Remove missing banks</label>
            </Grid>
        </Grid>
      </Form>
      <Button
        type='submit'
        disabled={submitted}
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2 }}
      >
        Import
      </Button>
    </Box>
  </div>
}
