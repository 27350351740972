import { Filter, FilterSet, Aggregation, AggregationSet, ResultSet, Results } from '@nclusion/feathers-client'
import app from '../../feathersClient'
import { langToLocale } from '../../i18nProvider'
import { useLocaleState } from 'react-admin'
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  UpdateManyResult,
  DeleteParams,
  DeleteResult,
  DeleteManyResult,
  DeleteManyParams
} from 'react-admin'
import { ClientApplication } from '@nclusion/feathers-client'
import { uniq, isEqual, pick, debounce, pickBy, isNumber, mapValues, identity, mapKeys, isDate } from 'lodash'
import appState from '../../appState'

export type ServiceName = Parameters<ClientApplication['service']>[0]
type SettingsSet = { [k: string]: any}

const dataProviderAsyncNoop = <P, R>(fn: any) => async (resource: any, params: P): Promise<R> => fn(params)

class Search {
  public filters: FilterSet
  public aggregations: AggregationSet
  public results: ResultSet
  public settings: SettingsSet
  public locale: string
  public timezone: string
  public listeners: { [id: string]: Function[] }
  public savedValues: null | { filters: Partial<FilterSet>, aggregations: Partial<AggregationSet>}
  public storageKey: string
  public listen: (Parameters<ClientApplication['service']>[0])[]
  public currency?: string

  constructor ({ storageKey, listen, currency }: { storageKey?: string, listen?: (Parameters<ClientApplication['service']>[0])[], currency?: string }) {
    const lang = useLocaleState()
    this.locale = langToLocale(lang as any)
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    this.aggregations = {}
    this.filters = {}
    this.results = {}
    this.settings = {}
    this.listeners = {}
    this.currency = currency || 'HTG'

    this.storageKey = storageKey || ''
    const saved = storageKey ? localStorage.getItem(storageKey) : null
    const linked = window.location.href.split('?')[1]
    this.savedValues = linked ? this.parse(linked) : saved ? this.parse(saved) : null

    if (linked) {
      window.history.pushState(null, '', window.location.href.split('?')[0])
    }

    this.listen = listen || []

    if (listen) {
      for (let service of listen) {
        app.service(service).on('created', this.listener)
        app.service(service).on('patched', this.listener)
        app.service(service).on('removed', this.listener)
      }
    }
  }

  public listener = async () => {
    await new Promise(resolve => setTimeout(resolve, 500))
    return this.search()
  }

  public destructor = (() => {
    const listen = this.listen || []
    for (let service of listen) {
      app.service(service).removeListener('created', this.listener)
      app.service(service).removeListener('patched', this.listener)
      app.service(service).removeListener('removed', this.listener)
    }
    
  }).bind(this)

  public search = debounce((async () => {
    if (Object.keys(this.aggregations).length < 1) {
      return
    }
    try {
      const { results, settings } = await app.service('aggregation' as any).create({
        filters: this.filters,
        aggregations: this.aggregations,
        locale: this.locale,
        timezone: this.timezone
      })
      if (this.storageKey) {
        localStorage.setItem(this.storageKey, this.stringify())
      }
      const resultsKeys = Object.keys(results)
      const settingsKeys = Object.keys(settings)
      for (let key of resultsKeys) {
        this.results[key] = results[key]
      }
      for (let key of settingsKeys) {
        this.settings[key] = settings[key]
      }
      const ids = uniq([...resultsKeys, ...settingsKeys])
      for (let id of ids) {
        for (let listener of this.listeners[id]) {
          listener({
            results: this.results[id] || null,
            settings: this.settings[id] || {},
            aggregation: this.aggregations[id] || {},
            filters: this.filters || {}
          })
        }
      }
    } catch (e: any) {
      appState.notify(e.message || `${e}`, { multiline: true, type: 'error' })
    }
  }).bind(this), 10)

  public onResults = ((id: string, fn: Function) => {
    if (this.listeners[id as any] && this.listeners[id as any].indexOf(fn) >= 0) {
      return
    }
    if (!this.listeners[id]) {
      this.listeners[id] = []
    }
    this.listeners[id]?.push(fn)
  }).bind(this)

  public setFilter = ((filter: Filter) => {
    this.filters[filter.id] = filter
    this.search()
  }).bind(this)

  public unsetFilter = ((id: string) => {
    delete this.filters[id]
    this.search()
  }).bind(this)

  public registerAggregation = (async (aggregation: Aggregation) => {
    this.aggregations[aggregation.id] = aggregation
    await this.search()
  }).bind(this)
  
  public unregisterAggregation = ((id: string) => {
    delete this.aggregations[id]
    delete this.results[id]
  }).bind(this) 

  public stringify = (() => {
    let { filters, aggregations } = this as any
    filters = mapValues<Partial<FilterSet>>(filters, (f: Filter) => pick(f, ['start', 'end', 'min', 'max', 'period', 'value']))
    aggregations = mapValues<Partial<FilterSet>>(aggregations, (a: Aggregation) => pick(a, ['sortField', 'sortDir', 'skip', 'limit', 'period', 'agg']))

    let pieces = []
    for (let key in filters) {
      let filter = pickBy(filters[key], identity)
      filter = mapKeys(filter, ((value: any, k: string) => `${key}.${k}`))
      for (let k in filter) {
        const val = filter[k]
        pieces.push(`!${k}=${isDate(val) ? val.getTime() : val}`)
      }
    }
    for (let key in aggregations) {
      let agg = pickBy(aggregations[key], identity)
      agg = mapKeys(agg, ((value: any, k: string) => `${key}.${k}`))
      for (let k in agg) {
        pieces.push(`${k}=${agg[k]}`)
      }
    }

    return pieces.join('&')
  }).bind(this)

  public parse = (searchString: string) => {
    const elements = searchString.split('&')
    const savedValues: any = { filters: {}, aggregations: {} }
    for (let el of elements) {
      let [key, val] = el.split('=')
      const isFilter = key.startsWith('!')
      if (isFilter) {
        const [id, prop] = key.substring(1).split('.')
        savedValues.filters[id] = savedValues.filters[id] || {} as Filter
        Object.assign(savedValues.filters[id]!, { [prop]: ['start', 'end'].includes(prop) ? new Date(Number(val)) : val })
      } else {
        const [id, prop] = key.split('.')
        savedValues.aggregations[id] = savedValues.aggregations[id] || {} as Aggregation
        Object.assign(savedValues.aggregations[id]!, { [prop]: ['start', 'end'].includes(prop) ? new Date(Number(val)) : val })
      }
    }
    
    return savedValues
  }
  public getList = (async (resource: any, { filter, sort, pagination, meta }: GetListParams): Promise<GetListResult> => {
    const node = this.aggregations[meta.id]
    if (node) {
      const newConfig = {
        sortDir: sort.order.toLowerCase(),
        sortField: sort.field,
        skip: (pagination.perPage * (pagination.page - 1)),
        limit: pagination.perPage
      }
      if (!isEqual(newConfig, pick(node, ['sortDir', 'sortField', 'limit', 'skip']))) {
        await this.registerAggregation({
          ...this.aggregations[meta.id],
          ...pickBy(newConfig, x => x || isNumber(x))
        })
      }
    }

    await new Promise(resolve => setTimeout(resolve, 500))

    // @ts-ignore
    return { data: this.results[meta?.id]?.data|| [], total: <any>this.results[meta?.id]?.total  }
  }).bind(this)

  public getOne = (async (id: any, params: GetOneParams): Promise<GetOneResult> => {
    const result = this.results[params.meta?.id]
    const record = result && result[0]

    if (!record) {
      return { data: { id: params.meta.recordId } }
    }
    if (params.meta.swapId) {
      record.id = record[params.meta.swapId]
    }

    return { data: record }
  }).bind(this)

  public getMany = dataProviderAsyncNoop<GetManyParams, GetManyResult>((params: GetManyParams) => ({ data: [], total: 0 }))
  public getManyReference = dataProviderAsyncNoop<GetManyReferenceParams, GetManyReferenceResult>((params: GetManyReferenceParams) => ({ data: [], total: 0 }))
  public create =  dataProviderAsyncNoop<CreateParams, CreateResult>((params: CreateParams) => ({ id: 0 }))
  public update = dataProviderAsyncNoop<UpdateParams, UpdateResult>((params: UpdateParams) => ({ id: 0 }))
  public updateMany = dataProviderAsyncNoop<UpdateManyParams, UpdateManyResult>((params: UpdateManyParams) => ({ data: [], total: 0 }))
  public delete = dataProviderAsyncNoop<DeleteParams, DeleteResult>((params: UpdateParams) => ({ id: 0 }))
  public deleteMany = dataProviderAsyncNoop<DeleteManyParams, DeleteManyResult>((params: UpdateParams) => ({ data: [], total: 0 }))
}

export default Search
