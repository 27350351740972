import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { values } from 'lodash'
import { useNavigate  } from 'react-router-dom'
import Copyright from './components/Copyright'
import { useTranslate } from 'react-admin'
import { restClient } from './feathersClient'

interface Errors {
  username?: string
}

export default function RequestReset() {
  const [username, setUsername] = React.useState('')
  const [errors, setErrors] = React.useState<Errors>({})
  const navigate = useNavigate()
  const translate = useTranslate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updatedErrors: Errors = {}
  
    try {
      const key = username.indexOf('@') >= 0 ? 'email' : 'phone'
      const { code, code_phone } = await restClient.service('verification').create({ [key]: username })
      sessionStorage.setItem('username', username)
      sessionStorage.setItem('resetPassword', 'yes')
      sessionStorage.setItem('code', code || '')
      sessionStorage.setItem('code_phone', code_phone || '')
    } catch (e: any) {
      if (!String(e).startsWith('NotFound')) {
        updatedErrors.username = translate(e.message)
      }
    }

    setErrors(updatedErrors)
    if (values(updatedErrors).length) {
      return
    }


    navigate('/login?reset')
  }

  const errorStyle = { color: 'red' }

  return (
      <Container key={1} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 160, height: 160 }}>
            <img style={{ width: '100%' }} src="/nclusion-ring-logo.png" />
          </div>
          <Typography component="h1" variant="h5">
            {translate('nclusion.forgot_password')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography component="p" sx={{ textAlign: 'center', mb: 3 }}>
              {translate('nclusion.password_reset_text')}.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="username"
                  required
                  fullWidth
                  id="username"
                  label={translate('nclusion.email_or_phone')}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  autoFocus
                  error={!!errors.username}
                  {...(errors.username ? { helperText: errors.username } : {})}
                />
                <legend style={{ fontSize: 12, color: '#aaa' }}>{translate('nclusion.reset_input_legend')}.</legend>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {translate('nclusion.reset_button_text')}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#/login?resend-confirmation" variant="body2" sx={{ mx: 4}}>
                  {translate('nclusion.resend_code')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#/login" variant="body2">
                  {translate('nclusion.account_already')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
  );
}
