import { useState } from 'react'
import {
  Create,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm
} from 'react-admin'

export default () => {
  const [tenant_id, setTenant_id] = useState()

  return <Create>
    <SimpleForm>
      <ReferenceInput reference='users' source='user_id'>
        <AutocompleteInput label='User' fullWidth />
      </ReferenceInput>
      <ReferenceInput reference='tenant' source='tenant_id'>
        <AutocompleteInput label='Tenant' fullWidth onChange={tenant_id => setTenant_id(tenant_id)}/>
      </ReferenceInput>
      <ReferenceInput reference='sub-tenant' source='subtenant_id' filter={{
        tenant_id
      }}>
        <AutocompleteInput label='Sub Tenant' fullWidth />
      </ReferenceInput>
      <NumberInput source='metadata.commission' label='Commission' min={0} max={1}/>
    </SimpleForm>
  </Create>
}