import { useState, useContext, useEffect } from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ChartContext from '../ChartContext'
import { useTheme, useTranslate } from 'react-admin'
import { intersection, compact } from 'lodash'
import ListIcon from '@mui/icons-material/List'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import { DayPicker, DateRange } from 'react-day-picker'
import { langToLocale } from '../../../i18nProvider'
import { useLocaleState } from 'react-admin'
import 'react-day-picker/dist/style.css'
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  addDays,
  addWeeks,
  addMonths,
  addQuarters
} from 'date-fns'
import { DateRangeFilter } from '@nclusion/feathers-client'

const defaultPeriods = [
  'today',
  'yesterday',
  'thisWeek',
  'lastWeek',
  'thisMonth',
  'lastMonth',
  'thisQuarter',
  'lastQuarter',
  'thisYear'
]

// only id here is extra
export type DateTimePickerProps = {
  id: string
  periods?: string[]
  period?: string
  start?: null | Date
  end?: null | Date
  field: any
}

type UIState = 'period' | 'picker' | 'exact'

const currentLabel = ({ period, start, end }: DateRangeFilter, tab: UIState, translate: ReturnType<typeof useTranslate>, locale: string) => {
  if (period) {
    return translate(`nclusion.period.${period}`)
  } else {
    const loc = langToLocale(locale)
    let startString = ''
    let endString = ''
    if (start) {
      startString = Intl.DateTimeFormat(loc, { year: 'numeric', month: 'numeric', day: 'numeric', ...(tab === 'picker' ? {} : { hour: 'numeric', minute: 'numeric' }) }).format(new Date(start))
    }
    if (end) {
      endString = Intl.DateTimeFormat(loc, { year: 'numeric', month: 'numeric', day: 'numeric', ...(tab === 'picker' ? {} : { hour: 'numeric', minute: 'numeric' }) }).format(new Date(end))
    }
    return compact([startString, endString]).join(' - ')
  } 
}

const CalendarPicker = ({ id, filter, updateFilter, close }: { id: string, filter: DateRangeFilter, updateFilter: any, close: any}) => {
  const [start, setStart] = useState<null | string | Date>(filter.start)
  const [end, setEnd] = useState<null | string | Date>(filter.end)
  const translate = useTranslate()

  return <div>
    <DayPicker
      id={`${id}_date_picker`}
      mode='range'
      defaultMonth={start ? new Date(start) : end ? new Date(end) : new Date()}
      selected={{ from: start || undefined, to: end || undefined } as any}
      onSelect={(range: DateRange | undefined) => {
        setStart(range?.from ? startOfDay(range.from) : null)
        setEnd(range?.to ? endOfDay(range.to) : null)
      }}
    />
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
      <Button onClick={() => updateFilter({ ...filter, start, end })}>{translate('nclusion.submit')}</Button>
      <Button onClick={close}>{translate('nclusion.cancel')}</Button>
    </div>
  </div>
}

const ExactDateRangePicker = ({ filter, updateFilter, close }: { filter: DateRangeFilter, updateFilter: any, close: any }) => {
  const translate = useTranslate()
  const [start, setStart] = useState<null | string | Date>(filter.start)
  const [end, setEnd] = useState<null | string | Date>(filter.end)

  return <div>
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
      <label>{translate('nclusion.start')}</label>
      <input style={{ padding: 8 }} type='datetime-local' value={start?.toLocaleString() || ''} onChange={(e: any) => setStart(e.target.value)} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
      <label>{translate('nclusion.end')}</label>
      <input style={{ padding: 8 }} type='datetime-local' value={end?.toLocaleString() || ''} onChange={(e: any) => setEnd(e.target.value)} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
      <Button onClick={() => updateFilter({ ...filter, start, end })}>{translate('nclusion.submit')}</Button>
      <Button onClick={close}>{translate('nclusion.cancel')}</Button>
    </div>
  </div>
}

export const convertPeriod = (period: string, now: Date) => ({
  today: { start: startOfDay(now), end: undefined },
  yesterday: { start: startOfDay(addDays(now, -1)), end: endOfDay(addDays(now, -1)) },
  thisWeek: { start: startOfWeek(now), end: undefined },
  lastWeek: { start: startOfWeek(addWeeks(now, -1)), end: endOfWeek(addWeeks(now, -1)) },
  thisMonth: { start: startOfMonth(now), end: undefined },
  lastMonth: { start: startOfMonth(addMonths(now, -1)), end: endOfMonth(addMonths(now, -1)) },
  thisQuarter: { start: startOfQuarter(now), end: undefined },
  lastQuarter: { start: startOfQuarter(addQuarters(now, -1)), end: endOfQuarter(addQuarters(now, -1)) },
  thisYear: { start: startOfYear(now), end: undefined },
}[period])

const DateRangePicker = ({ id, field, periods, period = 'thisQuarter', start = null, end = null }: DateTimePickerProps) => {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState<UIState>('period')
  const { search: { setFilter, onResults, savedValues } } = useContext(ChartContext)
  const [settings, setSettings] = useState<any>({})
  onResults(id, ({ settings: newSettings }: any) => setSettings(newSettings))
  const translate = useTranslate()
  const [locale] = useLocaleState()
  const [theme] = useTheme()
  const [filter, updateFilter] = useState<DateRangeFilter>({
    id,
    filterId: 'dateRange',
    field,
    period:  savedValues && savedValues.filters[id] && savedValues.filters[id]?.period || period || 'today',
    start: savedValues && savedValues.filters[id] && savedValues.filters[id]?.start || start || null,
    end: savedValues && savedValues.filters[id] && savedValues.filters[id]?.end || end ||  null
  })

  useEffect(() => {
    updateFilter({ ...filter })
  }, [tab]) 

  useEffect(() => {
    setFilter({
      ...filter,
      ...(filter.period ? convertPeriod(filter.period, new Date()) : { 
        start: filter.start ? new Date(filter.start).toISOString() : null,
        end: filter.end ? new Date(filter.end).toISOString() : null
      })
    } as any)
  }, [filter.start, filter.end, filter.period])

  const activePeriods = periods ? intersection(defaultPeriods, periods || []) : defaultPeriods
  
  return <div  style={{ position: 'relative' }}>
    <div>
      <div></div>
      <Button
        onClick={() => setOpen(!open)}
      >
        {currentLabel(filter, tab, translate, locale)}
      </Button>
    </div>
    {open && <div style={{
      position: 'absolute',
      right: 0,
      zIndex: 9,
      backgroundColor: theme === 'dark' ? '#323232' : 'white',
      padding: 10,
      boxShadow: `3px 3px 12px ${theme === 'dark' ? '#121212' : '#c3c3c3'}`,
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 4 }}>
        <ButtonGroup>
          <Button onClick={() => setTab('period')} style={(tab === 'period' ? { backgroundColor: 'rgb(144, 202, 249)', color: '#323232' } : {})}>
            <ListIcon />
          </Button>
          <Button onClick={() => setTab('picker')} style={(tab === 'picker' ? { backgroundColor: 'rgb(144, 202, 249)', color: '#323232' } : {})}>
            <CalendarIcon />
          </Button>
          <Button onClick={() => setTab('exact')} style={(tab === 'exact' ? { backgroundColor: 'rgb(144, 202, 249)', color: '#323232' } : {})}>
            <KeyboardIcon />
          </Button>
        </ButtonGroup>
      </div>
      {tab === 'period' && <div>
        {activePeriods.map(
          (period: string) => <div key={period} style={{ padding: 10, cursor: 'pointer' }} onClick={() => {
            updateFilter({ ...filter, period })
            setOpen(false)
          }}>
            {translate(`nclusion.period.${period}`)}
          </div>
        )}
      </div>}
      {tab === 'picker' && <CalendarPicker id={id} filter={filter} updateFilter={(filter: DateRangeFilter) => {
        updateFilter(filter)
        setOpen(false)
      }} close={() => setOpen(false)} />}
      {tab === 'exact' && <ExactDateRangePicker filter={filter} updateFilter={(filter: DateRangeFilter) => {
        updateFilter(filter)
        setOpen(false)
      }} close={() => setOpen(false)} />}
    </div>}
  </div>
}

export default DateRangePicker
