import { Box } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props

  return <div role='tabpanel' hidden={value !== index}>
    {value === index && <Box sx={{ p: 2 }}>
      <>{children}</>
    </Box>}
  </div>
}
