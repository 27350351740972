import ShareIcon from '@mui/icons-material/Share'
import ChartContext from './ChartContext'
import { Button, useTranslate } from 'react-admin'
import { useContext, useState } from 'react'
import { Card, CardActions, CardContent, CardHeader, Modal } from '@mui/material'

const ShareButton = () => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const { search: { stringify } } = useContext(ChartContext)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.href}?${stringify()}`)
    setOpen(false)
  }

  return <>
    <ShareIcon sx={{ color: 'primary', cursor: 'pointer', justifySelf: 'flex-end' }} onClick={() => setOpen(!open)} />
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <div style={{ margin: '10% 40% 0' }}>
        <Card>
          <CardHeader title={translate('nclusion.shareSearch')} />
          <CardContent>
            <p>{translate('nclusion.shareSearchInstructions')}</p>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button label={translate('nclusion.cancel')} onClick={() => setOpen(false)} />
            <Button onClick={copyToClipboard} label={translate('nclusion.copyToClipboard')} />
          </CardActions>
        </Card>
      </div>
    </Modal>
  </>
}

export default ShareButton