import {
  BooleanField,
  Datagrid,
  EmailField,
  NumberField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
} from 'react-admin'
import PhoneField from '../../components/PhoneField'
import i18nProvider from '../../i18nProvider'
import { getLocale, schemaFilters } from '../../util'
import UnassignedFilter from './UnassignedFilter'
import UnverifiedFilter from './UnverifiedFilter'
import { NclusionList } from '../../components/NclusionList'

const additionalFilters = [
  <UnassignedFilter
    source='$unassigned'
    label={i18nProvider.translate('nclusion.unassigned')}
  />,
  <UnverifiedFilter
    source='$unverified'
    label={i18nProvider.translate('nclusion.unverified')}
  />,
]

const listFilters = () => schemaFilters('associate', additionalFilters)

export default () => {
  const locale = getLocale()

  return (
    <NclusionList resource='associate' filters={listFilters()}>
      <Datagrid rowClick='show' bulkActionButtons={false}>
        <NumberField source='id' locales={locale} />
        <NumberField source='user_id' locales={locale} />
        <TextField source='name' />
        <TextField source='alias' />
        <ReferenceManyField
          target='associate_id'
          reference='associate-roles'
          label={i18nProvider.translate('nclusion.roles')}
        >
          <SingleFieldList linkType='show'>
            <TextField source='role.name' />
          </SingleFieldList>
        </ReferenceManyField>
        <PhoneField source='user.phone' sortable={false} />
        <EmailField source='user.email' sortable={false} />
        <TextField source='status' />
        <BooleanField source='user.verified' sortable={false} />
      </Datagrid>
    </NclusionList>
  )
}
