import { Create, SimpleForm, TextInput } from 'react-admin'

// after submitting the edit if the user is no longer verified we need
// to put values in local storage and redirect to the verify page

export const UserCreate = () => (
  <Create>
      <SimpleForm>
          <TextInput source="firstName" />
          <TextInput source="lastName" />
          <TextInput type="email" source="email" />
          <TextInput type="tel" source="phone" />
      </SimpleForm>
  </Create>
);